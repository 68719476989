@mixin scrollbars($width, $height, $background-color, $arrow-color, $border, $marginTopBottm) {
    &::-webkit-scrollbar {
      width: $width;
      height: $height;
    }
  
    &::-webkit-scrollbar-thumb {
      background: $arrow-color;
      border-radius: 5px;
    }
  
    &::-webkit-scrollbar-thumb:hover { 
      display: block;
    }
  
    &::-webkit-scrollbar-track {
      background: $background-color;
      border-radius: 5px ;
      border-left: $border;
      margin: $marginTopBottm 0;
    }
  }
@media (min-width: 1199px) {
    footer  {
        .container {
            .row-padding  {
                .col-flex-2 {
                    padding: 0;
                    max-width: 20%;
                }
                .col-flex {
                    max-width: 13%;
                    padding: 0;
                }
            }
        }   
    }

}
@media (max-width: 1469px) {
    .black-banner {
        .container {
            .row-padding {
                padding: 30px 115px;
                .col-md-4 {
                    padding: 80px 25px;
                    .subtitle {
                        font-size: 35px;
                    }
                }
            }
        }
    } 
    .personal-stylist {
        .black-banner {
            .container {
                padding: 30px 0;
                .row-padding {
                    padding: 30px 140px;
                    .slideshow-container {
                        .slide {
                            .text-row {
                                h2 {
                                    font-size: 36px;
                                    margin-bottom: 5px;
                                }
                                a {
                                    margin-bottom: 5px;
                                    font-size: 18px;
                                }
                            }
                            .col-md-5 .box-img .text-content {
                                top: 55%;
                                a {
                                    font-size: 24px;
                                }
                            }
                            .col-md-3 {
                                top: 50%;
                            }

                        }
                    }
                }
            }
        }
    } 
    .photo-banner .container {
        .photo-content {
            top: 25%;
            .photo-title {
                font-size: 90px;
                margin-bottom: 40px;
            }
            .underline-text {
                font-size: 18px;
            }
        }
        .photo-content-second {
            top: 55%;
            p {
                font-size: 18px;
            }
            .photo-title-smaller {
                font-size: 36px;
            }
        }
    }
    .woocommerce-tabs,
    .new-collection .row-padding {
        padding: 30px 140px;
        .box-v2 {
            .text-content {
                .new-collection-header {
                    font-size: 18px;
                }
                button {
                    a {
                        img {
                            left: 12%;
                        }
                    }
                    a:hover {
                        img {
                            left: 13%;
                        }
                    }
                }
            }
            .box-text {
                a {
                    font-size: 18px;
                }
            }
        }
    }
    .shop-new-collection .container .row {
        .col-12 .box {
            max-width: 30%;
        }
        .col-sm-6 .box {
            max-width: 45%;
        }
    }
    .login-container {
        .login, .register {
            form {
                width: 70%;
            }
        }
    }
    .register-container {
        .login, .register {
            #register-form {
                width: 70%;
            }
        }
    }
    .shop-all {
        .container {
            .panel-path,
            .row {
                padding: 0 150px;
                .box {
                    margin-bottom: 40px;
                }
            }
        }
    }
    .about {
        .container {
            .ceo {
                margin: 0 5%;
            }
            .stylish-everyday {
                margin: 60px 5%;
                .text-content {
                    h4 {
                        font-size: 20px;
                    }
                }
            }
            .about-header {
                font-size: 20px;
                padding: 60px 20px;
            }
            .about-header.bottom {
                padding-bottom: 30px;
            }
        }
    }
    footer .container .row-padding {
        .sign-up {
            font-size: 18px;
        }
        form {
            .sign-input {
                padding: 15px 10px;
            }
            .submit-form {
                right: 5%;
                width: 35px;
                img {
                    width: 100%;
                }
            }
            .submit-form:hover {
                right: 3%;
            }
        }
    }
    .my-data {
        .container {
            .row {
                .panel {
                    padding-left: 40px;
                    .order-row {
                        margin-right: 80px;
                    }
                    .returns-instruction-btn {
                        right: 80px;
                    }
                }
            }
            .instruction-content {
                max-height: 90%; 
                overflow-y: scroll;
                @include scrollbars(5px, 8px, white, lightgray, none, 0);
            }
        }
    }
    .shopping-cart {
        .shopping {
            padding: 60px 120px;
        }
        .shopping-card {
            .payment {
                top: 30%;
                padding: 0 60px 50px 30px;
            }
        }
    }
    .message {
        form {
            h4 {
                margin: 20px 0;
            }
            input {
                padding: 26px;
            }
            textarea {
                padding: 30px;
            }
            input[type="submit"],
            button {
                padding: 20px;
                margin-bottom: 20px;
            }
        }
    
    }
    .showroom {
        .row {
            margin: 40px 140px;
            .showroom-content {
                // padding: 0 50px;
                margin: 40px 0;
                .border-div {
                    .text-content {
                        padding-left: 20px;
                        h4 {
                            font-size: 36px;
                            margin-bottom: 15px;
                        }
                        p {
                            line-height: 1.8;
                            font-size: 18px;
                        }
                    }
                }
            }
        }
        h2 {
            font-size: 36px;
            line-height: 1.5;
            margin: 40px 0;
        }
        p {
            font-size: 18px;
            margin-bottom: 40px;
        }
    }
    .payment-end {
        .payment-method {
            padding: 60px 120px;
            h2 {
                font-size: 28px;
                padding: 30px 0;
            }
            form {
                .master-card {
                    label {
                        width: 70%;
                    }
                    span {
                        width: 70%;
                    }
                }
                button {
                    width: 66%;
                }
                .regulamin {
                    width: 70%;
                }
            }
        }
        .payment-nav {
            .payment {
                padding: 80px 80px 0 30px;
            }
        }
    }
    .product-details {
        .row {
            padding: 30px 140px 0;
            .main-content {
                padding: 30px 90px 30px 40px;
            }
        }
        .close-up {
            .box-img {
                max-width: 600px;
                max-height: 900px;
            }
        }
    }
}
/* Extra large devices (large desktops, 1200px and up) */
@media (max-width:1199px) {
    footer  {
        .container {
            padding: 30px;
            .row-padding  {
                padding: 0 20px;
                .col-flex-2 {
                    padding: 30px 0;
                    .sign-up {
                        font-size: 16px;
                    }
                    p {
                        padding-bottom: 0;
                    }
                    .footer-img {
                        height: 100%;
                        object-fit: contain;
                        padding: 0;
                    }
                }
                .col-flex {
                    padding: 0;
                    text-align: left;
                }
                .showroom {
                    .read-more img {
                        width: 30px;
                    }
                }
                form {
                    .submit-form:hover {
                        right: 4%;
                    }
                }
            }
            .copy-write {
                padding-top: 30px;
                .gray-font {
                    padding: 0;
                }
            }
        }   
    }
    .black-banner {
        .container {
            .row-padding {
                padding: 25px 100px;
                .col-md-4 {
                    padding: 70px 20px;
                    .subtitle {
                        font-size: 32px;
                    }
                }
            }
        }
    } 
    .personal-stylist {
        height: 50vw;
        .black-banner {
            .container {
                padding: 0;
                .row-padding {
                    padding: 25px 120px;
                    .slideshow-container {
                        .slide {
                            .text-row {
                                h2 {
                                    font-size: 32px;
                                }
                                p {
                                    font-size: 16px;
                                }
                            }
                            .col-md-5 {
                                .box-img {
                                    .text-content {
                                        width: 100%;
                                        a {
                                            font-size: 32px;
                                            .product-price {
                                                font-size: 14px;
                                            }
                                        }
                                    }
                                }
                            }
                            .col-md-3 {
                                .box-img .text-content a {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                    .stylist-arrow {
                        top: 55.5%;
                        a {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    } 
    .photo-banner {
        .container {
            .photo-arrow {
                width: 35px;
                height: 80px;
            }
            .photo-content {
                top: 25%;
                .photo-title {
                    font-size: 75px;
                    margin-bottom: 30px;
                }
                .underline-text {
                    font-size: 16px;
                }
            }
            .photo-content-second {
                top: 50%;
                p {
                    font-size: 16px;
                }
                .photo-title-smaller {
                    font-size: 32px;
                }
            }
            .text-content.about {
                a {
                    font-size: 18px;
                    .bigger {
                        font-size: 32px;
                        padding-top: 20px;
                    }
                }
            }
        }
        h2 {
            font-size: 64px;
        }
    }
    .new-collection {
        padding: 25px 0 0 0;
        .row-padding {
            padding: 25px 120px;
            .box-v2 {
                padding-bottom: 100px;
                .text-content {
                    .new-collection-header {
                        font-size: 16px;
                    }
                    button {
                        padding-bottom: 10px;
                        a {
                            img {
                                left: 15%;
                            }
                        }
                        a:hover {
                            img {
                                left: 16%;
                            }
                        }
                    }
                }
                .box-text {
                    a {
                        font-size: 16px;
                        margin-bottom: 10px;
                        .product-price {
                            margin-bottom: 10px;
                            padding-top: 10px;
                        }
                    }
                }
            }
        }
        .container .row-border h3 {
            font-size: 18px;
            margin: 0px 0 20px;
        }
    }
    header .container .navbar {
        padding: 20px 40px;
    }
    .shop-new-collection {
        padding: 80px 0;
        .container .row {
            .col-12 {
                margin-top: 80px;
                .box {
                    max-width: 35%;
                }
            } 
            .col-sm-6 .box {
                max-width: 50%;
            }
        }
    }
    .shop-product .container .row {
        padding: 80px 0 60px 0;
    }
    .login-container {
        .login, .register {
            form {
                width: 80%;
            }
        }
    }
    .register-container {
        .login, .register {
            #register-form {
                width: 80%;
            }
        }
    }
    .about {
        .container {
            .ceo {
                margin: 0 4%;
            }
            .stylish-everyday {
                margin: 40px 4%;
                .text-content {
                    h4 {
                        font-size: 20px;
                        margin-bottom: 10px;
                    }
                }
            }
            .about-header {
                font-size: 20px;
                padding: 50px 10px;
            }
            .about-header.bottom {
                padding-bottom: 25px;
            }
        }
    }
    .my-data .container .row {
        .account-nav .data p {
            margin-left: 30%;
            width: 60%;
        }
        .panel {
            padding-left: 30px;
            form {
                label {
                    width: 80%;
                }
                .flex-label {
                    width: 80%;
                    input {
                        padding: 16px;
                    }
                }
                .adres {
                    width: 80%;
                }
                .newsletter {
                    padding-right: 80px;
                }
                .btn-container {
                    width: 100%;
                    height: 50px;
                    position: relative;
                    margin: 50px 0;
                    button {
                        background-color: black;
                        padding: 10px 120px;
                        color: white;
                        position: absolute;
                        left: 40%;
                    }
                    button:hover {
                        transform: scale(1.07) translateX(-10%);
                    }
                }
            }
            .order-row {
                margin-right: 60px;
            }
            .see-more {
                padding: 15px 130px;
                margin: 40px 0 0 100px;
            }
            // .return-btn-container {
            //     // button {
            //     //     padding: 20px 140px;
            //     // }
            // }
            .returns-instruction-btn { 
                right: 60px;
            }
            .return-addres {
                h5 {
                    margin: 60px 0 25px;
                }
            }
        }
    }
    .shopping-cart {
        .shopping {
            padding: 50px 80px;
        }
        .shopping-card {
            .payment {
                padding: 0 20px;
            }
        }
        h4 {
            font-size: 18px;
        }
    }
    .newsletter2-popap.active,
    .newsletter-popap.active,
    .table-popup.active,
    .product-popap.active {
        .product-popap-content a button {
            padding: 18px 80px;
        }
    }
    .empty-cart {
        .empty-content {
            padding: 100px 0;
        }
    }
    .showroom {
        .row {
            margin: 30px 120px;
            .showroom-content {
                // padding: 0 40px;
                margin: 30px 0;
                .border-div {
                    .text-content {
                        padding-left: 20px;
                        h4 {
                            font-size: 32px;
                            margin-bottom: 10px;
                        }
                        p {
                            line-height: 1.6;
                            margin-bottom: 5px;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        h2 {
            font-size: 32px;
            line-height: 1.4;
            margin: 30px 0;
        }
        p {
            font-size: 16px;
            margin-bottom: 30px;
        }
    }
    .payment-end {
        .payment-method {
            padding: 50px 80px;
            h2 {
                padding: 30px 0;
            }
            form {
                .master-card {
                    margin: 5px 0;
                    label {
                        width: 80%;
                    }
                    span {
                        width: 80%;
                    }
                    .cont {
                        input {
                            margin: 15px;
                        }
                        p {
                            font-size: 18px;
                        }
                    }
                }
                button {
                    width: 74%;
                    p {
                        font-size: 14px;
                    }
                }
                .regulamin {
                    width: 80%;
                }
                
                .wrap-label {
                    .delivery-addres {
                        padding: 20px 0 10px 63px;
                    }
                }
            }
            .payment-header {
                padding: 50px 0 25px;
            }
        }
        .payment-nav {
            .payment {
                padding: 70px 20px;
                .btn-dark {
                    font-size: 14px;
                }
                .total-price {
                    font-size: 18px;
                }
                .orders.active {
                    .order-row {
                        height: 120px;
                        padding: 10px 0;
                        .order-value {
                            font-size: 14px;
                        }
                        .order-content {
                            .order-description {
                                padding-left: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    .product-details {
        .row {
            padding: 25px 120px 0;
            .main {
                .main-content {
                    padding: 20px 70px 20px 20px;
                    h3 {
                        line-height: 1.5;
                        font-size: 18px;
                        margin-bottom: 0;
                    }
                    p {
                        margin: 10px 0;
                    }
                    form {
                        label {
                            select {
                                padding: 10px;
                            }
                            height: 45px;
                        }
                    }
                }
            }
        }
        .close-up {
            .box-img {
                max-width: 540px;
                max-height: 800px;
            }
        }

    }
}

/* Large devices (desktops, 992px and up) */
@media (max-width: 991px) {

    .black-banner {
        .container {
            .row-padding {
                padding: 20px 55px;
                .col-md-4 {
                    padding: 60px 15px;
                    .subtitle {
                        font-size: 28px;
                    }
                }
            }
        }
    } 
    footer {
        .container {
            .row-padding {
                padding: 0 20px;
            }
            .copy-write {
                padding-top: 0;
            }
        }
    }
    .personal-stylist {
        height: 65vw;
        .black-banner {
            .container {
                .row-padding {
                    padding: 20px 80px;
                    .slideshow-container {
                        padding: 20px 0;
                        .slide {
                            .text-row {
                                height: 10%;
                                h2 {
                                    font-size: 28px;
                                }
                                p {
                                    font-size: 14px;
                                }
                            }
                            .col-md-5 {
                                .box-img {
                                    .text-content {
                                        width: 100%;
                                        a {
                                            font-size: 28px;
                                            padding: 5px;
                                            .product-price {
                                                padding: 5px;
                                                font-size: 18px;
                                            }
                                        }
                                    }
                                }
                            }
                            .col-md-3 {
                                padding-left: 15px;
                                .text-content {
                                    top: 60%;
                                    width: 100%;
                                    a {
                                        font-size: 14px;
                                        .product-price {
                                            font-size: 16px;
                                            padding-top: 2px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .stylist-arrow {
                        top: 53%;
                    }
                    .stylist-right-arrow {
                        right: 8%;
                    }
                    .stylist-right-arrow:hover {
                        right: 7%;
                    }
                    .stylist-left-arrow {
                        left: 8%;
                    }
                    .stylist-left-arrow:hover {
                        left: 7%;
                    }

                }
            }
        }
    } 
    .photo-banner .container {
        .photo-arrow {
            width: 30px;
            height: 65px;
        }
        .photo-content {
            top: 20%;
            .photo-title {
                font-size: 60px;
                margin-bottom: 20px;
            }
            .underline-text {
                font-size: 14px;
            }
        }
        .photo-content-second {
            top: 50%;
            p {
                font-size: 14px;
            }
            .photo-title-smaller {
                font-size: 28px;
            }
        }
    }
    .new-collection {
        .row-padding {
            padding: 20px 40px;
            .box-v2 {
                padding-bottom: 80px;
                .text-content {
                    .new-collection-header {
                        font-size: 14px;
                    }
                    button {
                        a {
                            img {
                                left: 68px;
                            }
                        }
                        a:hover {
                            img {
                                left: 72px;
                            }
                        }
                    }
                }
                .box-text {
                    a {
                        font-size: 14px;
                        margin-bottom: 5px;
                        .product-price {
                            margin-bottom: 5px;
                            padding-top: 5px;
                        }
                    }
                }
                .box-img2 {
                    margin-top: 5px;
                    height: calc(70% - 5px);
                }
            }
        }
        .container .row-border h3 {
            font-size: 16px;
            margin: 0px 0 15px;
        }
    }
    header {
        .container  {
            .navbar{
                padding: 15px 30px;
                position: relative;
                .navbar-toggler {
                    padding: 0;
                }
                .collapse {
                    z-index: 21;
                    transition: 0.3s;
                    position: absolute;
                    top: -204%;
                    background-color: white;
                    left: 0;
                    width: 100%;
                    padding: 10px 30px;
                    .navbar-nav {
                        position: absolute;
                        left: 0;
                        padding-left: 30px;
                        width: 100%;
                        padding-top: 30px;
                    }
                    .navbar-icons {
                        form {
                            // position: absolute;
                            left: 0;
                            width: 100%;
                            justify-content: flex-start;
                            margin: 0;
                            padding-top: 20px;
                            .search {
                                padding: 10px 0;
                                justify-content: center;
                                max-height: 40px;
                            }
                            #search {
                                margin: 10px 30px 10px 0;
                            }
                        }
                        .account {
                            top: 166px;
                            transform: translate(-50%, -50%);
                            opacity: 1;
                            position: absolute;
                            height: 40px;
                            padding: 10px;
                            right: 40px;
                        }
                        .account:hover {
                            opacity: 0.5;
                        }
                        .cart {
                            right: -25px;
                            opacity: 1;
                            position: absolute;
                            height: 40px;
                            padding: 10px;
                            top: 161px;
                            transform: translate(-50%, -50%);
                            p {
                                padding-top: 3px;
                                padding-bottom: 0;
                            }
                        }
                        .cart:hover {
                            opacity: 0.5;
                        }
                    }
                }
                .collapse.mobile-active {
                    transition: 0.3s;
                    opacity: 1;
                    z-index: 21;
                    display: flex;
                    flex: 100%;
                    flex-wrap: wrap;
                    position: absolute;
                    height: 100vh;
                    top: 100%;
                    align-content: flex-start;
                    left:0;
                    .navbar-nav {
                        position: static;
                        width: 100%;
                        padding-left: 0;
                        opacity: 1;
                        .nav-link:hover {
                            text-decoration: underline;
                        }
                    }
                    .navbar-icons {
                        opacity: 1;
                        form {
                            input {
                                width: 100%;
                                background-color: white;
                            }
                        }

                        // .account {
                        //     top: -42px;
                        // }
                        // .cart {
                        //     top: -42px;
                        // }
                    }
        
                }
                

                .navbar-first-col .navbar-nav .nav-link {
                    font-size: 14px;
                }
            }
        }
    }
    .shop-new-collection {
        padding: 60px 0;
        .container .row {
            .col-12 {
                margin-top: 60px;
                .box {
                    max-width: 40%;
                }
            }
            .col-sm-6 .box {
                max-width: 60%;
            }
        }
    }
    .shop-product .container .row {
        padding: 60px 0 40px 0;
        .col-xxl-2 .text-content {
            padding-bottom: 20px;
            a {
                align-content: flex-start;
            }
        }
    }
    .account-popap {
        top: -350px;
        left: 50%;
        transform: translateX(-50%);
        background-color: white;
    }
    .account-popap.active {
        top: -350px;
    }
    .account-popap.mobile-active {
        height: auto;
        display: block;
        top: 64px;
    }
    .account-popap.mobile-active.loged {
        display: none;
    }
    .loged-account-popap {
        left: 50%;
        transform: translateX(-50%);
        top: -350px;
    }
    .loged-account-popap.active {
        top: -350px;
    }
    .loged-account-popap.mobile-active {
        height: auto;
        display: block;
        top: 64px;
    }
    .loged-account-popap.logout {
        display: none;
    }
    .login-container {
        .login, .register {
            form {
                width: 90%;
                input {
                    padding: 10px;
                }
            }
        }
    }
    .register-container {
        .login, .register {
            #register-form {
                width: 90%;
                input {
                    padding: 10px;
                }
            }
        }
    }
    .shop-all {
        .container {
            .shop-all-header {
                padding: 30px 0;
            }
            .panel-path,
            .row {
                padding: 0 100px;
                .box {
                    margin-bottom: 40px;
                    padding: 0 10px;
                }
            }
            .blocks-nav {
                button {
                    width: 35px;
                    height: 35px;
                    margin: 15px;
                }
            }
        }
    }
    .about {
        .container {
            .ceo {
                margin: 0 50px;
            }
            .stylish-everyday {
                margin: 20px 50px;
                .text-content {
                    h4 {
                        font-size: 20px;
                        margin-bottom: 10px;
                    }
                    p {
                        font-size: 12px;
                        margin-bottom: 5px;
                    }
                }
            }
            .about-header {
                font-size: 20px;
                padding: 30px 5px;
            }
            .about-header.bottom {
                padding-bottom: 20px;
            }
        }
    }
    .my-data .container .row {
        .account-nav .data {
            p {
                margin-left: 10%;
                width: 85%;
            }
        }
        .panel {
            padding: 30px 0 30px 30px;
            form {
                label {
                    margin-left: 2px;
                    margin-bottom: 14px;
                    width: 80%;
                }
                .flex-label {
                    margin-bottom: 14px;
                    width: 90%;
                    input {
                        padding: 10px;
                    }
                }
                .adres {
                    width: 90%;
                }
                .newsletter {
                    padding-right: 80px;
                    div {
                        button {
                            padding: 15px 100px;
                        }
                    }
                }
                .btn-container {
                    width: 100%;
                    height: 50px;
                    position: relative;
                    margin: 50px 0;
                    button {
                        background-color: black;
                        padding: 10px 70px;
                        color: white;
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                    button:hover {
                        transform: translateX(-50%) scale(1.07);
                    }
                }
            }
            .order-row {
                margin-right: 30px;
            }
            .returns-instruction-btn {
                top: 87px;
                right: 30px;
            }
            .see-more {
                padding: 15px 100px;
                margin: 40px 0 0 60px;
            }
            .summary {
                .summary-data {
                    h4 {
                        margin-bottom: 20px;
                    }
                    p {
                        margin-bottom: 15px;
                    }
                }
            }
            .return-btn-container {
                margin-bottom: 0;
                // button {
                //     padding: 15px 120px;
                // }
            }
        }
    }
    .shopping-cart {
        .shopping {
            padding: 30px 50px 10px;
            font-size: 12px;
            .item-row {
                padding: 20px 0;
                height: 250px;
                .item-content {
                    img {
                        margin-right: 15px;
                    }
                    .item-description .dropdown button{
                        font-size: 14px;
                    }
                }
                .item-value {
                    p {
                        padding-bottom: 8px;
                    }
                }
            }
            .information {
                margin-top: 40px;
                padding: 40px;
                h5 {
                    font-size: 14px;
                }
                p {
                    font-size: 12px;
                }
            }
        }
        .shopping-card {
            .payment {
                padding: 10px;
                font-size: 12px;
                .btn-dark {
                    width: 90%;
                    font-size: 14px;
                    padding: 10px;
                }
                form {
                    input {
                        padding: 5px;
                    }
                    button {
                        padding: 5px 10px;
                    }
                }
                .payment-forms {
                    p {
                        font-size: 12px;
                    }
                    a {
                        img {
                            transform: scale(0.8);
                        }
                    }
                    a:hover {
                        img {
                            transform: scale(0.9);
                        }
                    }
                }
            }
        }
        h4 {
            font-size: 14px;
        }
    }
    .choices .item-content .item-description .chose-reason {
        width: 100%;
        height: 40px;
        select {
            padding: 5px 10px;
        }
    }
    .newsletter2-popap.active,
    .newsletter-popap.active,
    .table-popup.active,
    .product-popap.active {
        .product-popap-content {
            padding: 15px 30px;
            .product-describe {
                padding: 10px 0;
                justify-content: space-around;
                .box-img {
                    padding-left: 10px;
                }
                .box-content {
                    padding: 0 10px;
                }
            }
            a {
                font-size: 14px;
                padding: 10px 0;
                button {
                    padding: 18px;
                    width: 100%;
                }
            }
        }
        h3 {
            font-size: 18px;
            padding: 15px 0;
        }
    }
    .empty-cart {
        .empty-content {
            padding: 80px 0;
            img {
                margin-bottom: 30px;
            }
            h2 {
                margin: 10px 0 15px 0;
            }
            button {
                a {
                    padding: 18px 0;
                }
            }
        }
    }
    .message {
        form {
            input {
                padding: 20px;
            }
            textarea {
                padding: 20px;
            }
            .permission {
                margin-top: 20px;
                margin-bottom: 20px;
            }
            input[type="submit"],
            button {
                margin: 10px 5px 20px 5px;
                padding: 18px;
            }

        }
    
    }
    .showroom {
        .row {
            margin: 20px 80px;
            .showroom-content {
                // padding: 0 30px;
                padding: 0;
                margin: 20px 0;
                .border-div {
                    .text-content {
                        padding-left: 20px;
                        h4 {
                            font-size: 28px;
                            margin-bottom: 10px;
                        }
                        p {
                            line-height: 1.5;
                            margin-bottom: 5px;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        h2 {
            font-size: 28px;
            line-height: 1.3;
            margin: 20px 0;
        }
        p {
            font-size: 14px;
            margin-bottom: 20px;
        }
    }
    .payment-end {
        .payment-method {   
            padding: 30px 50px;
            h2 {
                font-size: 28px;
                padding: 25px 0;
            }
            form {
                .cont {
                    padding: 15px;
                    .delivery-type {
                        margin: 0 10px;
                    }
                    .delivery-type-x2 {
                        margin: auto 10px;
                    }
                    input {
                        margin: auto 12px;
                    }
                    p {
                        font-size: 16px;
                    }
                    .price {
                        font-size: 14px;
                        padding-right: 0;
                        width: 80px;
                    }
                }
                .wrap-label {
                    .delivery-addres {
                        padding: 15px 0 10px 47px;
                        .addres-data {
                            margin-bottom: 0;
                            a {
                                padding-right: 0;
                                font-size: 14px;
                            }
                        }
                    }
                }
                .master-card {
                    .cont {
                        input {
                            margin: auto 12px;
                        }
                        p {
                            font-size: 16px;
                        }
                    }
                    label {
                        width: 90%;
                        input {
                            font-size: 14px;
                        }
                    }
                    span {
                        width: 90%;
                        font-size: 14px;
                    }
                }
                button {
                    width: 86%;
                    margin-top: 20px;
                }
                .regulamin {
                    width: 90%;
                    margin-bottom: 0;
                }
            }
            .payment-header {
                padding: 30px 0 20px;
            }
        }
        .payment-nav {
            .payment {
                padding: 60px 10px;
                font-size: 12px;
                .subtotal-price {
                    font-size: 14px;
                }
                .btn-dark {
                    padding: 10px 0;
                }
                p {
                    font-size: 12px;
                }
                .orders.active {
                    .order-row {
                        height: 110px;
                        padding: 5px 0;
                        .order-value {
                            font-size: 14px;
                        }
                        .order-content {
                            img {
                                max-width: 90px;
                            }
                            .order-description {
                                padding-left: 5px;
                            }
                            p {
                                font-size: 12px;
                            }
                            .order-name {
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    .product-details {
        .row {
            padding: 20px 40px;
            .main {
                .main-content {
                    padding: 20px 40px 20px 20px;
                }
            }
        }
        .close-up {
            .box-img {
                max-width: 480px;
                max-height: 700px;
            }
        }
    }
    header .container .navbar.scroll .collapse .navbar-icons .account {
        top: 115px;
    }
    header .container .navbar.scroll .collapse .navbar-icons .cart {
        top: 110px;
    }
    // header .container .navbar.scroll .collapse .navbar-nav {
    //     top: 
    // }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (max-width: 767px) {
    .desktop{
        display:none;
    }
    .mobile{
        display:block;
    }
    .showroom .row .showroom-content .border-div .img-content img{
        height:250px;
    }
    .photo-banner .container img {
        height: 45vh;
    }
    .black-banner {
        .container {
            .row-padding {
                padding: 20px 50px;
                .col-md-4 {
                    max-height: 500px;
                    padding: 10px;
                    .box-img {
                        margin: auto;
                        width: 80%;
                        height: 100%;
                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    } 
    footer  {
        .container {
            padding: 0;
            .row-padding  {
                width: 100%;
                .col-flex-2 {
                    min-height: 50px;
                    padding: 30px 0;
                    .sign-up {
                        font-size: 14px;
                    }
                    p {
                        padding-bottom: 0;
                        font-size: 12px;
                    }
                    a {
                        font-size: 12px;
                    }
                    .footer-img {
                        height: 100%;
                        object-fit: contain;
                        padding: 0;
                    }
                }
                .col-flex {
                    text-align: left;
                    flex-wrap: wrap;
                    align-items: center;
                    padding: 10px 0;
                    font-size: 12px;
                    p {
                        text-align: left;
                        margin-right: 5px;
                    } 
                    a {
                        margin-top: 5px;
                        font-size: 12px;
                        text-align: center;
                    }
                    .black-font {
                        margin-bottom: 5px;
                        font-size: 14px;
                    }
                    .gray-font {
                        text-align: left;
                    }
                }
                .showroom {
                    .showroom-text {
                        p {
                            font-size: 14px;
                        }
                        a {
                            font-size: 12px;
                            padding-right: 5px;
                            margin-top: 5px;
                        }
                    }
                    .read-more {
                        margin: 6px 0 0 0;
                        img {
                            width: 25px;
                            top: 65%;
                        }
                    }
                }
                .social {
                    .contact {
                        p {
                            margin-bottom: 5px;
                        }
                    }
                    .visit-us {
                        p {
                            margin-bottom: 10px;
                        }
                        .social-media {
                            margin-bottom: 0;
                        }
                    }
                }
            }
            .copy-write {
                .gray-font {
                    padding-left: 0;
                    font-size: 12px;
                }
            }
        }   
    }
    .personal-stylist {
        height: 75vw;
        .black-banner {
            .container {
                .row-padding {
                    padding: 20px 60px;
                    .slideshow-container {
                        .slide {
                            .text-row {
                                height: 12%;
                                h2 {
                                    font-size: 28px;
                                }
                                p {
                                    font-size: 14px;
                                }
                            }
                            .col-md-5 {
                                .box-img {
                                    .text-content {
                                        width: 100%;
                                        a {
                                            font-size: 28px;
                                            padding: 5px;
                                            .product-price {
                                                padding: 5px;
                                                font-size: 16px;
                                            }
                                        }
                                    }
                                }
                            }
                            .col-md-3 {
                                padding-left: 10px;
                                .text-content {
                                    top: 65%;
                                    width: 100%;
                                    a {
                                        font-size: 12px;
                                        padding-bottom: 3px;
                                        .product-price {
                                            font-size: 14px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .stylist-arrow {
                        top: 54%;
                        width: 60px;
                    }
                    .stylist-left-arrow {
                        left: 2%;
                    }
                    .stylist-left-arrow:hover {
                        left: 1%;
                    }
                    .stylist-right-arrow {
                        right: 2%;
                    }
                    .stylist-right-arrow:hover {
                        right: 1%;
                    }
                }
            }
        }
    } 
    .photo-banner {
        .container {
            .photo-arrow {
                width: 22px;
                height: 48px;
            }
             .photo-content {
                top: 18%;
                .photo-title {
                    font-size: 45px;
                    margin-bottom: 15px;
                }
                .underline-text {
                    font-size: 12px;
                }
            }
            .photo-content-second {
                top: 45%;
                p {
                    font-size: 14px;
                }
                .photo-title-smaller {
                    font-size: 40px;
                }
            }
            .text-content.about {
                a {
                    font-size: 16px;
                    .bigger {
                        padding-top: 15px;
                        font-size: 24px;
                    }
                }
            }
        }
        h2 {
            font-size: 48px;
        }
    }
    .new-collection {
        padding-top: 30px;
        padding-bottom: 0;
        .row-padding {
            padding: 20px 30px;
            justify-content: space-between;
            .col-md-4 {
                max-width: 48%;
            }
            .col-md-8 {
                padding: 0;
                margin-bottom: 20px;
                .box-v2 {
                    padding-bottom: 0;
                    .text-content {
                        height: 25%;
                        .new-collection-header {
                            font-size: 14px;
                        }
                    }
                    .box-img2 {
                        width: 100%;
                        height: 75%;
                        margin-top: 0;
                        overflow: hidden;
                    }
                    .box-text {
                        bottom: -1%;
                        a {
                            font-size: 14px;
                        }
                    }
                }
            }
            .prl-0 {
                padding-left: 0;
                padding-right: 0;
            }
            .pl-0 {
                padding-right: 0;
            }
            .pr-0 {
                padding: 0;
            }
            .first {
                padding-right: 10px;
            }
            .second {
                padding: 0 5px;
            }
            .third {
                padding-left: 10px;
            }
        }
        .container {
            .row-border {
                padding: 0px 20px 30px;
            }
        }

    }
    .shop-new-collection {
        padding: 40px 0;
        .container .row {
            .col-12 {
                margin-top: 40px;
                .box {
                    max-width: 50%;
                    a {
                        height: 88%;
                    }
                    .text-content {
                        height: 12%;
                    }
                }
            }
            .col-sm-6 .box {
                max-width: 80%;
            }
            .head-text {
                font-size: 16px;
            }
        }
    }
    .shop-product .container .row {
        padding: 40px 0 20px 0;
        .col-xxl-2 {
            a {
                height: 80%;
            }
            .text-content {
                height: 20%;
                padding-bottom: 20px;
                a {
                    align-content: flex-start;
                }
            }
        }
    }
    .login-container {
        flex-wrap: wrap;
        .login {
            width: 100%;
            padding: 0 15%;
            height: 50%;
            form {
                height: 60%;
                button {
                    margin: 15px 0;
                }
                a {
                    margin: 0 0 10px 0;
                }
            }
        }
        .register {
            padding: 0 15%;
            border-left: none;
            width: 100%;
            height: 50%;
        }
    }
    .register-container div.login #login-form, .register-container div.login #register-form, .register-container div.register #login-form, .register-container div.register #register-form {
        position: relative;
        width: 80%;
    }
    main {
        margin-top: 120px;
    }
    .register-container {
        flex-wrap: wrap;
        height:auto;
        .login {
            width: 100%;
            padding: 0;
            height:auto;
            #register-form {
                height: 60%;
            }
        }
       div.register {
            padding: 0;
            border-left: none;
            width: 100%;
            form {
                height: 80%;
            }
        }
    }
    .shop-all {
        .container {
            .row {
                padding: 0 60px;
                .box {
                    margin-bottom: 40px;
                    padding: 0 5px;
                }
            }
            .shop-all-header {
                font-size: 28px;
            }
            .blocks-nav {
                button {
                    width: 30px;
                    height: 30px;
                    margin: 10px;
                }
            }
        }
    }
    .about {
        .container {
            .ceo {
                margin: 0 20px;
                .col-12 .box .text-content {
                    font-size: 14px;
                }
            }
            .stylish-everyday {
                margin: 20px;
                .text-content {
                    padding: 20px 0;
                    h4 {
                        font-size: 20px;
                        margin-bottom: 10px;
                    }
                    p {
                        font-size: 14px;
                    }
                }
                a {
                    font-size: 16px;
                }
            }
            .about-header {
                font-size: 20px;
                padding: 20px 0px;
            }
            .about-header.bottom {
                padding-bottom: 20px;
            }
        }
    }
    .my-data {
        .container {
            .row {
                .account-nav {
                    position: fixed;
                    left: 0;
                    top: 64px;
                    border-bottom: 1px solid black;
                    transform: translateY(-100%);
                    background-color: white;
                    justify-content: flex-start;
                    z-index: 10;
                    display: flex;
                    width: 100%;
                    margin: 0;
                    .data {
                        max-width: 100%;
                        position: relative;
                        width: 100%;
                        margin: 0;
                        display: flex;
                        border: none;
                        border-top: none;
                        padding: 0;
                        background-color: white;
                        flex-wrap: nowrap;
                        justify-content: left;
                        p {
                            width: auto;
                            margin: 10px 0 10px 10px;
                            display: flex;
                            padding-top: 0;
                            a {
                                width: 100%;
                                line-height: 1;
                            }
                        }
                        .account-nav-btn {
                            position: absolute;
                            top: 100%;
                            left: 0;
                            justify-content: center;
                            align-content: center;
                            background-color: white;
                            display: flex;
                            height: 22px;
                            border: 1px solid black;
                            img {
                                height: 100%;
                                padding: 2px;
                            }
                        }
                        .account-nav-btn:hover {
                            transform: scale(1.1);
                        }
                        .account-nav-btn.rotate {
                            top: 100%;
                            transform: rotate(180deg);
                        }
                        .account-nav-btn.rotate:hover {
                            transform: rotate(180deg) scale(1.1);
                        }
                    }
                }
                .account-nav.show {
                    left: 0px;
                    width: 100%;
                    height: auto;
                    transform: translateY(0);
                }
                .panel {
                    padding: 30px 20px;
                    input {
                        margin: 2px;
                    }
                    .panel-header {
                        margin: 20px 0 70px 0;
                    }
                    .panel-header.orders {
                        margin: 20px 0 40px 0;
                    }
                    form {
                        .newsletter {
                            padding: 0;
                            div {
                                button {
                                    padding: 10px 70px;
                                }
                            }
                        }
                    }
                    .order-row {
                        padding: 30px 0 30px 0;
                        margin-right: 0;
                        font-size: 14px;
                        .order-descriptions .delivery span img {
                            max-width: 20px;
                            max-height: 20px;
                        }
                        .order .order-number p {
                            font-size: 14px;
                        }
                    }
                    .summary {
                        height: 200px;
                        .summary-data {
                            h4 {
                                font-size: 18px;
                                margin-bottom: 15px;
                            }
                            p {
                                font-size: 12px;
                                margin-bottom: 10px;
                            }
                        }
                        .summary-price {
                            h5 {
                                font-size: 14px;
                            }
                            h4 {
                                font-size: 18px;
                            }
                            p {
                                font-size: 12px;
                            }
                        }

                    }
                    .returns-content {
                        margin-bottom: 20px;
                        padding-right: 0;
                    }
                    .returns-instruction-btn {
                        top: 75px;
                        right: 20px;
                    }
                    .see-more {
                        padding: 15px 80px;
                        margin: 40px 0 0 100px;
                        font-size: 14px;
                    }
                    .return-addres {
                        h5 {
                            margin: 40px 0 15px;
                            font-size: 18px;
                        }
                        .uppercase {
                            margin-bottom: 10px;
                        }
                        font-size: 14px;
                    }
                }
            }
            .instruction-content {
                width: 560px;
                padding: 35px 40px;
                overflow-y: scroll;
                @include scrollbars(5px, 8px, white, lightgray, none, 0);
            }
        }
    }
    footer .container .row-padding .col-flex-2 p,
    footer .container .row-padding .showroom .showroom-text a,
    footer .container .row-padding .col-flex a{
        color:#000;
    }
    .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-lg-4 {
        padding: 0;
    }
    .my-data .container .row .account-nav.scroll {
        top: 44px
    }
    .shopping-cart {
        .shopping {
            padding: 30px 20px 10px;
            font-size: 12px;
            .item-row {
                padding: 20px 0;
                height: 250px;
                .item-content {
                    img {
                        margin-right: 15px;
                    }
                    .item-description .dropdown {
                        button{
                            font-size: 14px;
                        }
                    }
                }
                .item-value {
                    p {
                        padding-bottom: 8px;
                    }
                }
            }
            .information {
                margin-top: 30px;
                margin-bottom: 10px;
                padding: 30px;
            }
        }
        .shopping-card {
            border-bottom: 1px solid black;
            position: static;
            z-index: 4;

            width: 100%;
            height: auto;
            overflow-y: auto;
            overflow-x: visible;
            align-content: center;
            background-color: white;
            @include scrollbars(5px, 8px, white, lightgray, none, 0);
            .payment {
                position: static;
                font-size: 12px;
                .payment-forms {
                    p {
                        font-size: 12px;
                    }
                    a {
                        padding-right: 5px;
                        img {
                            transform: scale(0.7);
                        }
                    }
                    a:hover {
                        img {
                            transform: scale(0.75);
                        }
                    }
                }
            }
        }
        .shopping-card.active {
            right: 0;
        }
        h4 {
            font-size: 14px;
        }
        .shopping-nav-btn {
            position: fixed;
            top: 30%;
            right: -1px;
            justify-content: center;
            align-content: center;
            background-color: white;
            display: flex;
            height: 30px;
            width: 30px;
            border: 1px solid black;
            img {
                height: 100%;
                padding: 2px;
                transform: rotate(90deg);
            }
        }
        .shopping-nav-btn:hover {
            transform: scaleY(1.1);
        }
        .shopping-nav-btn.rotate {
            transform: rotate(180deg);
            right: 229px;
        }
        .shopping-nav-btn.rotate:hover {
            transform: rotate(180deg) scaleY(1.1);
        }
        .shopping-card.choices {
            right: -250px;
        }
        .shopping-card.choices.active {
            right: 0;
        }
    }
    .choices .confirm-returned-info {
        padding: 30px;
        h5 {
            font-size: 18px;    
            padding: 25px 5px 30px;
        }
        a {
            font-size: 14px;
        }
    }
    .newsletter2-popap.active,
    .newsletter-popap.active,
    .table-popup.active,
    .product-popap.active {
        .product-popap-content {
            padding: 15px 20px;
            .product-describe {
                padding: 10px 0;
            }
            a {
                font-size: 12px;
                padding: 10px 0;
                button {
                    padding: 12px;
                }
            }
        }
        h3 {
            padding: 10px 0;
            font-size: 16px;
        }
    }
    .empty-cart {
        .empty-content {
            padding: 60px 0;
            img {
                margin-bottom: 20px;
                transform: scale(0.8);
            }
            h2 {
                font-size: 32px;
                margin: 0 0 15px 0;
            }
            button {
                a {
                    padding: 18px 0;
                }
            }
        }
    }
    .message {
        form {
            h4 {
                margin: 10px;
            }
            label {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                input {
                    max-width: 100%;
                }
            }
            input {
                padding: 16px;
                margin: 5px;
            }
            textarea {
                margin: 5px;
                padding: 16px;
            }
            .permission {
                flex-wrap: nowrap;
                margin: 15px 5px;
            }
            input[type="submit"],
            button {
                margin: 5px 5px 10px 5px;
                padding: 16px;
            }

        }
    
    }
    .showroom {
        .row {
            margin: 20px 30px;
            .showroom-content {
                // padding: 0 30px;
                padding: 0;
                margin: 15px 0;
                .border-div {
                    .text-content {
                        padding-left: 15px;
                        h4 {
                            font-size: 24px;
                            margin-bottom: 10px;
                        }
                        p {
                            line-height: 1.5;
                            margin-bottom: 5px;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
        h2 {
            font-size: 24px;
            line-height: 1.2;
            margin: 15px 0;
        }
        p {
            font-size: 12px;
            margin-bottom: 15px;
        }
    }
    .payment-end {
        .payment-method {
            padding: 10px 20px;
            h2 {
                padding: 20px 0;
            }
            form .adres .resp {
                flex-wrap: wrap;
            }
        }
        .payment-nav {
            position: fixed;
            right: -250px;
            background-color: white;
            height: calc(100% - 84px);
            width: 250px;
            z-index: 11;
            .payment {
                width: auto;
                padding: 50px 10px 5px 10px;
            }
        }
        .payment-nav.active {
            right: 0;
            overflow-y: auto;
        }
        .payment-nav-btn {
            position: fixed;
            right: -1px;
            border: 1px solid black;
            top: 300px;
            display: flex;
            justify-content: center;
            align-content: center;
            height: 30px;
            width: 30px;
            padding: 0;
            background-color: white;
            transform: rotate(90deg);
            z-index: 11;
            img {
                height: 100%;
                padding: 2px;
            }
        }
        .payment-nav-btn:hover {
            transform: scaleY(1.1) rotate(90deg);
        }
        .payment-nav-btn.rotate {
            right: 249px;
            transform: rotate(270deg);
        }
    }
    .product-details {
        .row {
            padding: 20px 20px 0;
            .side {
                padding-right: 5px;
                .first {
                    padding-bottom: 5px;
                }
                .second {
                    padding: 1.25px 0;
                }
                .third {
                    padding-top: 5px;
                }
            }
            .main .main-content {
                padding: 15px;
                form {
                    margin-bottom: 10px;
                    .chose-color {
                        .checkmark {
                            width: 20px;
                            height: 20px;
                        }
                        .checkbox-container {
                            .checkmark::after {
                                width: 18px;
                                height: 18px;
                            }
                        }
                    }
                    .space-beetwen {
                        p {
                            margin-bottom: 0;
                        }
                    }
                }
                h3 {
                    font-size: 16px;
                }
                p {
                    font-size: 16px;
                    margin: 5px 0 5px 0;
                }
                .share {
                    p {
                        font-size: 14px;
                        margin-bottom: 5px;
                    }
                }
                .about-product {
                    margin: 5px 0 10px;
                    p {
                        margin: 5px 0;
                    }
                }
                .price {
                    margin: 5px 0 10px;
                }
            }
            
        }
        .lack-of-product {
            width: 350px;
            height: 200px;
            padding: 30px;
            form {
                input {
                    padding: 15px;
                }
                button {
                    padding: 15px;
                }
            }
            h4 {
                font-size: 18px;
            }
            .close-lack-popap {
                right: -15px;
                top: -20px;
            }
        }
        .close-up {
            .box-img {
                max-width: 400px;
                max-height: 600px;
                img {
                    object-fit: contain;
                }
            }
            button {
                img {
                    width: 30px;
                    height: 30px;
                }
            }
            .prev-img {
                left:  -65px;
            }
            .next-img {
                right: -65px;
            }
    
        }
    }

}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 575px) {
    .promo-strap {
        p {
            font-size: 12px;
            letter-spacing: 0.3px;
        }
    }
    .black-banner {
        .container {
            .row-padding {
                padding: 20px 60px;
                .col-md-4 {
                    max-height: 300px;
                }
            }
        }
    } 
    footer {
        z-index: 2;
        .container {
            .row-padding {
                padding: 0 20px;
                .col-flex-2 {
                    padding: 15px 0;
                }
                .showroom {
                    .showroom-text {
                        width: 100%;
                    }
                    .read-more {
                        a {
                            margin-top: 0;
                        }
                        img {
                            top: 52%;
                        }
                    }
                }
                .social {
                    .contact {
                        p {
                            margin-bottom: 5px;
                        }
                    }
                    .visit-us {
                        margin-top: 5px;
                        p {
                            margin-bottom: 0;
                        }
                        .social-media {
                            margin-bottom: 0;
                            a {
                                height: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
    .photo-banner .container {
        .photo-arrow {
            height: 30px;
            width: 20px;
        }
        .photo-content {
            top: 22%;
            .photo-title {
                font-size: 35px;
                margin-bottom: 10px;
            }
            .underline-text {
                font-size: 12px;
            }
        }
        .photo-content-second {
            top: 45%;
            p {
                font-size: 12px;
                margin-bottom: 5px;
            }
            .photo-title-smaller {
                font-size: 30px;
            }
        }
        .text-content.about {
            bottom: 20%;
            a {
                font-size: 12px;
                .bigger {
                    padding-top: 5px;
                    font-size: 20px;
                }
            }
        }
    }
    .personal-stylist {
        height: 900px;
        .black-banner {
            .container {
                .row-padding {
                    padding: 20px 60px;
                    .slideshow-container {
                        .slide {
                            align-content: center;
                            .text-row {
                                height: 8%;
                                h2 {
                                    padding-top: 5px;
                                    font-size: 22px;
                                }
                                p {
                                    font-size: 12px;
                                    padding: 10px;
                                }
                            }
                            .col-md-4 {
                                padding-right: 0;
                                margin-bottom: 10px;
                                height: 25%;
                                .box-img {
                                    height: 100%;
                                    margin-top: 20px;
                                    .text-content {
                                        width: 100%;
                                        a {
                                            font-size: 18px;
                                            .product-price {
                                                font-size: 16px;
                                            }
                                        }
                                    }
                                }
                            }
                            .col-md-3 {
                                height:40%;
                                padding-left: 0;
                                .text-content {
                                    top: 60%;
                                    transform: translate(-50%, -50%);
                                    width: 100%;
                                    a {
                                        font-size: 22px;
                                        .product-price {
                                            padding: 5px;
                                            font-size: 16px;
                                        }
                                    }
                                }
                                .box-img {
                                    height: 50%;
                                    max-height: 50%;
                                }
                                .mb {
                                    margin-bottom: 5px;
                                }
                                .mt {
                                    margin-top: 5px;
                                }
                            }
                        }
                    }
                    .stylist-arrow {
                        top: 55%;
                    }
                }
            }
        }
    } 
    .new-collection {
        padding-top: 10px;
        .row-padding {
            padding: 20px;
            padding-bottom: 0;
            .col-md-8 {
                margin-bottom: 15px;
                .box-v2 {
                    .text-content {
                        height: 35%;
                        .new-collection-header {
                            font-size: 14px;
                        }
                    }
                    .box-img2 {
                        height: 65%;
                    }
                }
            }
            .box-v2 {
                .box-text {
                    bottom: 5%;
                    a {
                        font-size: 14px;
                    }
                }
            }
            .intrested-col {
                max-width: 60%;
                padding: 0;
            }
        }
        .container {
            .row-border {
                justify-content: center;
                h3 {
                    text-align: center;
                }
            }
        }
    }
    header {
        .container {
            .navbar {
                padding: 15px 30px;
                .navbar-toggler {
                    padding: 0;
                }
                .navbar-title-img {
                    left: 49%;
                }
                .collapse {
                    .navbar-icons {
                        .account {
                            right: 24px;
                        }
                        .cart {
                            right: -33px;
                        }
                    }
                }
            }
        }
    }
    .shop-new-collection {
        .container .row {
            .col-12 {
                margin-top: 30px;
                padding: 0;
                .box {
                    max-width: 70%;
                    a {
                        height: auto;
                    }
                    .text-content {
                        height: auto;
                    }
                }
            }
            .col-sm-6 .box {
                max-width: 70%;
            }
            .head-text {
                font-size: 14px;
            }
            .right, .left {
                justify-content: center;
            }
        }
    }
    .panel-path{
        display:none;
    }
    .product-details .close-up{
        width:80%;
    }
    .shop-product .container .row {
        padding: 40px 0 20px 0;
        .col-xxl-2 {
            a {
                height: 85%;
            }
            .text-content {
                height: 15%;
                padding-bottom: 0px;
                a {
                    align-content: flex-start;
                }
            }
        }
    }
    .login-container {
        .login {
            padding: 0 2%;
            form {
                height: 90%;
                h4 {
                    margin-bottom: 20px;
                }
                button {
                    margin: 10px 0;
                }
            }
        }
        .register {
            padding: 0 2%;
            form {
                height: 65%;
                h4 {
                    margin-bottom: 20px;
                }
            }
        }
    }
    .register-container {
        flex-wrap: wrap;
        .login {
            width: 100%;
            padding: 0 2%;
            #register-form {
                h4 {
                    margin-bottom: 10px;
                }
                button {
                    height: 40px;
                    margin: 10px 0;
                }
            }
        }
        .register {
            padding: 0 2%;
            border-left: none;
            width: 100%;
            height: 70%;
            form {
                height: 80%;
                input {
                    padding: 5px;
                }
                h4 {
                    margin-bottom: 10px;
                }
                .permission {
                    margin: 10px 0;
                }
                button {
                    height: 40px;
                    margin: 10px 0;
                }
            }
        }
    }
    .shop-all {
        .container {
            .panel-path,
            .row {
                padding: 0 40px;
                .box {
                    margin-bottom: 30px;
                }
            }
            .shop-all-header {
                padding: 20px 0;
            }
            .blocks-nav {
                button {
                    width: 25px;
                    height: 25px;
                    margin: 5px;
                }
            }
        }
    }
    .about {
        .container {
            .ceo .col-12 .box {
                width: 65%;
                .text-content {
                    padding: 10px 0;
                }
            }
            .stylish-everyday {
                margin: 10px 20px;
                .text-content {
                    padding: 15px 0;
                    h4 {
                        font-size: 16px;
                        margin-bottom: 10px;
                    }
                    p {
                        font-size: 14px;
                    }
                }
                a {
                    font-size: 14px;
                }
            }
            .about-header {
                font-size: 16px;
                padding: 10px 0px;
            }
            .about-header.bottom {
                padding-bottom: 10px;
            }
        }
    }
    .my-data {
        .container {
            .row {
                .panel {
                    .panel-header {
                        margin: 20px 0 50px 0;
                        font-size: 28px;
                    }
                    .panel-header.orders {
                        margin: 20px 0 30px 0;
                    }
                    form {
                        .newsletter {
                            div {
                                justify-content: center;
                                margin-top: 20px;
                                button {
                                    padding: 10px 50px;
                                }
                                button:hover {
                                    transform: scale(1.07);
                                }
                            }
                        }
                        .flex-label {
                            width: 100%;
                            flex-wrap: wrap;
                            margin-bottom: 1px;
                            input {
                                margin: 0 0 10px 0;
                                width: 100%;
                                max-width: 100%;
                            }
                        }
                        .adres {
                            width: 100%;
                            margin-bottom: 22px;
                            .resp {
                                flex-wrap: wrap;
                                .cont {
                                    margin-bottom: 10px;
                                }
                            }
                            .checkbox-container {
                                margin-right: 10px;
                            }
                        }
                        .add-adres {
                            label {
                                flex-wrap: wrap;
                                margin: 0;
                                input {
                                    margin: 0 0 10px 0;
                                    max-width: 100%;
                                    width: 100%;
                                }
                            }
                        }
                        .invoice-dropdown.visible label{
                            margin: 0;
                            input {
                                margin-bottom: 10px;
                                width: 100%;
                            }
                        }
                        p {
                            margin: 20px 0;
                        }
                        .btn-container {
                            height: 40px;
                            margin: 30px 0;
                            button {
                                padding: 5px 25px;
                            }
                        }
                        .customer {
                            width: 100%;
                            margin-bottom: 20px;
                            .checkbox-container {
                                margin-bottom: 10px;
                            }
                        }
                    }
                    .order-row {
                        height: 250px;
                        padding: 20px 0;
                        margin-right: 0;
                        font-size: 12px;
                        .order {
                            .order-number p {
                                font-size: 12px;
                            }
                            .order-items .box-img {
                                margin-right: 10px;
                            }
                        }
                        .order-descriptions .delivery p span {
                            display: block;
                        }
                    }
                    .summary {
                        height: 200px;
                        .summary-data {
                            h4 {
                                font-size: 16px;
                                margin: 5px 0 15px;
                            }
                        }
                        .summary-price {
                            padding-left: 5px;
                            h5 {
                                font-size: 12px;
                                margin: 6px 0;
                            }
                            h4 {
                                font-size: 16px;
                                margin: 8px 0 0;
                            }
                        }
                    }
                    .return-btn-container {
                        height: auto;
                        // button {
                        //     padding: 10px 80px;
                        // }
                    }
                    .returns-instruction-btn {
                        top: 105px;
                        right: 20px;
                    }
                    .see-more {
                        padding: 12px 60px;
                        margin: 30px 0 0 60px;
                        font-size: 14px;
                    }
                    .return-addres {
                        h5 {
                            margin: 30px 0 10px;
                        }
                        .uppercase {
                            margin-bottom: 8px;
                        }
                        font-size: 12px;
                    }
                }
            }
            .instruction-content {
                width: 350px;
                padding: 15px 20px;
                button {
                    top: 5px;
                    right: 5px;
                }
                h4 {
                    font-size: 16px;
                    line-height: 1.5;
                    padding-bottom: 0;
                }
                h5 {
                    font-size: 14px;
                    padding: 25px 0 10px;
                }
                p {
                    font-size: 12px;
                    line-height: 1.4;
                    padding: 5px 0;
                }
                a {
                    font-size: 12px;
                    padding: 5px 0 10px;
                }
            }
        }
    }
    .shopping-cart {
        .shopping {
            padding: 30px 20px 10px;
            overflow: visible;
            h2 {
                font-size: 28px;
            }
            .item-row {
                height: 200px;
                .item-content {
                    .item-description .dropdown {
                        .dropdown-menu {
                            z-index: 10;
                            font-size: 12px;
                            .dropdown-item {
                                z-index: 11;
                            }
                        }
                        button {
                            padding: 2px 8px;
                        }
                    }
                    img {
                        max-width: 110px;
                    }
                    a {
                        margin-right: 5px;
                    }
                }
            }
            .item-value p {
                font-size: 14px;
            }
            .information {
                margin-top: 20px;
                padding: 25px;

            }
        }
    }
    .choices {
        .select-all img{
            width: 20px;
            height: 20px;
        }
        .item-content {
            .checkbox-btn {
                margin-right: 20px;
                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
        .confirm-returned-info {
            padding: 20px;
            width: 300px;
            h5 {
                font-size: 18px;    
                padding: 25px 5px 30px;
            }
            a {
                font-size: 14px;
            }
        }
    }
    .newsletter2-popap.active,
    .newsletter-popap.active,
    .table-popup.active,
    .product-popap.active {
        width: 80%;
        .product-popap-content {
            padding: 30px 20px;
            .product-describe {
                .box-content {
                    padding-left: 10px;
                    h4 {
                        font-size: 14px;
                    }
                    span {
                        font-size: 14px;
                    }
                    p {
                        font-size: 12px;
                    }
                }
            }
            a {
                button {
                    padding: 12px 70px;
                }
            }
        }
        h3 {
            font-size: 16px;
        }
    }
    .empty-cart {
        .empty-content {
            padding: 40px 0;
            img {
                margin-bottom: 10px;
                transform: scale(0.8);
            }
            h2 {
                width: 100%;
                text-align: center;
                margin: 10px 0;
                font-size: 26px;
            }
            button {
                width: 70%;
                font-size: 16px;
                margin: 15px 0;
                a {
                    padding: 12px;
                }
            }
            a {
                padding: 10px 0;
                font-size: 14px;
            }
            a:hover {
                text-decoration: none;
            }
        
        }
    }
    .message {
        form {
            max-width: 350px;
            h4 {
                font-size: 20px;
                line-height: 1.5;
            }
            input {
                padding: 12px;
            }
            textarea {
                padding: 12px;
            }
            input[type="submit"],
            button {
                padding: 14px;
            }
        }
    
    }
    .showroom {
        .row {
            margin: 20px;
            .showroom-content {
                padding: 0;
                margin: 10px 0;
                .border-div {
                    flex-wrap: wrap;
                    .text-content {
                        padding: 20px;
                        h4 {
                            font-size: 20px;
                            text-align: center;
                            margin-bottom: 10px;
                        }
                        p {
                            text-align: center;
                            line-height: 1.5;
                            margin-bottom: 5px;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
        h2 {
            font-size: 18px;
            line-height: 1.2;
            margin: 10px 0;
        }
        p {
            font-size: 12px;
            margin-bottom: 10px;
        }
    }
    .payment-end {
        .payment-method {
            h2 {
                padding: 15px 0;
                font-size: 24px;
                p {
                    margin: auto 15px auto 0;
                }
            }
            form {
                .adres {
                    flex-wrap: wrap;
                }
                .cont {
                    padding: 10px;
                    input {
                        margin: auto 5px;
                    }
                    p {
                        font-size: 14px;
                    }
                    span {
                        font-size: 10px;
                    }
                    .price {
                        font-size: 12px;
                        width: 60px;
                    }
                    img {
                        max-height: 30px;
                    }
                }
                .wrap-label {
                    .delivery-addres {
                        padding: 15px 0 10px 34px;
                        span {
                            font-size: 14px;
                        }
                        .addres-data {
                            font-size: 12px;
                            a {
                                font-size: 12px;
                            }
                        }
                    }
                }
                .master-card {
                    padding: 10px;
                    .cont {
                        padding-left: 0;
                        padding-right: 0;
                        margin-top: 0;
                        input {
                            margin: auto 5px;
                        }
                        img {
                            max-height: 30px;
                        }
                        p {
                            font-size: 14px;
                        }
                    }
                    label {
                        width: 100%;
                        input {
                            font-size: 12px;
                        }
                    }
                    span {
                        width: 100%;
                        font-size: 12px;
                    }
                }
                button {
                    p {
                        font-size: 12px;
                        span {
                            font-size: 12px;
                        }
                    }
                }
                .regulamin {
                    font-size: 10px;
                    width: 100%;
                }
            }
        }
    }
    .product-details {
        .row {
            .side {
                display: none;
            }
            .main {
                width: 100%;
                flex-wrap: wrap;
                justify-content: center;
                .box-img {
                    width: 60%;
                    img {
                        display: none;
                    }
                    .slider {
                        display: block;
                        .slick-list {
                            .slick-track {
                                display: flex;
                                align-self: center;
                                align-items: center;
                                align-content: center;
                                img {
                                    display: block;
                                    margin-right: 10px;
                                    width: 100%;
                                    height: 100%;
                                }
                                img:hover {
                                    transform: scale(1.07);
                                }
                            }
                        }
                        .slick-list:hover {
                            img {
                                transform: scale(1);
                            }
                        } 
                    }
                }
                .main-content {
                    padding: 20px 0;
                    border: none;
                    width: 100%;
                }
            }
        }
        .close-up {
            .box-img {
                max-height: 500px;
                max-width: 320px;
                width: auto;
                img {
                    object-fit: cover;
                    width: 100%;
                }
            }

            button {
                img {
                    width: 25px;
                    height: 25px;
                }
            }
            .prev-img {
                left:  -30px;
            }
            .next-img {
                right: -30px;
            }
        }
    }
    .shop-all .container .row .box{
        max-height:none;
    }
}

@media (max-width: 375px) {
    .promo-strap {
        p {
            font-size: 12px;
            letter-spacing: -0.5px;
        }
    }
    .black-banner {
        .container {
            .row-padding {
                .col-md-4 {
                    max-height: 500px;
                    .box-img {
                        margin: auto;
                        width: 100%;
                        height: 100%;
                        img {
                            width: 100%;
                            height: auto;
                            object-fit: cover;
                        }
                    }
                    .subtitle {
                        font-size: 22px;
                    }
                }
            }
        }
    } 
    footer {
        .container {
            .row-padding {
                padding: 10px 20px;
                .showroom .read-more img {
                    display: none;
                }
            }
        }
    }
    .photo-banner {
        .container {
            .photo-content {
                top: 15%;
                .photo-title {
                    font-size: 26px;
                    margin-bottom: 5px;
                }
                .photo-arrow {
                    width: 12px;
                    height: 30px;
                }
                .underline-text {
                    font-size: 12px;
                    margin-bottom: 5px;
                }
            }
            .photo-content-second {
                top: 40%;
                p {
                    font-size: 12px;
                    margin-bottom: 5px;
                }
                .photo-title-smaller {
                    font-size: 24px;
                }
            }
            .text-content.about {
                bottom: 20%;
                a {
                    font-size: 12px;
                    .bigger {
                        padding-top: 5px;
                        font-size: 16px;
                    }
                }
            }
        }
        h2 {
            font-size: 42px;
        }
    }
    .personal-stylist {
        .black-banner {
            .container {
                .row-padding {
                    padding: 20px 40px;
                    .slideshow-container {
                        .slide {
                            .text-row {
                                h2 {
                                    font-size: 14px;
                                    margin-bottom: 0;
                                }
                                p {
                                    font-size: 10px;
                                    margin-bottom: 0;
                                    padding-top: 5px;
                                }
                            }
                            .col-md-5 {
                                .box-img {
                                    .text-content {
                                        a {
                                            font-size: 14px;
                                            .product-price {
                                                font-size: 12px;
                                            }
                                        }
                                    }
                                }
                            }
                            .col-md-3 {
                                .box-img {
                                    .text-content {
                                        top: 55%;
                                        a {
                                            font-size: 14px;
                                            .product-price {
                                                font-size: 12px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .stylist-arrow {
                        top: 53%;
                    }
                }
            }
        }
    } 
    .new-collection {
        padding-top: 10px;
        .row-padding {
            padding: 20px;
            .col-md-8 {
                margin-bottom: 10px;
                .box-v2 {
                    display: flex;
                    flex-wrap: wrap;
                    .text-content {
                        .new-collection-header {
                            font-size: 12px;
                        }
                    }
                    .box-img2 {
                        max-height: 50%;
                        a {
                            width: 100%;
                        }
                        img {
                            height: 100%;
                        }
                    }
                }
            }
            .box-v2 {
                padding-bottom: 50px;
                .box-text {
                    bottom: 0%;
                    a {
                        font-size: 10px;
                    }
                }
            }
            .intrested-col {
                max-width: 100%;
            }
        }
    }
    header .container .navbar {
        padding: 10px 15px;
        .navbar-title-img {
            left: 49%;
            max-width: 120px;
            img {
                object-fit: contain;
                width: 100%;
            }
        }
        .collapse {
            .navbar-icons {
                .account {
                    right: 12px;
                }
                .cart {
                    right: -44px;
                }
            }
        }
    }
    .shop-new-collection {
        .container .row {
            .col-12 {
                margin-top: 20px;
            }
            .head-text {
                font-size: 12px;
            }
        }
    }
    .login-container {
        .register {
            height: 50%;
            form {
                height: 60%;
                button {
                    height: 40px;
                    margin: 10px 0;
                }
                h4 {
                    margin-bottom: 10px;
                }
                .content-adverb {
                    width: 100%;
                    font-size: 12px;
                }
            }
        }
        .login form {
            button {
                height: 40px;
                margin: 10px 0;
            }
            h4 {
                margin-bottom: 10px;
            }
        }
    }
    .register-container {
        .login {
            #register-form {
                height: 70%;
                button {
                    height: 25px;
                    margin: 10px 0;
                }
                h4 {
                    margin-bottom: 10px;
                }
            }
        }
        .register {
            padding: 0 2%;
            border-left: none;
            width: 100%;
            #register-form {
                input {
                    padding: 5px;
                }
                .permission {
                    margin: 10px 0;
                }
                button {
                    height: 40px;
                    margin: 0 0 10px 0;
                }
                h4 {
                    margin-bottom: 0;
                }
                .date-id {
                    display: block;
                    #name {
                        margin-right: 0;
                    }
                    #surname {
                        margin-left: 0;
                    }
                }
                p {
                    font-size: 12px;
                }
            }
        }
    }
    .shop-all {
        .container {
            .panel-path,
            .row {
                padding: 0 25px;
                .box {
                    margin-bottom: 30px;
                }
            }
            .blocks-nav {
                button {
                    font-size: 14px;
                    width: 20px;
                    height: 20px;
                    margin: 5px;
                }
            }
        }
    }
    .about {
        .container {
            .ceo .col-12 .box {
                width: 85%;
            }
            .stylish-everyday {
                margin: 10px 20px;
                .text-content {
                    padding: 15px 0;
                    h4 {
                        font-size: 16px;
                        margin-bottom: 10px;
                    }
                    p {
                        font-size: 12px;
                    }
                }
                a {
                    font-size: 12px;
                }
            }
            .about-header {
                font-size: 14px;
                padding: 10px 0px;
            }
            .about-header.bottom {
                padding-bottom: 10px;
            }
        }
    }
    .my-data {
        .container {
            .row {
                .panel {
                    .panel-path {
                        font-size: 10px;
                        img {
                            padding: 0 10px;
                            width: 30px;
                        }
                    }
                    .panel-header {
                        margin-bottom: 30px;
                    }
                    .panel-header.orders {
                        margin: 20px 0 15px 0;
                    }
                    form {
                        font-size: 12px;
                        .gender {
                            margin-bottom: 15px;
                            width: 100%;
                            .checkbox-container {
                                font-size: 12px;
                                padding-left: 20px;
                                .adres-name {
                                    font-size: 14px;
                                }
                            }
                            .checkmark {
                                height: 15px;
                                width: 15px;
                            }
                            .checkbox-container .checkmark:after {
                                width: 13px;
                                height: 13px;
                                top: 0;
                                left: 0;
                                border: 1px solid white;
                            }
                        }
                        .newsletter {
                            label {
                                font-size: 12px;
                            }
                            div {
                                button {
                                    padding: 5px 30px;
                                }
                            }
                        }
                        p {
                            margin: 10px 0;
                        }
                        .adres {
                            .checkbox-container {
                                font-size: 12px;
                                padding-left: 20px;
                                .adres-name {
                                    font-size: 14px;
                                }
                            }
                            .checkmark {
                                height: 15px;
                                width: 15px;
                            }
                            .checkbox-container .checkmark:after {
                                width: 13px;
                                height: 13px;
                                top: 0;
                                left: 0;
                                border: 1px solid white;
                            }
                        }
                        .btn-container {
                            height: 30px;
                            margin: 20px 0 0 0;
                            button {
                                padding: 5px 15px;
                            }
                        }
                        .customer {
                            margin-bottom: 20px;
                            .checkbox-container {
                                margin-bottom: 20px;
                            }
                        }
                    }
                    .order-row {
                        height: 250px;
                        padding: 15px 0;
                        margin-right: 0;
                        font-size: 10px;
                        .order-descriptions {
                            padding-left: 5px;
                            .delivery span img {
                                max-width: 13px;
                                max-height: 13x;
                                margin-right: 5px;
                            }
                        }
                        .order {
                            .order-items {
                                .box-img {
                                    margin-right: 10px;
                                }
                            }
                            .order-number p {
                                font-size: 10px;
                            }
                        }
                    }
                    .summary {
                        height: 180px;
                        .summary-data {
                            h4 {
                                font-size: 14px;
                                margin-bottom: 10px;
                            }
                            p {
                                font-size: 10px;
                                margin-bottom: 5px;
                            }
                        }
                        .summary-price {
                            span {
                                text-align: right;
                            }
                            h4 {
                                font-size: 14px;
                            }
                            p {
                                font-size: 10px;
                            }
                        }
                        .order-value {
                            align-items: flex-end;
                            font-size: 12px;
                        }
                        .order-content .order-description {
                            padding-left: 10px;
                        }
                        h4 {
                            font-size: 16px;
                            span {
                                font-size: 12px;
                            }
                        }
                    }
                    .return-btn-container {
                        height: auto;
                        // button {
                        //     padding: 8px 60px;
                        //     font-size: 14px;
                        // }
                    }
                    .returns-content {
                        font-size: 16px;
                        margin-bottom: 0;
                        img {
                            width: 40px;
                            height: 40px;
                        }
                        a {
                            font-size: 12px;
                        }
                    }
                    .returns-instruction-btn {
                        top: 100px;
                        right: 20px;
                        font-size: 12px;
                    }
                    .see-more {
                        padding: 10px 40px;
                        margin: 20px 0 0 40px;
                        font-size: 12px;
                    }
                    .order-stats {
                        p {
                            font-size: 12px;
                        }
                    }
                    .return-addres {
                        h5 {
                            margin: 20px 0 10px;
                            font-size: 16px;
                        }
                        .uppercase {
                            margin-bottom: 8px;
                        }
                        p {
                            font-size: 10px;
                        }
                    }
                }
            }
            .instruction-content {
                width: 260px;
                height: 500px;
                h5 {
                    font-size: 14px;
                    padding: 15px 0 10px;
                }
            }
        }
    }
    .loged-account-popap {
        width: 100%;
        .loged-box {
            padding: 30px;
        }
    }
    .account-popap {
        // padding: 0 30px;
        width: 100%;
    }
    .shopping-cart {
        .shopping {
            padding: 30px 20px 10px;
            overflow: visible;
            .item-row {
                height: 150px;
                .item-content {
                    .item-description {
                        font-size: 10px;
                        .dropdown {
                            .dropdown-menu {
                                width: 50px;
                                max-width: 50px;
                                font-size: 10px;
                            }
                            button {
                                padding: 5px 1px 3px 5px;
                                font-size: 10px;
                                
                            }
                            button::after {
                                margin: 0 5px;
                                vertical-align: 0em;
                                transform: scale(0.7);
                            }
                    }
                    }
                    .item-name {
                        padding-top: 0;
                    }
                    img {
                        max-width: 80px;
                    }
                }
            }
            .item-value p {
                font-size: 12px;
            }
            h4 {
                font-size: 12px;
            }
            .information {
                margin-top: 15px;
                margin-bottom: 0;
                padding: 10px;
                h5 {
                    font-size: 14px;
                    margin-bottom: 20px;
                }
                p {
                    font-size: 12px;
                    margin-bottom: 10px;
                }
            }
        }
        .shopping-card {
            .payment {
                font-size: 12px;
                .total-price {
                    font-size: 13px;
                }
            }
        }

    }
    .choices {
        .choices.item-row {
            .item-content {
                .item-description {
                    .chose-reason {
                        width: 100%;
                        height: 30px;
                        select {
                            padding: 5px 10px;
                        }
                    }
                    .chose-reason::after {
                        width: 15px;
                        height: 15px;
                    }
                    p {
                        margin-bottom: 0;
                    }
                    .item-name {
                        font-size: 12px
                    }
                }
                .checkbox-btn {
                    width: 15px;
                    margin-right: 10px;
                    img {
                        width: 15px;
                        height: 15px;
                    }
                }
                img {
                    margin-right: 10px;
                }
            }
    
        }
        .select-all img {
            width: 15px;
            height: 15px;
        }
        .confirm-returned-info {
            padding: 15px;
            width: 250px;
            h5 {
                font-size: 16px;    
                padding: 15px 5px 20px;
                margin-bottom: 0;
            }
            a {
                font-size: 12px;
            }
        }
    }
    .newsletter2-popap.active,
    .newsletter-popap.active,
    .table-popup.active,
    .product-popap.active {
        width: 100%;
        .done {
            img {
                width: 15px;
                height: 15px;
            }
        }
        .product-popap-content {
            padding: 0px 10px;
            .product-describe {
                .box-content {
                    padding-left: 10px;
                    h4 {
                        font-size: 12px;
                    }
                    span {
                        font-size: 12px;
                    }
                    p {
                        font-size: 12px;
                    }
                }
            }
            a {
                button {
                    width: 100%;
                    padding: 12px;
                }
            }
        }
        h3 {
            padding: 5px 0;
            font-size: 12px;
            line-height: 1.5;
        }
    }
    .empty-cart {
        .empty-content {
            padding: 40px 0;
            width: 100%;
            img {
                margin-bottom: 0;
                transform: scale(0.6);
            }
            h2 {
                margin: 0 0 10px 0;
                font-size: 18px;
            }
            button {
                width: 70%;
                font-size: 14px;
                margin: 10px 0;
                a {
                    padding: 10px;
                }
            }
            a {
                padding: 10px 0;
                font-size: 12px;
            }
            a:hover {
                text-decoration: none;
            }
        
        }
    }
    .message {
        form {
            max-width: 265px;
            h4 {
                font-size: 18px;
                margin: 10px 0;
                line-height: 1.5;
            }
            input {
                padding: 8px;
            }
            textarea {
                padding: 8px;
            }
            .permission {
                margin: 10px 5px;
                p {
                    font-size: 14px;
                }
            }
            input[type="submit"],
            button {
                padding: 8px;
            }
        }
    
    }
    .showroom {
        .row {
            .showroom-content {
                .border-div {
                    .text-content {
                        h4 {
                            font-size: 20px;
                        }
                        p {
                            text-align: center;
                            line-height: 1.5;
                            margin-bottom: 5px;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
        h2 {
            font-size: 14px;
            line-height: 1.2;
            margin: 10px 0;
        }
        p {
            font-size: 12px;
            margin-bottom: 10px;
        }
    }
    .payment-end {
        .payment-method {
            form {
                .wrap-label {
                    .delivery-addres  {
                        padding: 15px 0 10px 0;
                        span {
                            font-size: 12px;
                        }
                        p {
                            font-size: 12px;
                        }
                    }
                }
                label {
                    padding: 5px;
                    input {
                        margin: auto 0;
                    }
                    .delivery-type {
                        margin: 0 10px 0 5px;
                    }
                    .delivery-type-x2 {
                        margin: 0 5px;
                    }
                    p {
                        font-size: 12px;
                    }
                    img {
                        margin: auto 30px auto 10px
                    }
                }
                .master-card {
                    .cont {
                        padding: 5px;
                        input {
                            margin: auto 0;
                        }
                        img {
                            margin: auto 30px auto 10px;
                        }
                        p {
                            font-size: 12px;
                        }
                    }
                    label {
                        input {
                            padding: 10px;
                        }
                        #card-number {
                            width: 40%;
                        }
                        #card-date {
                            width: 30%;
                        }
                        #card-cvv {
                            width: 30%;
                        }
                    }
                    span {
                        font-size: 10px;
                    }
                }
                button {
                    p {
                        font-size: 10px;
                        span {
                            font-size: 10px;
                        }
                    }
                }
            }
            .payment-header {
                padding: 10px 0;
            }
        }
    }
    .product-details {
        .row {
            .main {
                .box-img {
                    width: 100%;
                    img {
                        display: none;
                    }
                    .slider {
                        display: block;
                        img {
                            display: block;
                            width: 100%;
                            height: 100%;
                        }
                        // .slick-list.draggable {
                        //     height: 476px;
                        // }
                    }
                }
            }
        }
        .lack-of-product {
            width: 100%;
            height: 250px;
            padding: 30px;
            form {
                align-content: space-around;
                input {
                    padding: 10px;
                    font-size: 14px;
                }
                button {
                    padding: 10px;
                    font-size: 14px;
                }
            }
            h4 {
                font-size: 14px;
            }
            .close-lack-popap {
                right: -15px;
                top: -20px;
            }
        }
        .close-up {
            .box-img {
                max-height: 400px;
                max-width: 220px;
            }
        }
    }
}


@media only screen and (orientation: landscape) and (max-width: 1450px) and (max-height: 920px) {
    .register-container {
        .login{
            form {
                h4 {
                    margin-bottom: 20px;
                }
            }
        }
        .register {
            #register-form {
                h4 {
                    margin: 10px 0; 
                }
                input {
                    padding: 10px;
                }
                .permission {
                    margin: 10px 0;
                }
            }
        }
    }
    .login-container {
        .login{
            form {
                height: 65%;
                h4 {
                    margin-bottom: 20px;
                }
            }
        }
        .register {
            form {
                height: 45%;
                h4 {
                   margin: 10px 0; 
                }
                input {
                    padding: 10px;
                }
                .permission {
                    margin: 10px 0;
                }
            }
        }
    }
    .shop-all {
        .container {
            .panel-path,
            .row {
                .box {
                    padding: 0 20px;
                    max-height: 80vh;
                }
            }
        }
    }
}
@media only screen and (orientation: landscape) and (max-width: 920px) and (max-height: 500px) {
    .register-container {
        .login{
            margin-top: 40px;
            form {
                h4 {
                    margin-bottom: 20px;
                }
            }
        }
        .register {
            #register-form {
                h4 {
                   margin: 10px 0; 
                }
                input {
                    padding: 10px;
                }
                .permission {
                    margin: 10px 0;
                }
            }
        }
    }
    .login-container {
        height: 150%;
        .login{
            margin: 10px 0;
            form {
                h4 {
                    margin-bottom: 20px;
                }
            }
        }
        .register {
            height: auto;
            form {
                h4 {
                    margin: 10px 0; 
                }
                input {
                    padding: 10px;
                }
                .permission {
                    margin: 10px 0;
                }
            }
        }
    }
    .register-container {
        .login{
            form {
                h4 {
                    margin-bottom: 20px;
                }
            }
        }
        .register {
            #register-form {
                h4 {
                    margin: 10px 0; 
                }
                input {
                    padding: 10px;
                }
                .permission {
                    margin: 10px 0;
                }
            }
        }
    }
    .login-container {
        height: 200%;
        .login{
            form {
                h4 {
                    margin-bottom: 20px;
                }
            }
        }
        .register {
            form {
                height: 70%;
                h4 {
                    margin: 10px 0; 
                }
                input {
                    padding: 10px;
                }
                .permission {
                    margin: 10px 0;
                }
            }
        }
    }
    .shop-all {
        .container {
            .panel-path,
            .row {
                .box {
                    padding: 0 20px;
                    max-height: 115vh;
                }
            }
        }
    }
}
@media only screen and (orientation: landscape) and (max-width: 730px) and (max-height: 5500px) {
    .register-container {
        .login{
            form {
                h4 {
                    margin-bottom: 20px;
                }
            }
        }
        .register {
            #register-form {
                h4 {
                    margin: 10px 0; 
                }
                input {
                    padding: 10px;
                }
                .permission {
                    margin: 10px 0;
                }
            }
        }
    }
    .login-container {
        height: 200%;
        .login{
            form {
                h4 {
                    margin-bottom: 20px;
                }
            }
        }
        .register {
            form {
                height: 70%;
                h4 {
                   margin: 10px 0; 
                }
                input {
                    padding: 10px;
                }
                .permission {
                    margin: 10px 0;
                }
            }
        }
    }
}
@media only screen and (orientation: landscape) and (max-width: 700px) and (max-height: 400px) {
    .register-container {
        .login{
            form {
                h4 {
                    margin-bottom: 20px;
                }
            }
        }
        .register {
            #register-form {
                h4 {
                    margin: 10px 0; 
                }
                input {
                    padding: 10px;
                }
                .permission {
                    margin: 10px 0;
                }
            }
        }
    }
    .login-container {
        height: 200%;
        .login{
            form {
                h4 {
                    margin-bottom: 20px;
                }
            }
        }
        .register {
            form {
                height: 70%;
                h4 {
                   margin: 10px 0; 
                }
                input {
                    padding: 10px;
                }
                .permission {
                    margin: 10px 0;
                }
            }
        }
    }
    .shop-all {
        .container {
            .panel-path,
            .row {
                padding: 0 70px;
                .box {
                    padding: 0 25px;
                    max-height: 110vh;
                }
            }
        }
    }
    .my-data .container .row{
        .account-nav {
            height: calc(100% - 84px);
            .data {
                height: auto;  
                margin: 0;
            }
        }
    }
    .newsletter2-popap.active,
    .newsletter-popap.active,
    .table-popup.active,
    .product-popap.active {
        width: 50%;
        .done {
            img {
                width: 15px;
                height: 15px;
            }
        }
        .product-popap-content {
            padding: 0px 10px;
            .product-describe {
                .box-content {
                    padding-left: 10px;
                    h4 {
                        font-size: 12px;
                    }
                    span {
                        font-size: 12px;
                    }
                    p {
                        font-size: 10px;
                    }
                }
            }
            a {
                button {
                    width: 100%;
                    padding: 12px;
                }
            }
        }
        h3 {
            padding: 5px 0;
            font-size: 12px;
            line-height: 1.5;
        }
    }
}
@media (min-width: 512px) and (max-width: 540px) and (min-height: 717px) and (max-height: 720px) {
    .shop-all {
        .container {
            .panel-path,
            .row {
                padding: 0 80px;
                .box {
                    max-height: 80vh;
                }
            }
        }
    }
}
@import "scrollbars";
@font-face {
    font-family: 'Avenir Next';
    src: url('../fonts/subset-AvenirNext-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-AvenirNext-Bold.woff2') format('woff2'),
        url('../fonts/subset-AvenirNext-Bold.ttf') format('truetype'),
        url('../fonts/subset-AvenirNext-Bold.svg#AvenirNext-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('../fonts/subset-AvenirNext-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-AvenirNext-Regular.woff2') format('woff2'),
        url('../fonts/subset-AvenirNext-Regular.ttf') format('truetype'),
        url('../fonts/subset-AvenirNext-Regular.svg#AvenirNext-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('../fonts/subset-AvenirNext-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-AvenirNext-Medium.woff2') format('woff2'),
        url('../fonts/subset-AvenirNext-Medium.ttf') format('truetype'),
        url('../fonts/subset-AvenirNext-Medium.svg#AvenirNext-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next Ultra';
    src: url('../fonts/subset-AvenirNext-UltraLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-AvenirNext-UltraLight.woff2') format('woff2'),
        url('../fonts/subset-AvenirNext-UltraLight.ttf') format('truetype'),
        url('../fonts/subset-AvenirNext-UltraLight.svg#AvenirNext-UltraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next Demi';
    src: url('../fonts/subset-AvenirNext-DemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/subset-AvenirNext-DemiBold.woff2') format('woff2'),
        url('../fonts/subset-AvenirNext-DemiBold.ttf') format('truetype'),
        url('../fonts/subset-AvenirNext-DemiBold.svg#AvenirNext-DemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

* {
    margin: 0;
    padding: 0;
    font-family: 'Avenir Next';
    transition: 0.6s;
}
main {
    margin-top: 65px;
}
.container {
    max-width: 1920px;
    padding: 0;
    position: relative;
    .row {
        margin: 0;
    }
}
.promo-strap {
    color:  white;
    top: 0;
    z-index: 16;
    height: 48px;
    p {
        font-size: 12px;
        line-height: 30px;
        letter-spacing: 2px;
    }
}
header {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 11;
    .container {
        .navbar {
            z-index: 6;
            margin: auto;
            width: 100%;
            height: 64px;
            padding: 20px 80px;
            display: flex;
            transition:0.6s;
            align-content: center;
            background-color: white!important;
            button {
                border: none;
                transition: 0.3s;
                .navbar-toggler-icon {
                    transition: 0.3s;
                }
                .rotate {
                    transform: rotate(90deg);
                }
            }
            button:focus {
                box-shadow: none;
            }
            
            .collapse{
                display: flex;
                width: 100%;
                height: 100%;
                transition: 0.3s;
                z-index: 6;
                justify-content: space-between;
                .navbar-nav {
                    display: flex;
                    justify-content: space-between;
                    .nav-link {
                        color: #121212;
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        transition: 0.6s;
                    }
                    .nav-link::after {
                        transition: 0.1s;
                        background:url(../img/arrow.svg) center no-repeat;
                        background-size:14px;
                        border:none;
                        vertical-align: 2px;
                        width: 14px;
                        height: 8px;
                    }
                    .nav-link.rotate::after {
                        transform: rotate(180deg);
                    }
                    .nav-link:hover {
                        text-decoration: underline;
                    }
                    .dropdown-menu {
                        min-width: 20%;
                        left: 0;
                        height: 0;
                        display: flex;
                        flex-wrap: wrap;
                        opacity: 0;
                        background-color: rgba(var(--bs-light-rgb),var(--bs-bg-opacity))!important;
                        padding:2px 0;
                        transition: 0.3s;
                        border-radius:0;
                        .dropdown-item {
                            opacity: 0;
                            height: 0;
                            padding: 0;
                        }
                    }
                    .active {
                        opacity: 1;
                        height: auto;
                        .dropdown-item {
                            opacity: 1;
                            width: 100%;
                            height: auto;
                            padding: 1px 8px;
                        }
                        .dropdown-item:hover {
                            text-decoration: underline;
                            background-color: transparent;
                        }

                    }
                    .shop-menu {
                        position: absolute;
                        display: flex;
                        flex-wrap: wrap;
                        transition: .3s;
                        border-radius: 0;
                        background-color: white !important;
                        border: none;
                        color: black;
                        left: 89px;
                        top: 63px;
                        width: 150px;
                        min-width: 0;
                        .dropdown-item {
                            display: none;
                            background-color: white;
                            padding: 5px 20px;
                        }
                    }
                    .shop-menu.active {
                        padding: 5px 0;
                        .dropdown-item {
                            display: block;
                            opacity: 1;
                        }
                    }
                }
                .navbar-icons {
                    z-index: 6;
                    flex: 33%;
                    text-align: right;
                    display: flex;
                    justify-content: flex-end;
                    align-content: center;
                    height: 15px;
                    button {
                        background-color: transparent;
                        padding: 0;
                        border: none;
                        
                        img {
                            height: 15px;
                        }
                    }
                    button:hover {
                        opacity: 0.5;
                    }
                    form {
                        justify-content: flex-end;
                        align-items: flex-end;
                        transition: 0.3s;
                        display: flex;
                        margin: 0 10px;
                        input {
                            transition: 0.3s;
                            width: 50%;
                            border-top: none;
                            border-left: none;
                            border-right: none;
                        }
                        input:focus {
                            outline: none;
                            width: 100%;
                        }
                    }
                    .account {
                        margin: 0 10px;
                    }
                    .cart {
                        color: #121212;
                        text-decoration: none;
                        display: flex;
                        margin: 0 10px;
                        align-items: flex-end;
                        p {
                            font-size: 14px;
                            height: 100%;
                            padding-bottom: 17px;
                            margin: 0 0 0 2px
                        }
                        img {
                            height: 15px;
                        }
                    }
                    .cart:hover {
                        opacity: 0.5;
                    }
                    
                    .search {
                        display: flex;
                        justify-content: flex-end;
                        margin-right: 5px;
                    }
                }
            }
            .navbar-title-img {
                height: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                position: absolute;
                display: flex;
                z-index: 20;
                align-items: center;
                img:hover {
                    cursor: pointer;
                }
            }
            &.scroll{
                height:44px;
                padding-top:10px;
                padding-bottom:10px;
                .collapse .navbar-nav .shop-menu {
                    top: 40px;  
                }
            }
        }
    }
}
video {
    max-width: 100%;
    height: auto;
}
.mobile{
    display:none;
}
.photo-banner {
    width: 100%;
    position: relative;
    color: #fff;
    .container {
        .photo-arrow {
            display: flex;
            background-color: transparent;
            border: none;
            width: 41px;
            height: 96px;
            position: absolute;
            bottom: 15%;
            left: 50%;
            transition: 0.6s;
            transform: translateX(-50%);
            img {
                object-fit: contain;
                height: 100%;
            }
        }
        .photo-arrow:hover {
            bottom: 12%;
        }
        img {
            width: 100%;
            height: 85vh;
            object-fit: cover;
        }
        .photo-content { 
            position: absolute;
            bottom: 15%;
            text-align: center;
            width: 100%;
            .photo-title {
                font-size: 90px;
                line-height: 1;
                text-align: center;
                margin-bottom: 44px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
            }
            .underline-text {
                font-size: 20px;
                font-weight: 500;
                line-height: 1.5;
                text-decoration: underline;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                text-align: center;
            }

        }
        .photo-content-second {
            width: 100%;
            text-align: center;
            position: absolute;
            top: 60%;
            left: 50%;
            transform: translateX(-50%);
            p {
                font-size: 20px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;
            }
            .photo-title-smaller {
                font-size: 40px;
                line-height: 1.29;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
            }
        }
        .text-content {
            position: absolute;
            bottom: 15%;
            left: 50%;
            transform: translateX(-50%);
            a {
                text-decoration: none;
                color: white;
                font-size: 40px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;
                display: flex;
                flex-wrap: wrap;
                text-align: center;
                justify-content: center;
                width: 100%;
                .price {
                    padding-top: 20px;
                    font-size: 20px;
                    font-weight: 400;
                    width: 100%;
                }
            }
            
        }
        .text-content.about {
            bottom: 25%;
            a {
                display: flex;
                flex-wrap: wrap;
                font-size: 20px;
                font-weight: 500;
                .bigger {
                    padding-top: 30px;
                    width: 100%;
                    font-size: 40px;
                    font-weight: normal;
                }
            }
        }
    }
    h2 {
        font-size: 80px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        line-height: 0.5;
        font-weight: normal;
    }
}
.black-banner {
    color: #fff;
    .container {
        background-color: #121212;
        display: flex;
        justify-content: center;
        .row-padding {
            padding: 45px 180px;
            box-sizing: border-box;
            display: flex;
            width: 100%;
            justify-content: space-between;
            .col-md-4 {
                padding: 90px 30px;
                position: relative;
                .box-img {
                    width: 100%;
                    height:100%;
                    overflow: hidden;
                    position: relative;
                    img {
                        max-width: 100%;
                        transition: 0.6s;
                        object-fit:cover;
                    } 
                }
                a:hover .box-img {
                    img {
                        transform: scale(1.07);
                    }
                }
                .subtitle {
                    position: absolute;
                    top: 50%;
                    color: white;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 35px;
                    line-height: 1.1;
                    text-align: center;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: normal;
                }
            }
            .box {
                max-width: 33%;
                position: relative;
                img {
                    object-fit: contain;
                    height: 100%;
                } 
                .subtitle {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 70px;
                    line-height: 1.1;
                    text-align: center;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: normal;
                }
            }
        }
    }
}
.woocommerce-tabs{
    padding: 45px 180px 0 180px;
}
.new-collection {
    padding: 0 0 45px;
    .row-padding {
        padding: 80px 180px 0 180px;
        width: 100%;
        justify-content: space-between;
        .col-md-8 {
            padding-left: 0;
            padding-right: 40px;
        }
        .col-md-4 {
            padding-right: 0;
            padding-left: 0;
        }
        .pl-0 {
            padding-left: 0;
            padding-right: 30px;
        }
        .pr-0 {
            padding-right: 0;
            padding-left: 30px;
        }
        .prl-0 {
            padding-left: 15px;
            padding-right: 15px;
        }
        .box-v2 {
            height: 100%;
            padding-bottom: 136px;
            position: relative;
            .box-text {
                position: absolute;
                bottom: 0%;
                left: 50%;
                width: 100%; 
                text-align: center;
                transform: translate(-50%, -50%);

                a {
                    font-size: 20px;
                    width: 100%;
                    display: block;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1;
                    letter-spacing: normal;
                    transition: 0.3s;
                    color: #121212;
                    text-decoration: none;
                    margin-bottom: 1rem;
                    .product-price {
                        padding-top: 15px;
                        display: block;
                        font-weight: 400;
                    }
                }
            }
            .box-img, .box-img2 {
                img {
                    height: 100%;
                    object-fit: cover;
                    width: 100%;
                }
            }
            .box-img {
                height: 100%;
                overflow: hidden;
                a {
                    img {
                        transition: 0.6s;
                    }
                }
                a:hover {
                   img {
                       transform: scale(1.07);
                   }
                }
            }
            .box-img2 {
                height: 75%;
                width: 100%;
                overflow: hidden;
                a {
                    img {
                        transition: 0.6s;
                    }
                }
                a:hover {
                   img {
                       transform: scale(1.07);
                   }
                }
            }
            .text-content {
                height: 25%;
                color: var(--1);
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                display: flex;
                flex-wrap: wrap;
                align-content: start;

                .new-collection-header {
                    font-size: 20px;
                    margin-bottom: 0;
                    line-height: 1.53;
                    letter-spacing: -0.53px;
                }
                p {
                    font-size: 20px;
                    line-height: 1.15;
                    letter-spacing: -0.5px;
                }
                button {
                    background-color: white;
                    padding-bottom: 20px;
                    border: none;
                    position: relative;
                    width: 100%;
                    text-align: left;
                    a {
                        position: relative;
                        left: 0;
                        text-decoration: none;
                        padding:  10px 0;
                        font-size: 12px;
                        transition: 0.3s;
                        display: block;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1;
                        letter-spacing: normal;
                        color: #121212;
                        img {
                            position: absolute;
                            transform: translateY(-50%);
                            width: 31px;
                            height: 9px;
                            top: 50%;
                            left: 10%;
                            transition: 0.3s;
                        }
                    }
                    a:hover {
                        text-decoration: underline;
                        img {
                            left: 11%
                        }
                    }
                }
            }

        }
    }
    .container {
        .row-border {
            padding-bottom: 50px;
            h3 {
                font-size: 20px;
                text-align: left;
                width: 100%;
                margin: 10px 0 30px;
                padding: 0;
                line-height: 2;
                font-weight: normal;
            }
        }
    }
}
.personal-stylist {
    color: #fff;
    height: 55vw;
    max-height: 950px;
    .black-banner {
        height: 100%;
        .container {
            padding: 40px 0;
            height: 100%;
            .row-padding {
                .slideshow-container {
                    overflow: hidden;
                    position: relative;
                    .slide.current {
                        display: flex;
                    }
                    .slide.prev {
                        display: flex;
                        left: -110%;
                    }
                    .slide.next {
                        display: flex;
                        left: 100%;
                    }
                    .slide.hide {
                        display: none;
                    }
                    .slide {
                        transition: 1s;
                        position: absolute;
                        height: 100%;
                        left: 0;
                        top: 0;
                        width: 100%;
                        background-color: #121212;
                        padding: 0;
                        flex-wrap: wrap;
                        justify-content: center;
                        .text-row{
                            height: 16%;
                            width: 100%;
                            h2 {
                                width: 100%;
                                text-align: center;
                                font-size: 40px;
                                line-height: 1;
                                font-weight: normal;
                                font-stretch: normal;
                                font-style: normal;
                                letter-spacing: normal;
                            }
                            p {
                                font-size: 16px;
                                width: 100%;
                                font-weight: 500;
                                line-height: 1;
                                padding-top: 12px;
                                text-align: center;
                                font-stretch: normal;
                                font-style: normal;
                                letter-spacing: normal;
                            }
                        }
                        
                        .col-lg-4 {
                            padding:0 25px;
                        }
                        .col-md-3 {
                            display: flex;
                            flex-wrap: wrap;
                            object-fit: contain;
                            height: 84%;
                            align-content: space-between;
                            .box-img {
                                width: 100%;
                                max-height: 48%;
                                height: 100%;
                                position: relative;
                                overflow: hidden;
                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    transition: 0.6s;
                                    z-index: 2;
                                }
                                .text-content {
                                    z-index: 3;
                                    a {
                                        font-size: 16px;
                                        transition: 0.6s;
                                    }
                                }
                            }
                            .box-img:hover {
                                .text-content {
                                    a {
                                        transform: scale(1.07);
                                    }
                                }
                                img {
                                    transform: scale(1.07);
                                }
                            }
                        }
                        .col-md-5 {
                            height: 84%;
                            .box-img {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                                position: relative;
                                overflow: hidden;
                                img {
                                    width: 100%;
                                    object-fit: cover;
                                    height: 100%;
                                    transition: 0.6s;
                                }
                                .text-content {
                                    top: 50%;
                                    z-index: 3;
                                    a {
                                        transition: 0.6s;
                                        font-size: 40px;
                                        font-weight: normal;
                                        font-stretch: normal;
                                        font-style: normal;
                                        line-height: 1;
                                        letter-spacing: normal;
                                        .product-price {
                                            font-size: 20px;
                                            font-weight: 400;
                                        }
                                    }
                                }
                            }
                            .box-img:hover {
                                img {
                                    transform: scale(1.07);
                                }
                            }
                        }
                        .text-content {
                            position: absolute;
                            top: 55%;
                            left: 50%;
                            width: 100%;
                            transform: translate(-50%, -50%);
                            font-size: 20px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1;
                            letter-spacing: normal;
                            text-align:center;
                            margin-bottom: 1rem;
                            a {
                                font-size: 20px;
                                width: 100%;
                                display: block;
                                text-align: center;
                                color: white;
                                text-decoration: none;
                               
                            }
                            .product-price {
                                padding-top: 15px;
                                display: block;
                                font-weight: 400;
                            }
                        }
                    }
                }
                .stylist-arrow {
                    z-index: 3;
                    display: flex;
                    align-content: center;
                    color: white;
                    background-color: transparent;
                    border: none;
                    position: absolute;
                    padding: 0;
                    width: 80px;
                    top: 55%;
                    transition: 0.6s;
                    img {
                        width: 45%;
                        align-self: center;
                    }
                    a {
                        text-decoration: none;
                        color: white;
                    }
                }
                .stylist-left-arrow {
                    left: 10%;
                    a {
                        padding-left: 5px;
                    }
                }
                .stylist-left-arrow:hover {
                    left: 9%;
                }
    
                .stylist-right-arrow {
                    right: 10%;
                    a {
                        padding-right: 5px;
                    }
                }
                .stylist-right-arrow:hover {
                    right: 9%;
                }
            }
        }
    }
}
footer {
    border-top: 1px solid;
    .container {
        background-color: white;
        height: 100%;
        padding: 40px 30px 5px 30px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .row-padding {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .footer-img {
                width: 170px;
                padding-left: 30px;
                height: 20px;
                object-fit: contain;
            }
            .col-flex {
                display: flex;
                flex-wrap: wrap;
                align-content: flex-start;
                letter-spacing: normal;
                color: var(--1);
                a {
                    color: #9D9C9D;
                    text-decoration: none;
                    width: 100%;
                    font-weight: 200;
                }
            }
            .social {
                align-content: space-between;
                .contact, .visit-us {
                    width: 100%;
                    p {
                        margin-bottom: 28px;
                    }
                }
                .social-media {
                    width: 100%;
                    display: flex;
                    flex-wrap: nowrap;
                    a {
                        transition: 0.3s;
                        width: auto;
                        padding: 0 20px 0 0;
                        img {
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                    a:hover {
                        transform: scale(1.07);
                    }
                }
            }
            .black-font {
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;

                width: 100%;
                height: 16px;
                margin-bottom: 20px;
            }
            .gray-font {
                margin-top: 10px;
                line-height: 1.44;
                letter-spacing: -0.4px;
                font-style: normal;
                font-size: 14px;
                font-weight: 200;
                font-stretch: normal;
                font-style: normal;
                transition: 0.3s;
                color: #9D9C9D;
            }
            .gray-font:hover {
                cursor: pointer;
                text-decoration: underline;
            }
            .default:hover {
                cursor: default;
                text-decoration: none;
            }
            .sign-up {
                font-size: 20px;
                font-weight: 500;
                margin-bottom: 20px;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: normal;
                color: var(--1);
            }
            form {
                margin-top: 20px;
                position: relative;
                .submit-form {
                    position: absolute;
                    display: flex;
                    background-color: white;
                    border: none;
                    align-items: center;
                    width: 60px;
                    height: 90%;
                    bottom: 5%;
                    transition: 0.6s;
                    right: 5%;
                }
                .submit-form:hover {
                    right: 4%;
                }
                .sign-input {
                    box-sizing: border-box;
                    width: 100%;
                    padding: 20px;
                    border: 1px solid black;
                }
            }
            .showroom {
                display: flex;
                flex-wrap: wrap;
                align-content: space-between;
                .showroom-text {
                    display: flex;
                    flex-wrap: wrap;
                    a {
                        width: 100%;
                        color: #9D9C9D;
                        cursor:pointer;
                        font-size: 14px;
                    }
                    a:hover {
                        text-decoration: none;
                        cursor: pointer;
                    }
                }
                .read-more {
                    position: relative;
                    font-size: 12px;
                    margin: 14px 0 0;
                    a {
                        display: block;
                        width: auto;
                        height: 100%;
                        color: #121212;
                        text-decoration: none;
                    }
                    img {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 105%;
                    }
                }
                .read-more:hover {
                    cursor: pointer;
                    a {
                        text-decoration: underline;
                    }
                    img {
                        left: 110%;
                    }

                }
                .black-font {
                    font-weight: 500;
                    line-height: 1;
                    letter-spacing: normal;
                    height: 16px;
                    margin-bottom: 20px;
                }
                .gray-font {
                    margin-top: 10px;
                    text-decoration: none;
                    line-height: 1.44;
                    letter-spacing: -0.4px;
                    font-weight: 200;
                }
                .gray-font:hover {
                    text-decoration: underline;
                }
            }
        }
        .copy-write {
            width: 100%;
            align-self: flex-end;
            padding-top: 50px;
        }
    }
}
.shop-product {
    .row {
        display: flex;
        width: 100%;
        padding: 100px 0 80px 0;
        flex-wrap: wrap;
        justify-content: center;
        .col-xxl-2 {
            padding-left: 20px;
            padding-right: 20px;
            a {
                display: flex;
                width: 100%;
                overflow: hidden;
                justify-content: center;
                height: 80%;
                img {
                    height: auto;
                    width: 100%;
                    transition: 0.6s;
                }
            }
            a:hover {
                img {
                    transform: scale(1.07);
                }
            }
            .text-content {
                height: 20%;
                padding-top: 5px;
                overflow: hidden;
                a {
                    display: flex;
                    flex-wrap: wrap;
                    transition: 0.6s;
                    align-content: flex-end;
                    text-decoration: none;
                    width: 100%;
                    color: #121212;
                    font-family: Avenir Next;
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.25;
                    letter-spacing: normal;
                    text-align: center;

                    .price {
                        width: 100%;
                        text-align: center;
                        font-weight: 400;
                    }
                }
                a:hover {
                    transform: scale(1.07);
                }
            }
        }
    }
}
.shop-new-collection {
    padding: 100px 0;
    .container {
        .row {
            width: 100%;
            .col-12 {
                margin-top: 100px;
                display: flex;
                .box {
                    max-width: 25%;
                    width: 100%;
                    height: auto;
                    a {
                        display: flex;
                        width: 100%;
                        overflow: hidden;
                        justify-content: center;
                        height: 90%;
                        img {
                            width: 100%;
                        }
                    }
                    a:hover {
                        img {
                            transform: scale(1.07);
                        }
                    }
                    .text-content {
                        height: 10%;
                        a {
                            align-items: flex-end;
                            font-size: 16px;
                            font-style: normal;
                            line-height: 1.25;
                            text-align: center;
                            padding-top: 5px;
                            justify-content: center;
                            display: flex;
                            flex-wrap: wrap;
                            width: 100%;
                            text-decoration: none;
                            color: #121212;
                            .price {
                                width: 100%;
                                font-weight: 400;
                            }
                        }
                        a:hover {
                            transform: scale(1.07);
                        }
                    }
                }
            }
            .col-sm-6 {
                .box {
                    max-width: 35%;
                    width: 100%;
                    a {
                        height: auto;
                    }
                    .text-content {
                        height: auto;
                    }
                }
            }
            .center {
                justify-content: center;
            }
            .left {
                justify-content: flex-start;
                padding-left: 20px;
            }
            .right {
                justify-content: flex-end;
                padding-right: 20px;
            }
    
        }
    }
    .head-text {
        font-size: 20px;
        line-height: 2;
        color: #121212;
        width: 100%;
        padding: 0;
        margin: 0;
        text-align: center;
    }
}
.account-popap {
    position: absolute;
    right: 0;
    top: -350px;
    background-color: #FFF;
    width: 350px;
    height: auto;
    border: none;
    display: flex;
    z-index: 5;
    flex-wrap: wrap;
    justify-content: center;
    transition: 1s;
    .login-popap {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        padding: 20px;
        p {
            width: 100%;
            text-align: center;
            font-size: 20px;
            line-height: 2;
            font-weight: 500;
            letter-spacing: normal;
        }
        button {
            background-color: #121212;
            width: 100%;
            a {
                display: inline-block;
                font-size: 16px;
                padding: 10px 0;
                color: white;
                font-weight: 500;
                width: 100%;
            }
        }
        button:hover {
            transform: scale(1.07);
        }
    }
    .register-popap {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        width: 100%;
        border: 1px solid black;
        padding: 20px;
        p {
            width: 100%;
            text-align: center;
            font-size: 20px;
            font-weight: 500;
            line-height: 2;
            letter-spacing: normal;
        }
        button {
            background-color: #F8F9FA;
            width: 100%;
            a {
                display: inline-block;
                font-size: 16px;
                font-weight: 500;
                padding: 10px 0;
                border: 1px solid black;
                width: 100%;
            }
        }
        button:hover {
            transform: scale(1.07);
        }
    }
    a {
        color: #121212;
        text-decoration: none;
    }
}
.account-popap.active {
    top: 100%;
}
.account-popap.loged {
    display: none;
}
.loged-account-popap {
    display: block;
    width: 350px;
    background-color: #F8F9FA;
    position: absolute;
    top: -350px;
    transition: 1s;
    right: 0;
    z-index: 5;
    .loged-box {
        width: 100%;
        height: 100%;
        padding: 30px 50px;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        p {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            span {
                font-weight: 500;
                width: 100%;
            }
        }
        a {
            width: 100%;
            padding: 5px;
            text-decoration: none;
            color: #121212;
            img {
                padding-right: 20px;
            }
        }
        a:hover {
            transform: scale(1.07);
            cursor: pointer;
        }
    }
}
.loged-account-popap.active {
    top: 64px;
}
.loged-account-popap.logout {
    display: none;
}
.login-container {
    width: 100%;
    height: calc(100vh - 84px);
    display: flex;
    .login, .register {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        form {
            position: relative;
            width: 60%;
            height: 50%;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            h4 {
                line-height: 2;
                font-size: 20px;
                text-align: center;
                width: 100%;
                margin-bottom: 50px;
            }
            input {
                width: 100%;
                padding: 30px;
                margin-bottom: 10px;
                font-size: 16px;
            }
            label {
                width: 100%;
                position: relative;
                img {
                    position: absolute;
                    top: calc(50% - 5px);
                    left: 90%;
                    transform: translate(-50%, -50%);
                }
                .hide {
                    display: none;
                }
                img:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }
            }
            button {
                width: 100%;
                background-color: #121212;
                color: white;
                line-height: 1;
                text-align: center;
                margin: 30px 0;
                height: 64px;
                a {
                    text-decoration: none;
                    display: inline;
                    color: white;
                }
            }
            button:hover {
                font-size: 20px;
            }
            a {
                color: #121212;
                display: inline-block;
                width: 100%;
                text-align: center;
                margin-top: 10px;
                transition: 0.3s;
            }
            a:hover {
                text-decoration: none;
            }
            .content-adverb {
                position: absolute;
                left: 50%;
                transform: translate(-50%, 0);
                width: 320px;
                p{
                    margin-bottom:25px;
                }
            }
        }
    }
    .register {
        border: 1px solid black;
        border-bottom: none;
        border-right: none;
        form {
            overflow: hidden;
            a {
                margin-top: 0;
                display: block;
                width: 100%;
                background-color: #121212;
                color: white;
                padding: 20px;
                margin-bottom: 50px;
                text-decoration: none;
            }
            a:hover {
                transform: scale(1.07);
            }
        }
    } 
}
.register-container {
    width: 100%;
    height: 100vh;
    display: flex;
    div.login, div.register {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        #login-form,
        #register-form {
            position: relative;
            width: 60%;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            h4 {
                line-height: 2;
                font-size: 20px;
                text-align: center;
                width: 100%;
                margin-bottom: 50px;
            }
            input {
                width: 100%;
                padding: 30px;
                margin-bottom: 10px;
                font-size: 16px;
                border:1px solid rgb(118, 118, 118);
            }
            label {
                width: 100%;
                position: relative;
                img {
                    position: absolute;
                    left: 90%;
                    transform: translate(-50%, -50%);
                    top: calc(50% - 5px);
                }
                .hide {
                    display: none;
                }
                img:hover {
                    opacity: 0.7;
                    cursor: pointer;
                }
            }
            button {
                width: 100%;
                background-color: #121212;
                color: white;
                line-height: 1;
                text-align: center;
                margin: 30px 0;
                height: 64px;
                a {
                    text-decoration: none;
                    display: inline;
                    color: white;
                }
            }
            button:hover {
                font-size: 20px;
            }
            .login-button {
                background-color: white;
                border: 1px solid black;
                a {
                    color: #121212;   
                    font-weight: 500;
                }
            }
            .register-button {
                margin-top: 0;
            }
            .date-id {
                display: flex;
                #name {
                    margin-right: 5px;
                }
                
                #surname {
                    margin-left: 5px;
                }
            }
            .permission {
                font-size: 14px;
                line-height: 1.43;
                display: flex;
                margin: 30px 10px 10px 0;
                .ur-checkbox-list{
                    display: flex; 
                }
                input {
                    width: 40px;
                    height: 25px;
                    display: flex;
                    margin-right: 10px;
                    align-content: flex-start;
                    align-items: flex-start;
                    align-self: flex-start;
                }
                p {
                    text-align: left;
                }
                span {
                    color: lightgray;
                    opacity: 1;
                }
            }
            p {
                text-align: center;
                line-height: 1.3;
                a {
                    color: #121212;
                }
                a:hover {
                    font-size: 18px;
                }
            }
        }
    }
    div.register {
        border: 1px solid black;
        border-bottom: none;
        border-right: none;
    } 
}
.message {
    width: 100%;
    .container {
        display: flex;
        justify-content: center;
        height: 100%;
    }
    form {
        margin: auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 450px;
        h4 {
            width: 100%;
            margin: 0 0 42.5px 0;
            text-align: center;
            font-size: 20px;
            font-weight: 500;
            line-height: 2;
        }
        label {
            display: flex;
            width: 100%;
            input {
                max-width: 100%;
            }
        }
        br{
            display:none;
        }
        input {
            padding: 30px;
            margin: 7.5px;
            width: 100%;
        }
        input::placeholder {
            color: #121212;
            font-weight: 500;
        }
        #message-subject::placeholder {
            color: lightgray;
            opacity: 0.7;
        }
        textarea {
            margin: 7.5px;
            padding: 30px;
            width: 100%;
        }
        textarea::placeholder {
            opacity: 0.7;
            color: lightgray;
            font-weight: 500;
        }
        textarea:focus::placeholder {
            opacity: 1;
            color: #121212;
        }

        .permission {
            display: flex;
            margin: 17.5px 7.5px;
            input {
                margin: 0;
                margin-right: 10px;
                align-self: flex-start;
                width: 40px;
                height: 40px;
            }
            input:hover {
                cursor: pointer;
            }
            p {
                font-size: 14px;
                margin-bottom: 0;
                a { 
                    font-weight: 500;
                    color: #121212;
                }
                a:hover {
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }
        input[type="submit"],
        button {
            margin: 7.5px;
            background-color: #121212;
            width: 100%;
            padding: 24px;
            color: white;
            border: none;
        }
        input[type="submit"]:hover,
        button:hover {
            transform: scale(1.07);
        }
    }

}
.shop-all {
    .container{
        .panel-path{
            padding: 25px 350px 0;
        }
        .row {
            padding: 0 350px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            .box {
                padding: 0 15px;
                margin-bottom: 60px;
                width: 100%;
                .img-content {
                    text-align: center;
                    width: auto;
                    overflow: hidden;
                    img {
                        object-fit:cover;
                        width:100%;
                    }
                    img:hover{
                        cursor: pointer;
                    }
                }
                .text-content {
                    text-align: center;
                    padding-top: 3%;
                    width: 100%;
                }
            }
        }
    }
    a {
        font-size: 16px;
        width: 100%;
        display: block;
        line-height: 1.25;
        transition: 0.6s;
        color: #121212;
        text-decoration: none;
        .product-price {
            padding-top: 5px;
            display: block;
            font-weight: 400;
            .old-price {
                font-size: 14px;
                font-weight: 500;
                padding-left: 5px;
                line-height: 1;
                text-align: center;
                color: #ccc;
                text-decoration: line-through;
            }
        }
        .new-price {
            font-size: 16px;
            color: #bf3939;
            padding-left: 50px;
        }
        
    }
    .shop-all-header {
        padding: 25px 0 50px;
        margin: 0;
        font-size: 28px;
        font-weight: 400;
        width: 100%;
        text-align: center;
    }
    .blocks-nav {
        width: 100%;
        justify-content: center;
        display: flex;
        margin-bottom: 60px;
        button {
            height: 40px;
            width: 40px;
            background-color: white;
            margin: 20px;
            border: none;
            color: #121212;
            img {
                object-fit: contain;
            }
        }
        button.active,
        button:hover {
            background-color: #121212;
            color: white;
        }
        .prev-block {
            transform: rotateY(180deg);
        }
        .prev-block:hover {
            transform: scale(1.07) rotateY(180deg);
            background-color: white;
        }
        .next-block:hover {
            background-color: white;
        }

    }
}
.about {
    .container {
        .about-header {
            font-size: 20px;
            line-height: 1.63;
            font-weight:300;
            text-align: center;
            width: 100%;
            padding: 100px 40px;
        }
        .about-header.bottom{
            padding-bottom: 50px;
        }
        .ceo {
            margin: 0 10%;
            .col-12 {
                display: flex;
                justify-content: center;
                .box {
                    max-width: 423px;
                    width: 50%;
                    .img-content{
                        width: 100%;
                        img {
                            width: 100%;
                        }
                    }
                    .text-content {
                        padding: 10px;
                        display: flex;
                        flex-wrap: wrap;
                        font-size: 16px;
                        justify-content: center;
                        span {
                            text-align: center;
                            padding-bottom: 5px;
                            font-weight: 500;
                            width: 100%;
                        }
                    }
                }
            }
        }
        .stylish-everyday {
            margin: 80px 10%;
            justify-content: space-between;
            .img-content{
                display: flex;
                align-items: center;
                height: 100%;
                img {
                    width: 100%;
                }
            }
            .text-content {
                width: 100%;
                height: 100%;
                display: flex;
                padding: 10px 0 10px 30px;
                justify-content: left;
                align-content: center;
                flex-wrap: wrap;
                h4 {
                    text-align: left;
                    margin-bottom: 30px;
                    font-size: 20px;
                    font-weight: normal;
                }
                p {
                    text-align: left;
                    font-size: 14px;
                    line-height: 1.43;
                }
            }
            a {
                font-size: 18px;
                font-weight: 500;
                line-height: 1.5;
                color: #121212;
                width: 100%;
                text-align: center;
            }
            a:hover {
                text-decoration: none;
            }
        }
    }
}
.panel-path {
    display: flex;
    width: 100%;
    text-transform: uppercase;
    justify-content: start;
    a {
        font-size: 12px;
        font-weight: 500;
        width: auto;
        color: #ccc;
        text-decoration: none;
    }
    a:hover {
        cursor: pointer;
        transform: scale(1.07);
    }
    img {
        width: 40px;
        padding: 0 15px;
    }
}
.my-data {
    .container {
        .row {
            width: 100%;
            .account-nav {
                z-index: 1;
                justify-content: center;
                padding: 0;
                .data {
                    max-width: 355px;
                    background-color: white;
                    height: auto;
                    padding: 100px 0;
                    position: sticky;
                    top:50px;
                    display: flex;
                    align-items: center;
                    border: 1px solid black;
                    border-left: none;
                    p {
                        display: flex;
                        flex-wrap: wrap;
                        width: 50%;
                        margin: 0 0 0 40%;
                        height: auto;
                        font-size: 14px;
                        font-weight: 500;
                        a {
                            width: 100%;
                            display: block;
                            color: #121212;
                            margin: 5px 0 5px 40px;
                            text-decoration: none;
                        }
                        a:hover {
                            transform: scale(1.07);
                        }
                    }
                    .account-nav-btn {
                        display: none;
                    }
                }
            }
            .panel {
                padding: 50px 0 50px 50px;
                position: relative;
                min-height: 70vh;
                .panel-path {
                    display: flex;
                    width: 100%;
                    a {
                        font-size: 12px;
                        font-weight: 500;
                        color: #ccc;
                        text-decoration: none;
                    }
                    a:hover {
                        cursor: pointer;
                        transform: scale(1.07);
                    }
                    img {
                        width: 40px;
                        padding: 0 15px;
                    }
                }
                .panel-header {
                    margin: 30px 0 100px 0;
                    font-size: 28px;
                    font-weight: 400;
                }
                .panel-header.orders {
                    margin: 30px 0 50px 0;
                }
                .panel-header.order-details {
                    margin-bottom: 25px;
                }
                .order-stats {
                    p {
                        font-size: 14px;
                        line-height: 1.14;
                        margin-bottom: 5px;
                        span {
                            font-weight: 600;
                        }
                    }
                }
                .return-addres {
                    h5 {
                        font-size: 20px;
                        line-height: 1.2;
                        font-weight: normal;
                        margin: 80px 0 30px;
                    }
                    p {
                        font-size: 14px;
                        margin: 0;
                    }
                    .bold {
                        font-weight: 500;
                    }
                    .uppercase {
                        text-transform: uppercase;
                        line-height: 1.7;
                        margin-bottom: 15px;
                    }
                }
                form {
                    width: 100%;
                    label {
                        width: 70%;
                    }
                    .flex-label {
                        width: 70%;
                        margin-bottom: 15px;
                        display: flex;
                        justify-content: space-between;
                        .password-input{
                            display:block;
                            flex-grow: 1;
                            max-width: calc(50% - 15px);
                            margin-right: 15px;
                            input{
                                max-width:100%;
                                width: 100%;
                                margin-right: 0;
                            }
                        }
                        input {
                            flex-grow: 1;
                            max-width: calc(50% - 15px);
                            padding: 11px;
                            margin-right: 15px;
                        }
                    }
                    .checkbox-container {
                        width: auto;
                        display: block;
                        position: relative;
                        margin: 0;
                        padding-left: 35px;
                        cursor: pointer;
                        margin-right: 50px;
                    }
                    .checkbox-container input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                    }
                    .checkmark {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 25px;
                        width: 25px;
                        background-color: white;
                        border: 1px solid black;
                    }
                    .checkbox-container input:checked ~ .checkmark {
                        background-color: #121212;
                    }
                    .checkmark:after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        display: none;
                    }
                    .checkbox-container input:checked ~ .checkmark:after {
                        display: block;
                    }
                    .checkbox-container .checkmark:after {
                        top: 1px;
                        left: 1px;
                        width: 21px;
                        height: 21px;
                        background: black;
                        border: 2px solid white;
                    }
                    .adres {
                        width: 70%;
                        height: auto;
                        margin-bottom: 15px;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        .resp {
                            margin-right: 30px;
                        }
                        .cont {
                            border: none;
                            flex-wrap: nowrap;
                            align-items: flex-start;
                            label {
                                margin-right: 10px;
                                .checkmark {
                                    top: 55%
                                }
                            }
                            .adres-data {
                                margin-left: 40px;
                                .adres-name {
                                    margin-left: 0;
                                }
                            }
                        }
                        .adres-name {
                            font-size: 16px;
                            margin: 0;
                            font-weight: 500;
                            text-decoration: none;
                            color: #121212;
                            display: inline;
                            padding-left: 0;
                            margin-left: 40px;
                        }
                    }

                    .invoice {
                        .cont {
                            margin: 15px 0;
                            border: none;
                        }
                    }
                    .add-adres {
                        margin-top: 15px;
                        label {
                            margin-bottom: 15px;
                            input {
                                margin-right: 15px;
                                max-width: calc(50% - 15px);
                                flex-grow: 1;
                            }
                        }
                        input {
                            padding: 11px;
                        }
                    }
                    .invoice-dropdown.invisible {
                        height: 0;
                        overflow: hidden;
                        margin: 0 !important;
                    }
                    .invoice-dropdown.visible {
                        width: 100%;
                        overflow: hidden;
                        height: auto;
                        margin-top: 15px;
                        label {
                            margin-bottom: 15px;
                        }
                        input {
                            padding: 11px;
                            flex-grow: 1;
                            margin-right: 15px;
                        }
                    }
                    .invoice.invisible {
                        height: 0;
                        overflow: hidden;
                        margin: 0;
                    }


                    .gender, .customer{
                        display: flex;
                        flex-wrap: wrap;
                        width: 70%;
                        margin-left: 2px;
                        margin-bottom: 30px;
                        justify-content: flex-start;
                        
                        .checkbox-container {
                            width: 60px;
                            display: block;
                            position: relative;
                            margin: 0;
                            padding-left: 35px;
                            cursor: pointer;
                            margin-right: 50px;

                        }
                        .checkbox-container input {
                                position: absolute;
                                opacity: 0;
                                cursor: pointer;
                        }
                        .checkmark {
                                position: absolute;
                                top: 0;
                                left: 0;
                                height: 25px;
                                width: 25px;
                                background-color: white;
                                border: 1px solid black;
                        }
                        .checkbox-container input:checked ~ .checkmark {
                                background-color: #121212;
                        }
                        .checkmark:after {
                                content: "";
                                position: absolute;
                                top: 0;
                                left: 0;
                                display: none;
                        }
                        .checkbox-container input:checked ~ .checkmark:after {
                                display: block;
                        }
                        .checkbox-container .checkmark:after {
                                top: 1px;
                                left: 1px;
                                width: 21px;
                                height: 21px;
                                background: black;
                                border: 2px solid white;
                        }
                    }
                    .customer {
                        margin-left: 2px;
                        .checkbox-container {
                            width: auto;
                        }
                    }
                    .newsletter {
                        width: 100%;
                        padding: 50px 100px 40px 0;
                        margin-bottom: 50px;
                        p {
                            margin-bottom: 15px;
                            margin-top: 0;
                        }
                        label {
                            width: 100%;
                            font-size: 14px;
                            line-height: 1.43;;
                            input {
                                width: 14px;
                                height: 14px;
                                margin-right: 10px;
                                border: 3px solid black;
                            }

                            input:checked {
                                accent-color: #121212;
                            }
                        }
                        div {
                            width: 100%;
                            height: auto;
                            display: flex;
                            margin-top: 50px;
                            justify-content: flex-end;
                            button {
                                background-color: #121212;
                                padding: 15px 150px;
                                color: white;
                                position: absolute;
    
                            }
                            button:hover {
                                transform: scale(1.07) translateX(-10%);
                            }
                        }
                    }
                    .mt {
                        margin-top: 50px;
                    }
                    .mb {
                        margin-bottom: 49px;
                    }
                    p {
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 2;
                        margin: 30px 0 40px 0;
                    }
                    .remove {
                        margin-bottom: 15px;
                    }
                    a{
                        color: #121212;
                    }
                    a:hover {
                        text-decoration: none;
                    }
                }
                .btn-container {
                    width: 100%;
                    height: 50px;
                    position: relative;
                    margin: 50px 0;
                    button {
                        background-color: #121212;
                        padding: 15px 150px;
                        color: white;
                        position: absolute;
                        left: 50%;
                    }
                    button:hover {
                        transform: scale(1.07) translateX(-10%);
                    }
                }
                .returns-content {
                    text-align: center;
                    line-height:  1;
                    padding-right: 100px;
                    margin-bottom: 100px;
                    font-size: 20px;
                    img {
                        width: 55px;
                        height: 55px;
                    }
                    .price {
                        font-weight: 600;
                        line-height: 0.8;
                        margin-top: 50px;
                        padding-bottom: 0;
                    }
                    p {
                        padding-bottom: 10px;
                    }
                    a {
                        font-size: 16px;
                        font-weight: 500;
                        color: #121212;
                    }
                    a:hover {
                        text-decoration: none;
                    }
                }
                .order-row {
                    padding:  40px 0 40px 0;
                    margin-right: 100px;
                    border-bottom: 1px solid lightgray;
                    height: 300px;
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                    .order {
                        height: 100%;
                        // padding-bottom: 10px;
                        .order-number {
                            width: 100%;
                            height: 22%;
                            display: flex;
                            flex-wrap: wrap;
                            p {
                                width: 100%;
                                font-size: 16px;
                                margin: auto;
                            }
                            .price {
                                font-weight: 400;
                            }
                        }
                        .order-items {
                            height: 78%;
                            width: 100%;
                            position: relative;
                            padding-top: 10px;
                            display: flex;
                            justify-content: flex-start;
                            overflow: hidden;
                            flex-wrap: nowrap;
                            .box-img {
                                margin-right: 15px;
                                width: auto;
                                overflow: hidden;
                                height: 100% !important;
                                img {
                                    height: 100% !important;
                                    width: 100%;
                                    object-fit:cover;
                                }
                                a:hover {
                                    img {
                                        transform: scale(1.07);
                                    }
                                }
                            }
                            .box-img:last-of-type {
                                margin-right: 0;
                            }
                        }
                    }
                    .order-descriptions {
                        height: 100%;
                        padding-left: 10px;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-end;
                        align-content: space-between;
                        .delivery {
                            display: flex;
                            width: 100%;
                            justify-content: flex-end;
                            flex-wrap: wrap;
                            span {
                                width: 100%;
                                margin-bottom: 10px;
                                font-weight: 600;
                                display: flex;
                                align-items: center;
                                justify-content: flex-end;
                                img {
                                    height: 100%;
                                    margin-right: 10px;
                                }
                            }
                            p {
                                text-align: right;
                                width: 100%;
                                span {
                                    padding-left: 5px;
                                    display: inline;
                                    font-weight: normal;
                                }
                            }
                        }
                        a {
                            color: #121212;
                            text-align: right;
                            font-weight: 500;
                        }
                        a:hover {
                            text-decoration: none;
                            cursor: pointer;
                        }
                    }
                    //My-order-details
                    .order-content {
                        display: flex;
                        flex-wrap: nowrap;
                        a {
                            height: 100%;
                            overflow: hidden;
                            img {
                                height: 100%;
                            }
                        }
                        a:hover{
                            cursor: pointer;
                            img {
                                transform: scale(1.07);
                            }
                        }
                        .order-description {
                            padding-left: 20px;
                            padding-top: 10px;
                            a {
                                color: #121212;
                                text-decoration: none;
                            }
                            a:hover {
                                cursor: pointer;
                                text-decoration: underline;
                            }
                        }
                        p {
                            display: flex;
                            flex-wrap: wrap;
                            line-height: 1.25;
                            span {
                                width: 100%;
                            }
                        }
                        .order-name {
                            line-height: 1.5;
                            text-transform: uppercase;
                        }
                    }
                    .order-value {
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-end;
                        padding-top: 10px;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 1.25;
                    }
                    .uppercase {
                        text-transform: uppercase;
                    }
                }
                .summary {
                    justify-content: space-between;
                    height: auto;
                    h4 { 
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        font-size: 20px;
                        font-weight: normal;
                        span {
                            font-size: 16px;
                            font-weight: 400;
                            text-transform: uppercase;
                        }
                    }
                    .summary-data {
                        h4 {
                            margin-top: 8px;
                            margin-bottom: 40px;
                            font-size: 16px;
                            font-weight: normal;
                        }
                        span {
                            font-weight: 500;
                        }
                        p {
                            font-size: 14px;
                            &:last-child{
                                margin-bottom:0;
                            }
                        }
                    }
                    .summary-price {
                        h4, h5 {
                            display: flex;
                            justify-content: space-between;
                            font-weight: normal;
                            span {
                                font-weight: 400;
                            }
                        }
                        h5 {
                            margin: 8px 0;
                            font-size: 16px;
                            line-height: 1.25;
                        }
                        h4 {
                            font-size: 20px;
                            margin: 12px 0 10px;
                        }

                    }
                }
                .return-btn-container {
                    justify-content: flex-end;
                    height: auto;
                    margin-bottom: 20px;
                    button {
                        border: 1px solid black;
                        font-weight: 500;
                        background-color: white;
                        font-size: 16px;
                        a {
                            padding: 14px 100px;
                            display: block;
                            width: 100%;
                            height: 100%;
                            color: #121212;
                            text-decoration: none;
                        }
                        a:hover {
                            cursor: pointer;
                        }
                    }
                    button:hover {
                        transform: scale(1.07);
                    }
                }
                .order-row:last-of-type {
                    border: none;
                }
                .order-row.return {
                    height: auto;
                }
                .returns-instruction-btn {
                    position: absolute;
                    top: 105px;
                    right: 100px;
                    font-size: 16px;
                    font-weight: 500;
                    color: #121212;
                }
                .returns-instruction-btn:hover {
                    text-decoration: none;
                }
                .see-more {
                    border: none;
                    margin-left: 20%;
                    margin-top: 50px;
                    background-color: #121212;
                    color: white;
                    padding: 15px 150px;
                }
                .see-more:hover {
                    transform: scale(1.07);
                    cursor: pointer;
                }
            }
            .current {
                text-transform: uppercase;
            }
        }
        .instruction-content {
            position: fixed;
            top: 50%;
            left: 50%;
            padding: 35px 50px;
            transform: translate(-50%, -50%);
            z-index: 16;
            width: 570px;
            max-height: 750px;
            height: auto;
            background-color: white;
            display: none;
            flex-wrap: wrap;
            justify-content: center;
            align-content: center;
            button {
                position: absolute;
                top: 10px;
                right: 10px;
                background-color: transparent;
                border: none;
                img {
                    width: 15px;
                    height: 15px;
                }
            }
            button:hover {
                cursor: pointer;
                img {
                    transform: rotate(90deg);
                }
            }
            h4 {
                font-size: 20px;
                font-weight: 500;
                line-height: 1.7;
                margin: 0;
                text-align: center;
                padding-bottom:  15px;
            }
            h5 {
                font-size: 16px;
                font-weight: 500;
                padding: 35px 0 15px;
                margin: 0;
                text-align: center;
            }
            p {
                font-size: 14px;
                line-height: 1.79;
                margin: 0;
                padding: 5px 0 10px;
                text-align: center;
                span {
                    font-weight: 500;
                    text-align: center;
                }
            }
            .return-email {
                padding-bottom: 5px;
            }
            a {
                color: #121212;
                text-align: center;
                font-size: 16px;
                font-weight: 500;
                padding: 10px 0 20px;
                margin: 0;
                text-align: center;
                width: 100%;
                display: block;
            }
            a:hover {
                cursor: pointer;
                text-decoration: none;
            }
        }
        .instruction-content.active {
            display: block;
        }
    }
}
.shopping-cart {
    .shopping {
        padding: 70px 140px 70px 210px;
        min-height: 60vh;
        .item-row {
            padding: 15px 0;
            border-bottom: 1px solid lightgray;
            display: flex;
            justify-content: space-between;
        }
        .item-row:last-of-type {
            border-bottom: none;
        }
        .item-content {
            display: flex;
            a {
                height: 100%;
                margin-right: 30px;
                max-width: 140px;
            }
            a:hover {
               img {
                transform: scale(1.07);
               }
            }
            img {
                max-width: 140px;
                margin-right: 15px;
                width: 140px;
                height: 140px;
                object-fit:cover;
            }
            .item-description {
                display: flex;
                flex-wrap: wrap;
                align-content: space-between;
                p {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    span {
                        width: 100%;
                    }
                }
                .chose-amount {
                    position: relative;
                    width: 70px;
                    height: 36px;
                    select {
                        max-height: 100px;
                        position: absolute;
                        width: 70px;
                        padding: 5px 10px;
                        -webkit-appearance: none;
                        z-index: 4;
                        background-color: transparent;
                    }
                    select:hover {
                        cursor: pointer;
                    }
                    select:focus-visible {
                        outline: none;
                    }
                }
                .chose-amount::after {
                    content: "";
                    z-index: 3;
                    background-image: url(../img/language.png);
                    position: absolute;
                    top: 50%;
                    right: 10px;
                    -webkit-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    transform: translateY(-50%);
                    width: 20px;
                    height: 20px;
                }
            }
            .item-name {
                width: 100%;
                padding-top: 8px;
                font-weight: normal;
                a {
                    color: #121212;
                    text-decoration: none;
                }
                a:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }

            }
        }
        .item-value {
            display: flex;
            flex-wrap: wrap;
            align-content: space-between;
            justify-content: flex-end;
            a {
                padding-top: 8px;
                background-color: transparent;
                display: flex;
                border: none;
                align-items: center;
                text-align: right;
            }
            a:hover {
                transform: rotate(90deg);
                cursor: pointer;
            }
            p {
                width: 100%;
                margin-bottom: 0;
                line-height: 1;
                text-align: right;
                font-weight: 400;
                font-size: 20px;
            }
        }
        .information {
            margin-top: 50px;
            padding: 50px;
            border: 1px solid black;
            h5 {
                font-size: 20px;
                font-weight: normal;
                line-height: 1.2;
                margin-bottom: 30px;
            }
            p {
                font-size: 14px;
                span {
                    font-weight: 500;
                }
            }
        }
    }
    .shopping-card {
        border: 1px solid black;
        border-bottom: none;
        border-right: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        position: relative;
        align-content: flex-start;
        padding-top:30px;
        .payment {
            position: sticky;
            top: 30%;
            padding: 0px 100px 100px 40px;
            background-color: transparent;
            overflow: hidden;
            width: auto;
            height: auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            text-transform: uppercase;
            .total-price { 
                width: 100%;
                font-weight: normal;
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                span {
                    font-weight: 400;
                }
            }
            form {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                margin: 20px 0;
                flex-grow: 0;
                input {
                    width: 70%;
                    border: none;
                    background-color: transparent;
                    border-bottom: 1px solid lightgray;
                    padding: 10px;
                }
                input:focus-visible {
                    outline: 0;
                }
                button {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.14;
                    padding: 8px 20px;
                }
                button:hover {
                    background-color:#212529;
                    color:white;
                }
            }
            .payment-forms {
                width: 100%;
                p {
                    width: 100%;
                    font-size: 16px;
                }
                a {
                    
                    padding-right: 10px;
                    text-decoration: none;
                    img {
                        max-width: 70px;
                        max-height: 30px;
                    }
                }
                a:hover {
                    img {
                        transform: scale(1.07);
                    }
                }
            }
            button {
                transition: 0.6s;
            }
            .btn-light {
                background-color: transparent;
                border: 1px solid black;
                border-radius: 0;
            }
            .btn-light:focus {
                box-shadow: none;
            }
            .btn-dark {
                margin: 20px 0;
                width: 100%;
                border-radius: 0;
                padding: 15px;
                a {
                    color: white;
                    text-decoration: none;
                    width: 100%;
                    height: 100%;
                    display: block;
                }
            }
            .btn-dark:focus {
                box-shadow: none;
            }
            .btn-dark:hover {
                transform: scale(1.07);
            }
        }
    }
    .shopping-card.choices {
        height: 280px;
        position: fixed;
        border-bottom: 1px solid black;
        display: flex;
        align-items: flex-end;
        flex-wrap: nowrap;
        right: 0;
        .payment {
            position: static;
            padding-bottom: 50px;
            button {
                border: none;
                background-color: #cccccc;
                color: white;
                border-radius: 0;
                margin: 20px 0;
                padding: 0;
                width: 100%;
                a {
                    padding: 13px 0;
                    display: block;
                    width: 100%;
                    height: 100%;
                    text-decoration: none;
                    color: #121212;
                }
            }
            .finish-return-btn {
                color: white;
                background-color: #121212;
                padding: 16px 0;
            }
            .btn:focus {
                box-shadow: none;
            }
            .btn:hover {
                transform: scale(1.07);
            }
        }
    }
    .shopping-nav-btn {
        display: none;
        z-index: 13;
    }

    h4 {
        font-size: 16px;
    }
    h2 {
        font-size: 28px;
    }
    .shoping-header {
        display: flex;
        flex-wrap: nowrap;
        font-weight: 400;
        align-items: center;
        justify-content: flex-start;
        margin: 0;
        img:hover {
            cursor: pointer;
            transform: scale(1.07);
        }
    }
}
.choices {
    .item-content {
        flex-grow: 1;
        img {
            max-width: 150px;
        }
        .item-description {
            display: flex;
            justify-content: flex-end;
            flex-grow: 1;
            h4 {
                font-size: 16px;
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                .price {
                    font-weight: 400;
                    width: auto;
                }
            }

            .chose-reason {
                position: relative;
                width: 70%;
                height: 58px;
                select {
                    width: 100%;
                    height: 100%;
                    color: #ccc;
                    border: 1px solid #ccc; 
                    position: absolute;
                    -webkit-appearance: none;
                    z-index: 4;
                    background-color: transparent;
                    padding: 10px;
                    option {
                        color: #121212;
                        padding: 10px;
                    }
                    // option:checked {
                    //     width: 100%;
                    //     overflow: scroll;
                    //     background-color: #121212;
                    // }
                    // option:hover {
                    //     background: black;
                    //     color: white;
                    // }
                    // option:focus {
                    //     background: black;
                    // }
                }
                select:hover {
                    cursor: pointer;
                }
                select:focus-visible {
                    outline: none;
                }
            }
            .chose-reason::after {
                content: "";
                z-index: 3;
                background-image: url(../img/arrow-gray.svg);
                background-repeat: no-repeat;
                background-position: center;
                position: absolute;
                top: 50%;
                right: 10px;
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
                width: 20px;
                height: 20px;
            }
        }
        .checkbox-btn {
            background: white;
            width: 29px;
            height: 100%;
            display: flex;
            align-items: center;
            margin-right: 30px;
            border: none;
            input {
                display: none;
            }
            img {
                height: 29px;
                margin: 0;
            }
            img:hover {
                cursor: pointer;
            }
            .hide {
                display: none;
            }
        }
        .checkbox-btn:hover {
            cursor: default;
        }
    }
    .select-all {
        border: none;
        display: flex;
        flex-wrap: nowrap;
        margin-top: 20px;
        align-items: center;
        background-color: transparent;
        img {
            margin-right: 10px;
            height: 29px;
        }
        img:hover {
            cursor: pointer;
        }
        .hide {
            display: none;
        }
    }
    .confirm-returned-info {
        position: absolute;
        z-index: 16;
        top: 50%;
        display: none;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        width: auto;
        padding: 50px;
        .return-info-content {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        }
        h5 {
            width: 100%;
            font-size: 20px;
            font-weight: 500;
            text-align: center;
            padding: 35px 10px 50px;
        }
        a {
            display: block;
            width: 100%;
            text-align: center;
            font-weight: 500;
            font-size: 16px;
            color: #121212;
        }
        a:hover {
            text-decoration: none;
        }
    }
    .confirm-returned-info.active {
        display: block;
    }
}
.empty-cart {
    .row {
        width: 100%;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid black;
    }
    .empty-content {
        padding: 140px 0;
        width: 420px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        img {
            margin-bottom: 40px;
        }
        h2 {
            width: 100%;
            text-align: center;
            margin: 20px 0;
            font-size: 40px;
            line-height: 1;
        }
        button {
            width: 100%;
            font-size: 16px;
            font-weight: 500;
            border: none;
            margin: 15px 0;
            background-color: transparent;
            a {
                background-color: #121212;
                padding: 24px;
                color: white;
                text-decoration: none;
            }
        }
        button:hover {
            transform: scale(1.07);
        }
        a {
            padding: 15px 0;
            display: block;
            width: 100%;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            color: #121212;
        }
        a:hover {
            text-decoration: none;
        }
    
    }
}
.blur {
    display: none;
}
.blur.active {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 14;
    width: 100%;
    height: 100%;
    background-color: rgba(18, 18, 18, 0.4);
    opacity: 1;
    display: block;
}
.newsletter2-popap,
.newsletter-popap,
.table-popup,
.product-popap {
    position: fixed;
    background-color: white;
    top: 50%;
    left: -200%;
    max-width: 100%;
}
.close-pp4,
.close-pp3,
.close-ps,
.close-pp {
    top: 10px;
    right: 10px;
    position: absolute;
    display: block;
    padding: 0;
    width: 10px;
    height: 10px;
    z-index:100;
    img {
        width: 100%;
        height: 100%;
        vertical-align: text-top;
    }
}
.close-ps:hover,
.close-pp:hover {
    cursor: pointer;
    img {
        transform: rotate(90deg);
    }
}
.newsletter2-popap.active,
.newsletter-popap.active,
.table-popup.active,
.product-popap.active {
    max-width: 580px;
    z-index: 15;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .product-popap-content {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        height: 100%;
        padding: 50px;
        .product-describe {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            padding: 20px 0;
            justify-content: start;
            .box-img {
                max-width: 190px;
                max-height: 190px;
            }
            .box-content {
                padding-left: 20px;
                p {
                    font-size: 14px;
                    line-height: 1.43;
                    margin-bottom: 5px;
                }
                span {
                    line-height: 1.5;
                    font-weight: 400;
                    font-size: 16px;
                }
            }
            h4 {
                font-size: 16px;
                line-height: 1.5;
            }
        }
        a {
            width: 100%;
            text-align: center;
            color: #121212;
            padding: 20px 0;
            button {
                padding: 24px;
                width: 100%;
                border: none;
                background-color: #121212;
                color: white;
            }
            button:hover {
                transform: scale(1.07);
            }
        }
        a:hover {
            text-decoration: none;
            cursor: pointer;
        }
        
    }
    .done {
        width: 100%;
        text-align: center;
        img {
            width: 25px;
            height: 25px;
        }
    }
    .box-img {
        img {
            height: 100%;
            object-fit:cover;
        }
    }
    h3 {
        display: block;
        text-align: center;
        line-height: 1.7;
        padding: 20px 0;
        margin: 0;
        font-size: 20px;
        font-weight: 500;
    }
}
.table-popup.active{
    max-width:100%;
}
.delivery-popup {
    top: 50%;
    left: -600px;
    transform: translate(-50%, -50%);
    width: 500px;
    z-index: 15;
    max-width:100%;
    background-color: white;
    text-align: center;
    a {
        text-decoration: underline;
        color: black;
    }
    a:hover {
        text-decoration: underline;
    }
    p {
        font-weight: 500;
    }
    button {
        border: none;
        background-color: transparent;
        right: 25px;
        top: 25px;
    }
    button:hover {
        cursor: pointer;
        img {
            transform: rotate(90deg);
        }
    }
}
.delivery-popup.active {
    left: 50%;
}
.payment-popup {
    top: 50%;
    left: -600px;
    transform: translate(-50%, -50%);
    width: 500px;
    z-index: 15;
    max-width:100%;
    background-color: white;
    text-align: center;
    a {
        text-decoration: underline;
        color: black;
    }
    a:hover {
        text-decoration: underline;
    }
    p {
        font-weight: 500;
    }
    button {
        border: none;
        background-color: transparent;
        right: 25px;
        top: 25px;
    }
    button:hover {
        cursor: pointer;
        img {
            transform: rotate(90deg);
        }
    }
}
.payment-popup.active {
    left: 50%;
}
.showroom {
    .row {
        margin: 50px 18%;
        .showroom-content {
            padding: 0 70px;
            margin: 50px 0;
            .border-div {
                display: flex;
                border: 1px solid black;
                .text-content {
                    display: flex;
                    align-content: center;
                    justify-content: center;
                    flex-wrap: wrap;
                    padding-left: 5%;
                    h4 {
                        width: 100%;
                        font-size: 40px;
                        margin-bottom: 20px;
                        p{
                            font-size: 40px;
                        }
                    }
                    p {
                        width: 100%;
                        line-height: 2;
                        margin-bottom: 10px;
                    }
                    .tinner {
                        font-weight: 200;
                    }
                }
                .img-content {
                    overflow: hidden;
                    img {

                        width: auto;
                    }
                }
            }
        }
    }
    h2 {
        line-height: 1.63;
        text-align: center;
        font-size: 40px;
        width: 100%;
        padding: 0;
        margin: 50px 0;
    }
    p {
        font-size: 20px;
        font-weight: 500;
        width: 100%;
        margin-bottom: 60px;
    }
    .centered {
        text-align: center;
    }
}
.payment-end {
    .payment-method {
        padding: 70px 50px 70px 210px;
        h2 {
            width: 100%;
            font-size: 28px;
            font-weight: 400;
            align-content: center;
            padding: 40px 0;
            display: flex;
            flex-wrap: nowrap;
            margin: 0;
            p {
                font-size: 14px;
                display: flex;
                background-color: #121212;
                color: white;
                width: 27px;
                height: 27px;
                align-items: center;
                justify-content: center;
                margin: auto 25px auto 0;
            }
        }
        .payment-header {
            padding: 60px 0 30px;
        }
        form {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            .invoice {
                border: 1px solid black;
                .cont {
                    border: none;
                }
            }
            .add-adres {
                input {
                    padding: 11px;
                }
            }
            .invoice-dropdown.invisible {
                height: 0;
                overflow: hidden;
                margin: 0 !important;
            }
            .invoice-dropdown.visible {
                overflow: hidden;
                height: auto;
                input {
                    padding: 11px;
                }
            }
            .invoice.invisible {
                height: 0;
                overflow: hidden;
                margin: 0;
            }

            .cont {
                width: 100%;
                border: 1px solid black;
                margin: 7.5px 0;
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                padding: 30px;

                p {
                    margin-bottom: 0;
                    line-height: 1.2;
                    font-size: 20px;
                }
                span {
                    width: 100%;
                    font-size: 14px;
                    line-height: 1.71;
                }
                .delivery-type {
                    margin: 0 20px;
                    display: flex;
                    flex-wrap: wrap;
                    align-content: center;
                }
                .price {
                    font-size: 16px;
                    font-weight: 400;
                    margin: auto 0 auto auto;
                    width: 100px;
                    text-align: right;
                }
                img {
                    margin: auto 20px auto 20px;
                }
                p {
                    align-self: center;
                }
                .delivery-type-x2 {
                    display: flex;
                    flex-grow: 1;
                    justify-content: space-between;
                    margin: auto 20px;
                    flex-wrap: nowrap;
                    span {
                        width: auto;
                    }
                }                
            }
            .wrap-label {
                flex-wrap: wrap;
                flex-grow: 1;
                .delivery-type-x2 {
                    display: flex;
                    flex-grow: 1;
                    justify-content: space-between;
                    margin: auto 20px;
                    flex-wrap: nowrap;
                    span {
                        width: auto;
                    }
                }
                .label-5 {
                    width: 100%;
                    display: flex;
                    flex-wrap: nowrap;
                }
                .delivery-addres {
                    padding: 25px 0 10px 70px;
                    width: 100%;
                    .addres-data {
                        font-size: 14px;
                        line-height: 1.7;
                        text-transform: uppercase;
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: nowrap;
                        margin-bottom: 5px;
                        a {
                            font-size: 16px;
                            text-transform: none;
                            color: #121212;
                            font-weight: 500;
                        }
                        a:hover {
                            text-decoration: none;
                        }
                    }
                    p {
                        font-size: 14px;
                        line-height: 1.7;
                        span {
                            font-weight: 500;
                        }
                    }
                }
            }
            .checkbox-container {
                width: 20px;
                height: 20px;
                position: relative;
                display: flex;
                align-content: center;
                cursor: pointer;
                .checkmark {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 20px;
                    width: 20px;
                    background-color: white;
                    border: 1px solid black;
                }
                .checkmark:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    display: none;
                }
            }
            .checkbox-container input {
                    position: absolute;
                    margin: 0;
                    opacity: 0;
                    cursor: pointer;
            }
            .checkbox-container input:checked ~ .checkmark {
                    background-color: #121212;
            }
            .checkmark:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    display: none;
            }
            .checkbox-container input:checked ~ .checkmark:after {
                    display: block;
            }
            .checkbox-container .checkmark:after {
                    top: 50%;
                    transform: translateY(-50%);
                    left: 1px;
                    width: 16px;
                    height: 16px;
                    background: black;
                    border: 2px solid white;
            }
            .master-card {
                margin: 7.5px 0;
                padding-bottom: 10px;
                border: 1px solid black;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;

                label {
                    display: flex;
                    flex-wrap: nowrap;
                    align-content: center;
                    padding: 0;
                    width: 55%;
                    justify-content: space-between;
                    border: 1px solid black;
                    input {
                        margin: 0;
                        border: none;
                        padding: 15px;
                    }
                    input:focus-visible {
                        outline: none;
                    }
                    #card-number {
                        width: 50%;
                    }
                    #card-date {
                        width: 25%;
                    }
                    #card-cvv {
                        width: 25%;
                    }
                }

                span {
                    width: 55%;
                    text-align: center;
                    a {
                        color: lightgray;
                    }
                    a:hover {
                        text-decoration: underline;
                    }
                }
                .cont {
                    border: none;
                    .checkbox-container {
                        border: none;
                        width: 20px;
                        height: 20px;
                        position: relative;
                        display: flex;
                        align-content: center;
                        cursor: pointer;
                        .checkmark {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            height: 20px;
                            width: 20px;
                            background-color: white;
                            border: 1px solid black;
                        }
                        .checkmark:after {
                            content: "";
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            display: none;
                        }
                    }
                    .checkbox-container input {
                            position: absolute;
                            margin: 0;
                            opacity: 0;
                            cursor: pointer;
                    }
                    .checkbox-container input:checked ~ .checkmark {
                            background-color: #121212;
                    }
                    .checkmark:after {
                            content: "";
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            display: none;
                    }
                    .checkbox-container input:checked ~ .checkmark:after {
                            display: block;
                    }
                    .checkbox-container .checkmark:after {
                            top: 50%;
                            transform: translateY(-50%);
                            left: 1px;
                            width: 16px;
                            height: 16px;
                            background: black;
                            border: 2px solid white;
                    }
                    img {
                        margin: auto 50px auto 20px;
                    }
                    p {
                        align-self: center;
                        line-height: 1.2;
                        font-size: 20px;
                        margin: 0;
                    }
                }
            }
            button {
                background-color: #121212;
                margin: 50px auto 15px auto;
                justify-self: center;
                width: 53%;
                color: #FFF;
                padding: 10px 0;
                p {
                    color: white;
                    font-size: 16px;
                    text-decoration: none;
                    font-weight: 500;
                    margin-bottom: 0;
                    span {
                        line-height: 1.14;
                        font-size: 14px;
                    }
                }
            }
            button:hover {
                transform: scale(1.07);
            }
            .regulamin {
                width: 54%;
                font-size: 12px;
                line-height: 1.67;
                text-align: center;
                a {
                    font-weight: 500;
                    color: #121212;
                }
                a:hover {
                    text-decoration: none;
                }
            }
            .adres {
                width: 100%;
                height: auto;
                margin-bottom: 15px;
                display: flex;
                flex-wrap: wrap;
                border: 1px solid black;
                justify-content: flex-start;
                .cont {
                    border: none;
                    flex-wrap: nowrap;
                    align-items: flex-start;
                    label {
                        margin-right: 10px;
                        .checkmark {
                            top: 55%
                        }
                    }
                }
                .adres-name {
                    font-size: 16px;
                    margin: 0;
                    font-weight: 500;
                    text-decoration: none;
                    color: #121212;
                    display: inline;
                    padding-left: 0;
                }
            }
        }
    }
    .payment-nav {
        margin-right: 0;
        border-left: 1px solid black;
        border-top: 1px solid black;
        position: relative;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: flex-start;
        .payment {
            padding: 100px 100px 0 40px;
            background-color: transparent;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            .subtotal-price { 
                text-transform: uppercase;
                width: 100%;
                font-size: 16px;
                font-weight: normal;
                margin: 0;
                padding-bottom: 15px;
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                span {
                    font-weight: 400;
                }
            }
            .total-price {
                font-size: 16px;
                border-top: 1px solid lightgray;
                padding: 25px 0;
                margin-top: 10px;
            }
            p {
                font-size: 14px;
                line-height: 1.43;
                margin-bottom: 13px;
            }
            button {
                transition: 0.6s;
            }
            .btn-dark {
                margin: 15px 0;
                width: 100%;
                border-radius: 0;
                padding: 15px;
            }
            .btn-dark:focus {
                box-shadow: none;
            }
            .btn-dark:hover {
                transform: scale(1.07);
            }
            .orders-hide {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                background-color: transparent;
                width: auto;
                text-decoration: none;
                color: #121212;
                text-transform: uppercase;
                margin: 16.5px 0;
                width: 100%;
                text-align: left;
                img {
                    margin-left: 10px;
                    transform: rotate(180deg);
                }
                .rotate {
                    transform: rotate(0deg);
                }
            }
            .orders-hide:hover {
                cursor: pointer;
            }
            .orders {
                opacity: 0;
                height: 0;
                overflow: hidden;
            }
            .orders.active {
                opacity: 1;
                height: auto;
                width: 100%;
                .order-row {
                    height: 125px;
                    width: 100%;
                    display: flex;
                    flex-wrap: nowrap;
                    padding: 12.5px 0;
                    .order-content {
                        display: flex;
                        flex-wrap: nowrap;
                        a {
                            max-width: 100px;
                            max-height: 100px;
                            overflow: hidden;
                            img{
                                max-width: 100px;
                                max-height: 100px;
                                overflow: hidden;
                            }
                        }
                        a:hover {
                            img {
                                transform: scale(1.07);
                                cursor: pointer;
                            }
                        }
                        .order-description {
                            padding-left: 20px;
                        }
                        p {
                            font-size: 14px;
                            display: flex;
                            flex-wrap: wrap;
                            span {
                                width: 100%;
                            }
                        }
                        .order-name {
                            line-height: 1.43;
                            color: #121212;
                            text-decoration: none;
                        }
                        .order-name:hover {
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }
                    .order-value {
                        display: flex;
                        align-items: flex-end;
                        justify-content: flex-end;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 1.5;
                    }
                }
            }
        }
        .payment-nav-btn {
            position: absolute;
            border: 1px solid black;
            top: 250px;
            right: 100%;
            background-color: transparent;
            transform: rotate(90deg);
            img {
                height: 100%;
                padding: 2px;
            }
        }
        .payment-nav-btn:hover {
            transform: scaleY(1.1) rotate(90deg);
        }
        .payment-nav-btn.rotate {
            transform: translate(270deg);
        }
    }
    .payment-nav-btn {
        display: none;
    }
    .confirm-buy-info {
        position: fixed;
        z-index: 16;
        top: 50%;
        display: none;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        width: auto;
        padding: 50px;
        .return-info-content {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        }
        h5 {
            width: 100%;
            font-size: 20px;
            font-weight: 500;
            text-align: center;
            padding: 35px 10px 50px;
        }
        a {
            display: block;
            width: 100%;
            text-align: center;
            font-weight: 500;
            font-size: 16px;
            color: #121212;
        }
        a:hover {
            text-decoration: none;
        }
    }
    .confirm-buy-info.active {
        display: block;
    }
}
.product-details {
    .row {
        padding: 50px 12% 0;
        height: 100%;
        .side {
            padding-right: 15px;
            max-height: 100%;
            padding-left: 0;
            .box-img {
                width: 100%;
                height: 33.333%;
                padding: 5px 0;
                display: flex;
                overflow: hidden;
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
                video {
                    max-width: 100%;
                    height: auto;
                    object-fit: cover;
                    width: 100%;
                }
                img:hover {
                    cursor: pointer;
                }
                &:first-child{
                    padding-top:0;
                    padding-bottom: 10px;
                }
                &:last-child {
                    padding-top: 10px;
                    padding-bottom:0;            }
            }
            
        }
        .main {
            padding: 0;
            display: flex;
            flex-wrap: nowrap;
            .box-img {
                padding: 0;
                overflow: hidden;
                img {
                    height: 100%;
                    width: 100%;
                    object-position: center;
                    object-fit: cover;
                }
                .slider {
                    display: none;
                    img {
                        display: block;
                    }
                }
            }
            .box-img:hover {
                img {
                    transform: scale(1.07);
                    cursor: pointer;
                }
            }
            .main-content {
                padding: 30px 70px 30px 55px;
                border: 1px solid black;
                border-left: none;
                p {
                    font-size: 14px;
                    line-height: 1.43;
                    margin: 20px 0 0;
                    a {
                        color: #cccccc;
                        text-decoration: none;
                    }
                    a:hover {
                        text-decoration: underline;
                    }
                }
                h1,
                h3 {
                    font-size: 20px;
                    line-height: 2;
                    font-weight: 400;
                }
                .price {
                    line-height: 1;
                    margin: 5px 0 15px;
                }
                .bold {
                    font-weight: 500;
                }
                .uppercase {
                    text-transform: uppercase;
                }
                .bolder {
                    font-weight: 600;
                }
                .space-beetwen {
                    width: 100%;
                    justify-content: space-between;
                }
                form {
                    margin-bottom: 20px;
                    .chose-color {
                        width: 100%;
                        height: 25px;
                        display: flex;
                        align-content: flex-start;
                        .checkbox-container {
                            width: auto;
                            height: auto;
                            position: relative;
                            margin-right: 10px;
                            cursor: pointer;
                            width: 25px;
                            label {
                                top: 0;
                            }
                        }
                        .checkbox-container input {
                            position: absolute;
                            border: none;
                            opacity: 0;
                            cursor: pointer;
                        }
                        .checkmark {
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 25px;
                            width: 25px;
                        }
                        .checkbox-container input:checked ~ .checkmark {
                            border: 1px solid black;
                        }
                        .checkmark:after {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            display: none;
                        }
                        .checkbox-container input:checked ~ .checkmark:after {
                            display: block;
                        }
                        .checkbox-container .checkmark:after {
                            top: 0px;
                            left: 0px;
                            width: 23px;
                            height: 23px;
                            border: 3px solid white;
                        }
                    }
                    label {
                        position: relative;
                        height: 50px;
                        width: 100%;
                        margin-bottom: 10px;
                        select {
                            position: absolute;
                            z-index: 3;
                            width: 100%;
                            font-weight: 500;
                            text-transform: uppercase;
                            padding: 12px;
                            -webkit-appearance: none;
                            background-color: transparent;
                        }
                        select:hover {
                            cursor: pointer;
                        }
                    }
                    .chose-size::after {
                        content: "";
                        background-image: url('../img/language.png');
                        position: absolute;
                        overflow: visible;
                        z-index: 2;
                        top: 50%;
                        right: 20px;
                        transform: translateY(-50%);
                        width: 20px;
                        height: 20px;
                    }
                    
                    label:hover {
                        cursor: pointer;
                    }
                    .to-cart {
                        width: 100%;
                        background-color: #121212;
                        border: none;
                        display: flex;
                        height: 50px;
                        justify-content: center;
                        align-content: center;
                        p {
                            color: white;
                            display: flex;
                            width: auto;
                            display: block;
                            width: 100%;
                            align-content: center;
                            justify-content: center;
                            text-align: center;
                            padding: 10px 10px 10px 0;
                            margin: auto 0;

                        }
                        img {
                            padding: 0 10px 2px 0;
                        }
                    }
                    .to-cart:hover {
                        transform: scale(1.07);
                    }
                    .space-beetwen {
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                        p {
                            margin-bottom: 5px;
                        }
                    }
                }
                .about-product {
                    margin: 10px 0 20px;
                    p {
                        margin: 10px 0;
                    }
                }
                .share {
                    p {
                        margin: 0 0 15px;
                        font-size: 16px;
                    }
                    img {
                        max-width: 15px;
                        max-height: 15px;
                        margin: 5px;
                    }
                    img:hover {
                        transform: scale(1.07);
                    }
                }
            }
        }
    }
    .lack-of-product {
        width: 570px;
        height: 350px;
        display: none;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 16;
        padding: 50px;
        background-color: white;
        form {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            align-content: space-between;
            flex-wrap: wrap;
            input {
                width: 100%;
                padding: 30px;
            }
            button {
                background-color: #121212;
                font-size: 16px;
                font-weight: 500;
                color: white;
                width: 100%;
                padding: 20px;
                text-align: center;
                border: none;
            }
            button:hover {
                transform: scale(1.07);
            }
        }
        h4 {
            line-height: 1.7;
            font-size: 20px;
            font-weight: 500;
            width: 100%;
            text-align: center;
        }
        .close-lack-popap {
            position: absolute;
            right: -20px;
            top: -30px;
            img {
                width: 15px;
                height: 15px;
            }
        }
        .close-lack-popap:hover {
            img {
                transform: rotate(90deg);
            }
        }

    }
    .lack-of-product.active {
        display: block;
    }
    .close-up {
        display: none;
        width: auto;
        z-index: 16;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 80%;
        .box-img {
            display: none;
        }
        .box-img.show {
            display: flex;
            justify-content: center;
            align-content: center;
            overflow: hidden;
            width: 100%;
            height: 100%;
            position: relative;
            img {
                height: 100%;
                width: auto;
            }
        }
        button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-color: white;
            border: none;
            img {
                width: 38px;
                height: 38px;
            }
        }
        button:hover {
            img {
                transform: scale(1.07);
            }
        }
        .prev-img {
            left:  -80px;
        }
        .next-img {
            right: -80px;
        }

    }
    .close-up.active {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

var {
    color: #121212;
}

@keyframes arrowShake {
    40% {
        top: 140%;
    }
    50% {
        top: 135%
    }
    60% {
        top: 140%;
    }
}

@import "slick";
@import "slick-theme";
@import "responsive"; // --- Vendors Bootstrap 4

// @include scrollbars(10px, 8px, white, $borders-color, none, 0);
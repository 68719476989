@charset "UTF-8";
@font-face {
  font-family: 'Avenir Next';
  src: url("../fonts/subset-AvenirNext-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/subset-AvenirNext-Bold.woff2") format("woff2"), url("../fonts/subset-AvenirNext-Bold.ttf") format("truetype"), url("../fonts/subset-AvenirNext-Bold.svg#AvenirNext-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url("../fonts/subset-AvenirNext-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/subset-AvenirNext-Regular.woff2") format("woff2"), url("../fonts/subset-AvenirNext-Regular.ttf") format("truetype"), url("../fonts/subset-AvenirNext-Regular.svg#AvenirNext-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next';
  src: url("../fonts/subset-AvenirNext-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/subset-AvenirNext-Medium.woff2") format("woff2"), url("../fonts/subset-AvenirNext-Medium.ttf") format("truetype"), url("../fonts/subset-AvenirNext-Medium.svg#AvenirNext-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next Ultra';
  src: url("../fonts/subset-AvenirNext-UltraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/subset-AvenirNext-UltraLight.woff2") format("woff2"), url("../fonts/subset-AvenirNext-UltraLight.ttf") format("truetype"), url("../fonts/subset-AvenirNext-UltraLight.svg#AvenirNext-UltraLight") format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next Demi';
  src: url("../fonts/subset-AvenirNext-DemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/subset-AvenirNext-DemiBold.woff2") format("woff2"), url("../fonts/subset-AvenirNext-DemiBold.ttf") format("truetype"), url("../fonts/subset-AvenirNext-DemiBold.svg#AvenirNext-DemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Avenir Next';
  transition: 0.6s;
}

main {
  margin-top: 65px;
}

.container {
  max-width: 1920px;
  padding: 0;
  position: relative;
}

.container .row {
  margin: 0;
}

.promo-strap {
  color: white;
  top: 0;
  z-index: 16;
  height: 48px;
}

.promo-strap p {
  font-size: 12px;
  line-height: 30px;
  letter-spacing: 2px;
}

header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 11;
}

header .container .navbar {
  z-index: 6;
  margin: auto;
  width: 100%;
  height: 64px;
  padding: 20px 80px;
  display: flex;
  transition: 0.6s;
  align-content: center;
  background-color: white !important;
}

header .container .navbar button {
  border: none;
  transition: 0.3s;
}

header .container .navbar button .navbar-toggler-icon {
  transition: 0.3s;
}

header .container .navbar button .rotate {
  transform: rotate(90deg);
}

header .container .navbar button:focus {
  box-shadow: none;
}

header .container .navbar .collapse {
  display: flex;
  width: 100%;
  height: 100%;
  transition: 0.3s;
  z-index: 6;
  justify-content: space-between;
}

header .container .navbar .collapse .navbar-nav {
  display: flex;
  justify-content: space-between;
}

header .container .navbar .collapse .navbar-nav .nav-link {
  color: #121212;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  transition: 0.6s;
}

header .container .navbar .collapse .navbar-nav .nav-link::after {
  transition: 0.1s;
  background: url(../img/arrow.svg) center no-repeat;
  background-size: 14px;
  border: none;
  vertical-align: 2px;
  width: 14px;
  height: 8px;
}

header .container .navbar .collapse .navbar-nav .nav-link.rotate::after {
  transform: rotate(180deg);
}

header .container .navbar .collapse .navbar-nav .nav-link:hover {
  text-decoration: underline;
}

header .container .navbar .collapse .navbar-nav .dropdown-menu {
  min-width: 20%;
  left: 0;
  height: 0;
  display: flex;
  flex-wrap: wrap;
  opacity: 0;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  padding: 2px 0;
  transition: 0.3s;
  border-radius: 0;
}

header .container .navbar .collapse .navbar-nav .dropdown-menu .dropdown-item {
  opacity: 0;
  height: 0;
  padding: 0;
}

header .container .navbar .collapse .navbar-nav .active {
  opacity: 1;
  height: auto;
}

header .container .navbar .collapse .navbar-nav .active .dropdown-item {
  opacity: 1;
  width: 100%;
  height: auto;
  padding: 1px 8px;
}

header .container .navbar .collapse .navbar-nav .active .dropdown-item:hover {
  text-decoration: underline;
  background-color: transparent;
}

header .container .navbar .collapse .navbar-nav .shop-menu {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  transition: .3s;
  border-radius: 0;
  background-color: white !important;
  border: none;
  color: black;
  left: 89px;
  top: 63px;
  width: 150px;
  min-width: 0;
}

header .container .navbar .collapse .navbar-nav .shop-menu .dropdown-item {
  display: none;
  background-color: white;
  padding: 5px 20px;
}

header .container .navbar .collapse .navbar-nav .shop-menu.active {
  padding: 5px 0;
}

header .container .navbar .collapse .navbar-nav .shop-menu.active .dropdown-item {
  display: block;
  opacity: 1;
}

header .container .navbar .collapse .navbar-icons {
  z-index: 6;
  flex: 33%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  height: 15px;
}

header .container .navbar .collapse .navbar-icons button {
  background-color: transparent;
  padding: 0;
  border: none;
}

header .container .navbar .collapse .navbar-icons button img {
  height: 15px;
}

header .container .navbar .collapse .navbar-icons button:hover {
  opacity: 0.5;
}

header .container .navbar .collapse .navbar-icons form {
  justify-content: flex-end;
  align-items: flex-end;
  transition: 0.3s;
  display: flex;
  margin: 0 10px;
}

header .container .navbar .collapse .navbar-icons form input {
  transition: 0.3s;
  width: 50%;
  border-top: none;
  border-left: none;
  border-right: none;
}

header .container .navbar .collapse .navbar-icons form input:focus {
  outline: none;
  width: 100%;
}

header .container .navbar .collapse .navbar-icons .account {
  margin: 0 10px;
}

header .container .navbar .collapse .navbar-icons .cart {
  color: #121212;
  text-decoration: none;
  display: flex;
  margin: 0 10px;
  align-items: flex-end;
}

header .container .navbar .collapse .navbar-icons .cart p {
  font-size: 14px;
  height: 100%;
  padding-bottom: 17px;
  margin: 0 0 0 2px;
}

header .container .navbar .collapse .navbar-icons .cart img {
  height: 15px;
}

header .container .navbar .collapse .navbar-icons .cart:hover {
  opacity: 0.5;
}

header .container .navbar .collapse .navbar-icons .search {
  display: flex;
  justify-content: flex-end;
  margin-right: 5px;
}

header .container .navbar .navbar-title-img {
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  display: flex;
  z-index: 20;
  align-items: center;
}

header .container .navbar .navbar-title-img img:hover {
  cursor: pointer;
}

header .container .navbar.scroll {
  height: 44px;
  padding-top: 10px;
  padding-bottom: 10px;
}

header .container .navbar.scroll .collapse .navbar-nav .shop-menu {
  top: 40px;
}

video {
  max-width: 100%;
  height: auto;
}

.mobile {
  display: none;
}

.photo-banner {
  width: 100%;
  position: relative;
  color: #fff;
}

.photo-banner .container .photo-arrow {
  display: flex;
  background-color: transparent;
  border: none;
  width: 41px;
  height: 96px;
  position: absolute;
  bottom: 15%;
  left: 50%;
  transition: 0.6s;
  transform: translateX(-50%);
}

.photo-banner .container .photo-arrow img {
  object-fit: contain;
  height: 100%;
}

.photo-banner .container .photo-arrow:hover {
  bottom: 12%;
}

.photo-banner .container img {
  width: 100%;
  height: 85vh;
  object-fit: cover;
}

.photo-banner .container .photo-content {
  position: absolute;
  bottom: 15%;
  text-align: center;
  width: 100%;
}

.photo-banner .container .photo-content .photo-title {
  font-size: 90px;
  line-height: 1;
  text-align: center;
  margin-bottom: 44px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.photo-banner .container .photo-content .underline-text {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  text-decoration: underline;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
}

.photo-banner .container .photo-content-second {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translateX(-50%);
}

.photo-banner .container .photo-content-second p {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}

.photo-banner .container .photo-content-second .photo-title-smaller {
  font-size: 40px;
  line-height: 1.29;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.photo-banner .container .text-content {
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translateX(-50%);
}

.photo-banner .container .text-content a {
  text-decoration: none;
  color: white;
  font-size: 40px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  width: 100%;
}

.photo-banner .container .text-content a .price {
  padding-top: 20px;
  font-size: 20px;
  font-weight: 400;
  width: 100%;
}

.photo-banner .container .text-content.about {
  bottom: 25%;
}

.photo-banner .container .text-content.about a {
  display: flex;
  flex-wrap: wrap;
  font-size: 20px;
  font-weight: 500;
}

.photo-banner .container .text-content.about a .bigger {
  padding-top: 30px;
  width: 100%;
  font-size: 40px;
  font-weight: normal;
}

.photo-banner h2 {
  font-size: 80px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  line-height: 0.5;
  font-weight: normal;
}

.black-banner {
  color: #fff;
}

.black-banner .container {
  background-color: #121212;
  display: flex;
  justify-content: center;
}

.black-banner .container .row-padding {
  padding: 45px 180px;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.black-banner .container .row-padding .col-md-4 {
  padding: 90px 30px;
  position: relative;
}

.black-banner .container .row-padding .col-md-4 .box-img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.black-banner .container .row-padding .col-md-4 .box-img img {
  max-width: 100%;
  transition: 0.6s;
  object-fit: cover;
}

.black-banner .container .row-padding .col-md-4 a:hover .box-img img {
  transform: scale(1.07);
}

.black-banner .container .row-padding .col-md-4 .subtitle {
  position: absolute;
  top: 50%;
  color: white;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 35px;
  line-height: 1.1;
  text-align: center;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.black-banner .container .row-padding .box {
  max-width: 33%;
  position: relative;
}

.black-banner .container .row-padding .box img {
  object-fit: contain;
  height: 100%;
}

.black-banner .container .row-padding .box .subtitle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 70px;
  line-height: 1.1;
  text-align: center;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.woocommerce-tabs {
  padding: 45px 180px 0 180px;
}

.new-collection {
  padding: 0 0 45px;
}

.new-collection .row-padding {
  padding: 80px 180px 0 180px;
  width: 100%;
  justify-content: space-between;
}

.new-collection .row-padding .col-md-8 {
  padding-left: 0;
  padding-right: 40px;
}

.new-collection .row-padding .col-md-4 {
  padding-right: 0;
  padding-left: 0;
}

.new-collection .row-padding .pl-0 {
  padding-left: 0;
  padding-right: 30px;
}

.new-collection .row-padding .pr-0 {
  padding-right: 0;
  padding-left: 30px;
}

.new-collection .row-padding .prl-0 {
  padding-left: 15px;
  padding-right: 15px;
}

.new-collection .row-padding .box-v2 {
  height: 100%;
  padding-bottom: 136px;
  position: relative;
}

.new-collection .row-padding .box-v2 .box-text {
  position: absolute;
  bottom: 0%;
  left: 50%;
  width: 100%;
  text-align: center;
  transform: translate(-50%, -50%);
}

.new-collection .row-padding .box-v2 .box-text a {
  font-size: 20px;
  width: 100%;
  display: block;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  transition: 0.3s;
  color: #121212;
  text-decoration: none;
  margin-bottom: 1rem;
}

.new-collection .row-padding .box-v2 .box-text a .product-price {
  padding-top: 15px;
  display: block;
  font-weight: 400;
}

.new-collection .row-padding .box-v2 .box-img img, .new-collection .row-padding .box-v2 .box-img2 img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.new-collection .row-padding .box-v2 .box-img {
  height: 100%;
  overflow: hidden;
}

.new-collection .row-padding .box-v2 .box-img a img {
  transition: 0.6s;
}

.new-collection .row-padding .box-v2 .box-img a:hover img {
  transform: scale(1.07);
}

.new-collection .row-padding .box-v2 .box-img2 {
  height: 75%;
  width: 100%;
  overflow: hidden;
}

.new-collection .row-padding .box-v2 .box-img2 a img {
  transition: 0.6s;
}

.new-collection .row-padding .box-v2 .box-img2 a:hover img {
  transform: scale(1.07);
}

.new-collection .row-padding .box-v2 .text-content {
  height: 25%;
  color: var(1);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  display: flex;
  flex-wrap: wrap;
  align-content: start;
}

.new-collection .row-padding .box-v2 .text-content .new-collection-header {
  font-size: 20px;
  margin-bottom: 0;
  line-height: 1.53;
  letter-spacing: -0.53px;
}

.new-collection .row-padding .box-v2 .text-content p {
  font-size: 20px;
  line-height: 1.15;
  letter-spacing: -0.5px;
}

.new-collection .row-padding .box-v2 .text-content button {
  background-color: white;
  padding-bottom: 20px;
  border: none;
  position: relative;
  width: 100%;
  text-align: left;
}

.new-collection .row-padding .box-v2 .text-content button a {
  position: relative;
  left: 0;
  text-decoration: none;
  padding: 10px 0;
  font-size: 12px;
  transition: 0.3s;
  display: block;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #121212;
}

.new-collection .row-padding .box-v2 .text-content button a img {
  position: absolute;
  transform: translateY(-50%);
  width: 31px;
  height: 9px;
  top: 50%;
  left: 10%;
  transition: 0.3s;
}

.new-collection .row-padding .box-v2 .text-content button a:hover {
  text-decoration: underline;
}

.new-collection .row-padding .box-v2 .text-content button a:hover img {
  left: 11%;
}

.new-collection .container .row-border {
  padding-bottom: 50px;
}

.new-collection .container .row-border h3 {
  font-size: 20px;
  text-align: left;
  width: 100%;
  margin: 10px 0 30px;
  padding: 0;
  line-height: 2;
  font-weight: normal;
}

.personal-stylist {
  color: #fff;
  height: 55vw;
  max-height: 950px;
}

.personal-stylist .black-banner {
  height: 100%;
}

.personal-stylist .black-banner .container {
  padding: 40px 0;
  height: 100%;
}

.personal-stylist .black-banner .container .row-padding .slideshow-container {
  overflow: hidden;
  position: relative;
}

.personal-stylist .black-banner .container .row-padding .slideshow-container .slide.current {
  display: flex;
}

.personal-stylist .black-banner .container .row-padding .slideshow-container .slide.prev {
  display: flex;
  left: -110%;
}

.personal-stylist .black-banner .container .row-padding .slideshow-container .slide.next {
  display: flex;
  left: 100%;
}

.personal-stylist .black-banner .container .row-padding .slideshow-container .slide.hide {
  display: none;
}

.personal-stylist .black-banner .container .row-padding .slideshow-container .slide {
  transition: 1s;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #121212;
  padding: 0;
  flex-wrap: wrap;
  justify-content: center;
}

.personal-stylist .black-banner .container .row-padding .slideshow-container .slide .text-row {
  height: 16%;
  width: 100%;
}

.personal-stylist .black-banner .container .row-padding .slideshow-container .slide .text-row h2 {
  width: 100%;
  text-align: center;
  font-size: 40px;
  line-height: 1;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.personal-stylist .black-banner .container .row-padding .slideshow-container .slide .text-row p {
  font-size: 16px;
  width: 100%;
  font-weight: 500;
  line-height: 1;
  padding-top: 12px;
  text-align: center;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-lg-4 {
  padding: 0 25px;
}

.personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-3 {
  display: flex;
  flex-wrap: wrap;
  object-fit: contain;
  height: 84%;
  align-content: space-between;
}

.personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-3 .box-img {
  width: 100%;
  max-height: 48%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-3 .box-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.6s;
  z-index: 2;
}

.personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-3 .box-img .text-content {
  z-index: 3;
}

.personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-3 .box-img .text-content a {
  font-size: 16px;
  transition: 0.6s;
}

.personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-3 .box-img:hover .text-content a {
  transform: scale(1.07);
}

.personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-3 .box-img:hover img {
  transform: scale(1.07);
}

.personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-5 {
  height: 84%;
}

.personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-5 .box-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: relative;
  overflow: hidden;
}

.personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-5 .box-img img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  transition: 0.6s;
}

.personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-5 .box-img .text-content {
  top: 50%;
  z-index: 3;
}

.personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-5 .box-img .text-content a {
  transition: 0.6s;
  font-size: 40px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}

.personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-5 .box-img .text-content a .product-price {
  font-size: 20px;
  font-weight: 400;
}

.personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-5 .box-img:hover img {
  transform: scale(1.07);
}

.personal-stylist .black-banner .container .row-padding .slideshow-container .slide .text-content {
  position: absolute;
  top: 55%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 1rem;
}

.personal-stylist .black-banner .container .row-padding .slideshow-container .slide .text-content a {
  font-size: 20px;
  width: 100%;
  display: block;
  text-align: center;
  color: white;
  text-decoration: none;
}

.personal-stylist .black-banner .container .row-padding .slideshow-container .slide .text-content .product-price {
  padding-top: 15px;
  display: block;
  font-weight: 400;
}

.personal-stylist .black-banner .container .row-padding .stylist-arrow {
  z-index: 3;
  display: flex;
  align-content: center;
  color: white;
  background-color: transparent;
  border: none;
  position: absolute;
  padding: 0;
  width: 80px;
  top: 55%;
  transition: 0.6s;
}

.personal-stylist .black-banner .container .row-padding .stylist-arrow img {
  width: 45%;
  align-self: center;
}

.personal-stylist .black-banner .container .row-padding .stylist-arrow a {
  text-decoration: none;
  color: white;
}

.personal-stylist .black-banner .container .row-padding .stylist-left-arrow {
  left: 10%;
}

.personal-stylist .black-banner .container .row-padding .stylist-left-arrow a {
  padding-left: 5px;
}

.personal-stylist .black-banner .container .row-padding .stylist-left-arrow:hover {
  left: 9%;
}

.personal-stylist .black-banner .container .row-padding .stylist-right-arrow {
  right: 10%;
}

.personal-stylist .black-banner .container .row-padding .stylist-right-arrow a {
  padding-right: 5px;
}

.personal-stylist .black-banner .container .row-padding .stylist-right-arrow:hover {
  right: 9%;
}

footer {
  border-top: 1px solid;
}

footer .container {
  background-color: white;
  height: 100%;
  padding: 40px 30px 5px 30px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

footer .container .row-padding {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

footer .container .row-padding .footer-img {
  width: 170px;
  padding-left: 30px;
  height: 20px;
  object-fit: contain;
}

footer .container .row-padding .col-flex {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  letter-spacing: normal;
  color: var(1);
}

footer .container .row-padding .col-flex a {
  color: #9D9C9D;
  text-decoration: none;
  width: 100%;
  font-weight: 200;
}

footer .container .row-padding .social {
  align-content: space-between;
}

footer .container .row-padding .social .contact, footer .container .row-padding .social .visit-us {
  width: 100%;
}

footer .container .row-padding .social .contact p, footer .container .row-padding .social .visit-us p {
  margin-bottom: 28px;
}

footer .container .row-padding .social .social-media {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
}

footer .container .row-padding .social .social-media a {
  transition: 0.3s;
  width: auto;
  padding: 0 20px 0 0;
}

footer .container .row-padding .social .social-media a img {
  height: 100%;
  object-fit: contain;
}

footer .container .row-padding .social .social-media a:hover {
  transform: scale(1.07);
}

footer .container .row-padding .black-font {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  width: 100%;
  height: 16px;
  margin-bottom: 20px;
}

footer .container .row-padding .gray-font {
  margin-top: 10px;
  line-height: 1.44;
  letter-spacing: -0.4px;
  font-style: normal;
  font-size: 14px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  transition: 0.3s;
  color: #9D9C9D;
}

footer .container .row-padding .gray-font:hover {
  cursor: pointer;
  text-decoration: underline;
}

footer .container .row-padding .default:hover {
  cursor: default;
  text-decoration: none;
}

footer .container .row-padding .sign-up {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(1);
}

footer .container .row-padding form {
  margin-top: 20px;
  position: relative;
}

footer .container .row-padding form .submit-form {
  position: absolute;
  display: flex;
  background-color: white;
  border: none;
  align-items: center;
  width: 60px;
  height: 90%;
  bottom: 5%;
  transition: 0.6s;
  right: 5%;
}

footer .container .row-padding form .submit-form:hover {
  right: 4%;
}

footer .container .row-padding form .sign-input {
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
  border: 1px solid black;
}

footer .container .row-padding .showroom {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

footer .container .row-padding .showroom .showroom-text {
  display: flex;
  flex-wrap: wrap;
}

footer .container .row-padding .showroom .showroom-text a {
  width: 100%;
  color: #9D9C9D;
  cursor: pointer;
  font-size: 14px;
}

footer .container .row-padding .showroom .showroom-text a:hover {
  text-decoration: none;
  cursor: pointer;
}

footer .container .row-padding .showroom .read-more {
  position: relative;
  font-size: 12px;
  margin: 14px 0 0;
}

footer .container .row-padding .showroom .read-more a {
  display: block;
  width: auto;
  height: 100%;
  color: #121212;
  text-decoration: none;
}

footer .container .row-padding .showroom .read-more img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 105%;
}

footer .container .row-padding .showroom .read-more:hover {
  cursor: pointer;
}

footer .container .row-padding .showroom .read-more:hover a {
  text-decoration: underline;
}

footer .container .row-padding .showroom .read-more:hover img {
  left: 110%;
}

footer .container .row-padding .showroom .black-font {
  font-weight: 500;
  line-height: 1;
  letter-spacing: normal;
  height: 16px;
  margin-bottom: 20px;
}

footer .container .row-padding .showroom .gray-font {
  margin-top: 10px;
  text-decoration: none;
  line-height: 1.44;
  letter-spacing: -0.4px;
  font-weight: 200;
}

footer .container .row-padding .showroom .gray-font:hover {
  text-decoration: underline;
}

footer .container .copy-write {
  width: 100%;
  align-self: flex-end;
  padding-top: 50px;
}

.shop-product .row {
  display: flex;
  width: 100%;
  padding: 100px 0 80px 0;
  flex-wrap: wrap;
  justify-content: center;
}

.shop-product .row .col-xxl-2 {
  padding-left: 20px;
  padding-right: 20px;
}

.shop-product .row .col-xxl-2 a {
  display: flex;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  height: 80%;
}

.shop-product .row .col-xxl-2 a img {
  height: auto;
  width: 100%;
  transition: 0.6s;
}

.shop-product .row .col-xxl-2 a:hover img {
  transform: scale(1.07);
}

.shop-product .row .col-xxl-2 .text-content {
  height: 20%;
  padding-top: 5px;
  overflow: hidden;
}

.shop-product .row .col-xxl-2 .text-content a {
  display: flex;
  flex-wrap: wrap;
  transition: 0.6s;
  align-content: flex-end;
  text-decoration: none;
  width: 100%;
  color: #121212;
  font-family: Avenir Next;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
}

.shop-product .row .col-xxl-2 .text-content a .price {
  width: 100%;
  text-align: center;
  font-weight: 400;
}

.shop-product .row .col-xxl-2 .text-content a:hover {
  transform: scale(1.07);
}

.shop-new-collection {
  padding: 100px 0;
}

.shop-new-collection .container .row {
  width: 100%;
}

.shop-new-collection .container .row .col-12 {
  margin-top: 100px;
  display: flex;
}

.shop-new-collection .container .row .col-12 .box {
  max-width: 25%;
  width: 100%;
  height: auto;
}

.shop-new-collection .container .row .col-12 .box a {
  display: flex;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  height: 90%;
}

.shop-new-collection .container .row .col-12 .box a img {
  width: 100%;
}

.shop-new-collection .container .row .col-12 .box a:hover img {
  transform: scale(1.07);
}

.shop-new-collection .container .row .col-12 .box .text-content {
  height: 10%;
}

.shop-new-collection .container .row .col-12 .box .text-content a {
  align-items: flex-end;
  font-size: 16px;
  font-style: normal;
  line-height: 1.25;
  text-align: center;
  padding-top: 5px;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  text-decoration: none;
  color: #121212;
}

.shop-new-collection .container .row .col-12 .box .text-content a .price {
  width: 100%;
  font-weight: 400;
}

.shop-new-collection .container .row .col-12 .box .text-content a:hover {
  transform: scale(1.07);
}

.shop-new-collection .container .row .col-sm-6 .box {
  max-width: 35%;
  width: 100%;
}

.shop-new-collection .container .row .col-sm-6 .box a {
  height: auto;
}

.shop-new-collection .container .row .col-sm-6 .box .text-content {
  height: auto;
}

.shop-new-collection .container .row .center {
  justify-content: center;
}

.shop-new-collection .container .row .left {
  justify-content: flex-start;
  padding-left: 20px;
}

.shop-new-collection .container .row .right {
  justify-content: flex-end;
  padding-right: 20px;
}

.shop-new-collection .head-text {
  font-size: 20px;
  line-height: 2;
  color: #121212;
  width: 100%;
  padding: 0;
  margin: 0;
  text-align: center;
}

.account-popap {
  position: absolute;
  right: 0;
  top: -350px;
  background-color: #FFF;
  width: 350px;
  height: auto;
  border: none;
  display: flex;
  z-index: 5;
  flex-wrap: wrap;
  justify-content: center;
  transition: 1s;
}

.account-popap .login-popap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  padding: 20px;
}

.account-popap .login-popap p {
  width: 100%;
  text-align: center;
  font-size: 20px;
  line-height: 2;
  font-weight: 500;
  letter-spacing: normal;
}

.account-popap .login-popap button {
  background-color: #121212;
  width: 100%;
}

.account-popap .login-popap button a {
  display: inline-block;
  font-size: 16px;
  padding: 10px 0;
  color: white;
  font-weight: 500;
  width: 100%;
}

.account-popap .login-popap button:hover {
  transform: scale(1.07);
}

.account-popap .register-popap {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  width: 100%;
  border: 1px solid black;
  padding: 20px;
}

.account-popap .register-popap p {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 2;
  letter-spacing: normal;
}

.account-popap .register-popap button {
  background-color: #F8F9FA;
  width: 100%;
}

.account-popap .register-popap button a {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 0;
  border: 1px solid black;
  width: 100%;
}

.account-popap .register-popap button:hover {
  transform: scale(1.07);
}

.account-popap a {
  color: #121212;
  text-decoration: none;
}

.account-popap.active {
  top: 100%;
}

.account-popap.loged {
  display: none;
}

.loged-account-popap {
  display: block;
  width: 350px;
  background-color: #F8F9FA;
  position: absolute;
  top: -350px;
  transition: 1s;
  right: 0;
  z-index: 5;
}

.loged-account-popap .loged-box {
  width: 100%;
  height: 100%;
  padding: 30px 50px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

.loged-account-popap .loged-box p {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.loged-account-popap .loged-box p span {
  font-weight: 500;
  width: 100%;
}

.loged-account-popap .loged-box a {
  width: 100%;
  padding: 5px;
  text-decoration: none;
  color: #121212;
}

.loged-account-popap .loged-box a img {
  padding-right: 20px;
}

.loged-account-popap .loged-box a:hover {
  transform: scale(1.07);
  cursor: pointer;
}

.loged-account-popap.active {
  top: 64px;
}

.loged-account-popap.logout {
  display: none;
}

.login-container {
  width: 100%;
  height: calc(100vh - 84px);
  display: flex;
}

.login-container .login, .login-container .register {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container .login form, .login-container .register form {
  position: relative;
  width: 60%;
  height: 50%;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}

.login-container .login form h4, .login-container .register form h4 {
  line-height: 2;
  font-size: 20px;
  text-align: center;
  width: 100%;
  margin-bottom: 50px;
}

.login-container .login form input, .login-container .register form input {
  width: 100%;
  padding: 30px;
  margin-bottom: 10px;
  font-size: 16px;
}

.login-container .login form label, .login-container .register form label {
  width: 100%;
  position: relative;
}

.login-container .login form label img, .login-container .register form label img {
  position: absolute;
  top: calc(50% - 5px);
  left: 90%;
  transform: translate(-50%, -50%);
}

.login-container .login form label .hide, .login-container .register form label .hide {
  display: none;
}

.login-container .login form label img:hover, .login-container .register form label img:hover {
  cursor: pointer;
  opacity: 0.7;
}

.login-container .login form button, .login-container .register form button {
  width: 100%;
  background-color: #121212;
  color: white;
  line-height: 1;
  text-align: center;
  margin: 30px 0;
  height: 64px;
}

.login-container .login form button a, .login-container .register form button a {
  text-decoration: none;
  display: inline;
  color: white;
}

.login-container .login form button:hover, .login-container .register form button:hover {
  font-size: 20px;
}

.login-container .login form a, .login-container .register form a {
  color: #121212;
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 10px;
  transition: 0.3s;
}

.login-container .login form a:hover, .login-container .register form a:hover {
  text-decoration: none;
}

.login-container .login form .content-adverb, .login-container .register form .content-adverb {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  width: 320px;
}

.login-container .login form .content-adverb p, .login-container .register form .content-adverb p {
  margin-bottom: 25px;
}

.login-container .register {
  border: 1px solid black;
  border-bottom: none;
  border-right: none;
}

.login-container .register form {
  overflow: hidden;
}

.login-container .register form a {
  margin-top: 0;
  display: block;
  width: 100%;
  background-color: #121212;
  color: white;
  padding: 20px;
  margin-bottom: 50px;
  text-decoration: none;
}

.login-container .register form a:hover {
  transform: scale(1.07);
}

.register-container {
  width: 100%;
  height: 100vh;
  display: flex;
}

.register-container div.login, .register-container div.register {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.register-container div.login #login-form,
.register-container div.login #register-form, .register-container div.register #login-form,
.register-container div.register #register-form {
  position: relative;
  width: 60%;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}

.register-container div.login #login-form h4,
.register-container div.login #register-form h4, .register-container div.register #login-form h4,
.register-container div.register #register-form h4 {
  line-height: 2;
  font-size: 20px;
  text-align: center;
  width: 100%;
  margin-bottom: 50px;
}

.register-container div.login #login-form input,
.register-container div.login #register-form input, .register-container div.register #login-form input,
.register-container div.register #register-form input {
  width: 100%;
  padding: 30px;
  margin-bottom: 10px;
  font-size: 16px;
  border: 1px solid #767676;
}

.register-container div.login #login-form label,
.register-container div.login #register-form label, .register-container div.register #login-form label,
.register-container div.register #register-form label {
  width: 100%;
  position: relative;
}

.register-container div.login #login-form label img,
.register-container div.login #register-form label img, .register-container div.register #login-form label img,
.register-container div.register #register-form label img {
  position: absolute;
  left: 90%;
  transform: translate(-50%, -50%);
  top: calc(50% - 5px);
}

.register-container div.login #login-form label .hide,
.register-container div.login #register-form label .hide, .register-container div.register #login-form label .hide,
.register-container div.register #register-form label .hide {
  display: none;
}

.register-container div.login #login-form label img:hover,
.register-container div.login #register-form label img:hover, .register-container div.register #login-form label img:hover,
.register-container div.register #register-form label img:hover {
  opacity: 0.7;
  cursor: pointer;
}

.register-container div.login #login-form button,
.register-container div.login #register-form button, .register-container div.register #login-form button,
.register-container div.register #register-form button {
  width: 100%;
  background-color: #121212;
  color: white;
  line-height: 1;
  text-align: center;
  margin: 30px 0;
  height: 64px;
}

.register-container div.login #login-form button a,
.register-container div.login #register-form button a, .register-container div.register #login-form button a,
.register-container div.register #register-form button a {
  text-decoration: none;
  display: inline;
  color: white;
}

.register-container div.login #login-form button:hover,
.register-container div.login #register-form button:hover, .register-container div.register #login-form button:hover,
.register-container div.register #register-form button:hover {
  font-size: 20px;
}

.register-container div.login #login-form .login-button,
.register-container div.login #register-form .login-button, .register-container div.register #login-form .login-button,
.register-container div.register #register-form .login-button {
  background-color: white;
  border: 1px solid black;
}

.register-container div.login #login-form .login-button a,
.register-container div.login #register-form .login-button a, .register-container div.register #login-form .login-button a,
.register-container div.register #register-form .login-button a {
  color: #121212;
  font-weight: 500;
}

.register-container div.login #login-form .register-button,
.register-container div.login #register-form .register-button, .register-container div.register #login-form .register-button,
.register-container div.register #register-form .register-button {
  margin-top: 0;
}

.register-container div.login #login-form .date-id,
.register-container div.login #register-form .date-id, .register-container div.register #login-form .date-id,
.register-container div.register #register-form .date-id {
  display: flex;
}

.register-container div.login #login-form .date-id #name,
.register-container div.login #register-form .date-id #name, .register-container div.register #login-form .date-id #name,
.register-container div.register #register-form .date-id #name {
  margin-right: 5px;
}

.register-container div.login #login-form .date-id #surname,
.register-container div.login #register-form .date-id #surname, .register-container div.register #login-form .date-id #surname,
.register-container div.register #register-form .date-id #surname {
  margin-left: 5px;
}

.register-container div.login #login-form .permission,
.register-container div.login #register-form .permission, .register-container div.register #login-form .permission,
.register-container div.register #register-form .permission {
  font-size: 14px;
  line-height: 1.43;
  display: flex;
  margin: 30px 10px 10px 0;
}

.register-container div.login #login-form .permission .ur-checkbox-list,
.register-container div.login #register-form .permission .ur-checkbox-list, .register-container div.register #login-form .permission .ur-checkbox-list,
.register-container div.register #register-form .permission .ur-checkbox-list {
  display: flex;
}

.register-container div.login #login-form .permission input,
.register-container div.login #register-form .permission input, .register-container div.register #login-form .permission input,
.register-container div.register #register-form .permission input {
  width: 40px;
  height: 25px;
  display: flex;
  margin-right: 10px;
  align-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
}

.register-container div.login #login-form .permission p,
.register-container div.login #register-form .permission p, .register-container div.register #login-form .permission p,
.register-container div.register #register-form .permission p {
  text-align: left;
}

.register-container div.login #login-form .permission span,
.register-container div.login #register-form .permission span, .register-container div.register #login-form .permission span,
.register-container div.register #register-form .permission span {
  color: lightgray;
  opacity: 1;
}

.register-container div.login #login-form p,
.register-container div.login #register-form p, .register-container div.register #login-form p,
.register-container div.register #register-form p {
  text-align: center;
  line-height: 1.3;
}

.register-container div.login #login-form p a,
.register-container div.login #register-form p a, .register-container div.register #login-form p a,
.register-container div.register #register-form p a {
  color: #121212;
}

.register-container div.login #login-form p a:hover,
.register-container div.login #register-form p a:hover, .register-container div.register #login-form p a:hover,
.register-container div.register #register-form p a:hover {
  font-size: 18px;
}

.register-container div.register {
  border: 1px solid black;
  border-bottom: none;
  border-right: none;
}

.message {
  width: 100%;
}

.message .container {
  display: flex;
  justify-content: center;
  height: 100%;
}

.message form {
  margin: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 450px;
}

.message form h4 {
  width: 100%;
  margin: 0 0 42.5px 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 2;
}

.message form label {
  display: flex;
  width: 100%;
}

.message form label input {
  max-width: 100%;
}

.message form br {
  display: none;
}

.message form input {
  padding: 30px;
  margin: 7.5px;
  width: 100%;
}

.message form input::placeholder {
  color: #121212;
  font-weight: 500;
}

.message form #message-subject::placeholder {
  color: lightgray;
  opacity: 0.7;
}

.message form textarea {
  margin: 7.5px;
  padding: 30px;
  width: 100%;
}

.message form textarea::placeholder {
  opacity: 0.7;
  color: lightgray;
  font-weight: 500;
}

.message form textarea:focus::placeholder {
  opacity: 1;
  color: #121212;
}

.message form .permission {
  display: flex;
  margin: 17.5px 7.5px;
}

.message form .permission input {
  margin: 0;
  margin-right: 10px;
  align-self: flex-start;
  width: 40px;
  height: 40px;
}

.message form .permission input:hover {
  cursor: pointer;
}

.message form .permission p {
  font-size: 14px;
  margin-bottom: 0;
}

.message form .permission p a {
  font-weight: 500;
  color: #121212;
}

.message form .permission p a:hover {
  text-decoration: none;
  cursor: pointer;
}

.message form input[type="submit"],
.message form button {
  margin: 7.5px;
  background-color: #121212;
  width: 100%;
  padding: 24px;
  color: white;
  border: none;
}

.message form input[type="submit"]:hover,
.message form button:hover {
  transform: scale(1.07);
}

.shop-all .container .panel-path {
  padding: 25px 350px 0;
}

.shop-all .container .row {
  padding: 0 350px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.shop-all .container .row .box {
  padding: 0 15px;
  margin-bottom: 60px;
  width: 100%;
}

.shop-all .container .row .box .img-content {
  text-align: center;
  width: auto;
  overflow: hidden;
}

.shop-all .container .row .box .img-content img {
  object-fit: cover;
  width: 100%;
}

.shop-all .container .row .box .img-content img:hover {
  cursor: pointer;
}

.shop-all .container .row .box .text-content {
  text-align: center;
  padding-top: 3%;
  width: 100%;
}

.shop-all a {
  font-size: 16px;
  width: 100%;
  display: block;
  line-height: 1.25;
  transition: 0.6s;
  color: #121212;
  text-decoration: none;
}

.shop-all a .product-price {
  padding-top: 5px;
  display: block;
  font-weight: 400;
}

.shop-all a .product-price .old-price {
  font-size: 14px;
  font-weight: 500;
  padding-left: 5px;
  line-height: 1;
  text-align: center;
  color: #ccc;
  text-decoration: line-through;
}

.shop-all a .new-price {
  font-size: 16px;
  color: #bf3939;
  padding-left: 50px;
}

.shop-all .shop-all-header {
  padding: 25px 0 50px;
  margin: 0;
  font-size: 28px;
  font-weight: 400;
  width: 100%;
  text-align: center;
}

.shop-all .blocks-nav {
  width: 100%;
  justify-content: center;
  display: flex;
  margin-bottom: 60px;
}

.shop-all .blocks-nav button {
  height: 40px;
  width: 40px;
  background-color: white;
  margin: 20px;
  border: none;
  color: #121212;
}

.shop-all .blocks-nav button img {
  object-fit: contain;
}

.shop-all .blocks-nav button.active,
.shop-all .blocks-nav button:hover {
  background-color: #121212;
  color: white;
}

.shop-all .blocks-nav .prev-block {
  transform: rotateY(180deg);
}

.shop-all .blocks-nav .prev-block:hover {
  transform: scale(1.07) rotateY(180deg);
  background-color: white;
}

.shop-all .blocks-nav .next-block:hover {
  background-color: white;
}

.about .container .about-header {
  font-size: 20px;
  line-height: 1.63;
  font-weight: 300;
  text-align: center;
  width: 100%;
  padding: 100px 40px;
}

.about .container .about-header.bottom {
  padding-bottom: 50px;
}

.about .container .ceo {
  margin: 0 10%;
}

.about .container .ceo .col-12 {
  display: flex;
  justify-content: center;
}

.about .container .ceo .col-12 .box {
  max-width: 423px;
  width: 50%;
}

.about .container .ceo .col-12 .box .img-content {
  width: 100%;
}

.about .container .ceo .col-12 .box .img-content img {
  width: 100%;
}

.about .container .ceo .col-12 .box .text-content {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  justify-content: center;
}

.about .container .ceo .col-12 .box .text-content span {
  text-align: center;
  padding-bottom: 5px;
  font-weight: 500;
  width: 100%;
}

.about .container .stylish-everyday {
  margin: 80px 10%;
  justify-content: space-between;
}

.about .container .stylish-everyday .img-content {
  display: flex;
  align-items: center;
  height: 100%;
}

.about .container .stylish-everyday .img-content img {
  width: 100%;
}

.about .container .stylish-everyday .text-content {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 10px 0 10px 30px;
  justify-content: left;
  align-content: center;
  flex-wrap: wrap;
}

.about .container .stylish-everyday .text-content h4 {
  text-align: left;
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: normal;
}

.about .container .stylish-everyday .text-content p {
  text-align: left;
  font-size: 14px;
  line-height: 1.43;
}

.about .container .stylish-everyday a {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  color: #121212;
  width: 100%;
  text-align: center;
}

.about .container .stylish-everyday a:hover {
  text-decoration: none;
}

.panel-path {
  display: flex;
  width: 100%;
  text-transform: uppercase;
  justify-content: start;
}

.panel-path a {
  font-size: 12px;
  font-weight: 500;
  width: auto;
  color: #ccc;
  text-decoration: none;
}

.panel-path a:hover {
  cursor: pointer;
  transform: scale(1.07);
}

.panel-path img {
  width: 40px;
  padding: 0 15px;
}

.my-data .container .row {
  width: 100%;
}

.my-data .container .row .account-nav {
  z-index: 1;
  justify-content: center;
  padding: 0;
}

.my-data .container .row .account-nav .data {
  max-width: 355px;
  background-color: white;
  height: auto;
  padding: 100px 0;
  position: sticky;
  top: 50px;
  display: flex;
  align-items: center;
  border: 1px solid black;
  border-left: none;
}

.my-data .container .row .account-nav .data p {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  margin: 0 0 0 40%;
  height: auto;
  font-size: 14px;
  font-weight: 500;
}

.my-data .container .row .account-nav .data p a {
  width: 100%;
  display: block;
  color: #121212;
  margin: 5px 0 5px 40px;
  text-decoration: none;
}

.my-data .container .row .account-nav .data p a:hover {
  transform: scale(1.07);
}

.my-data .container .row .account-nav .data .account-nav-btn {
  display: none;
}

.my-data .container .row .panel {
  padding: 50px 0 50px 50px;
  position: relative;
  min-height: 70vh;
}

.my-data .container .row .panel .panel-path {
  display: flex;
  width: 100%;
}

.my-data .container .row .panel .panel-path a {
  font-size: 12px;
  font-weight: 500;
  color: #ccc;
  text-decoration: none;
}

.my-data .container .row .panel .panel-path a:hover {
  cursor: pointer;
  transform: scale(1.07);
}

.my-data .container .row .panel .panel-path img {
  width: 40px;
  padding: 0 15px;
}

.my-data .container .row .panel .panel-header {
  margin: 30px 0 100px 0;
  font-size: 28px;
  font-weight: 400;
}

.my-data .container .row .panel .panel-header.orders {
  margin: 30px 0 50px 0;
}

.my-data .container .row .panel .panel-header.order-details {
  margin-bottom: 25px;
}

.my-data .container .row .panel .order-stats p {
  font-size: 14px;
  line-height: 1.14;
  margin-bottom: 5px;
}

.my-data .container .row .panel .order-stats p span {
  font-weight: 600;
}

.my-data .container .row .panel .return-addres h5 {
  font-size: 20px;
  line-height: 1.2;
  font-weight: normal;
  margin: 80px 0 30px;
}

.my-data .container .row .panel .return-addres p {
  font-size: 14px;
  margin: 0;
}

.my-data .container .row .panel .return-addres .bold {
  font-weight: 500;
}

.my-data .container .row .panel .return-addres .uppercase {
  text-transform: uppercase;
  line-height: 1.7;
  margin-bottom: 15px;
}

.my-data .container .row .panel form {
  width: 100%;
}

.my-data .container .row .panel form label {
  width: 70%;
}

.my-data .container .row .panel form .flex-label {
  width: 70%;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.my-data .container .row .panel form .flex-label .password-input {
  display: block;
  flex-grow: 1;
  max-width: calc(50% - 15px);
  margin-right: 15px;
}

.my-data .container .row .panel form .flex-label .password-input input {
  max-width: 100%;
  width: 100%;
  margin-right: 0;
}

.my-data .container .row .panel form .flex-label input {
  flex-grow: 1;
  max-width: calc(50% - 15px);
  padding: 11px;
  margin-right: 15px;
}

.my-data .container .row .panel form .checkbox-container {
  width: auto;
  display: block;
  position: relative;
  margin: 0;
  padding-left: 35px;
  cursor: pointer;
  margin-right: 50px;
}

.my-data .container .row .panel form .checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.my-data .container .row .panel form .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border: 1px solid black;
}

.my-data .container .row .panel form .checkbox-container input:checked ~ .checkmark {
  background-color: #121212;
}

.my-data .container .row .panel form .checkmark:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

.my-data .container .row .panel form .checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.my-data .container .row .panel form .checkbox-container .checkmark:after {
  top: 1px;
  left: 1px;
  width: 21px;
  height: 21px;
  background: black;
  border: 2px solid white;
}

.my-data .container .row .panel form .adres {
  width: 70%;
  height: auto;
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.my-data .container .row .panel form .adres .resp {
  margin-right: 30px;
}

.my-data .container .row .panel form .adres .cont {
  border: none;
  flex-wrap: nowrap;
  align-items: flex-start;
}

.my-data .container .row .panel form .adres .cont label {
  margin-right: 10px;
}

.my-data .container .row .panel form .adres .cont label .checkmark {
  top: 55%;
}

.my-data .container .row .panel form .adres .cont .adres-data {
  margin-left: 40px;
}

.my-data .container .row .panel form .adres .cont .adres-data .adres-name {
  margin-left: 0;
}

.my-data .container .row .panel form .adres .adres-name {
  font-size: 16px;
  margin: 0;
  font-weight: 500;
  text-decoration: none;
  color: #121212;
  display: inline;
  padding-left: 0;
  margin-left: 40px;
}

.my-data .container .row .panel form .invoice .cont {
  margin: 15px 0;
  border: none;
}

.my-data .container .row .panel form .add-adres {
  margin-top: 15px;
}

.my-data .container .row .panel form .add-adres label {
  margin-bottom: 15px;
}

.my-data .container .row .panel form .add-adres label input {
  margin-right: 15px;
  max-width: calc(50% - 15px);
  flex-grow: 1;
}

.my-data .container .row .panel form .add-adres input {
  padding: 11px;
}

.my-data .container .row .panel form .invoice-dropdown.invisible {
  height: 0;
  overflow: hidden;
  margin: 0 !important;
}

.my-data .container .row .panel form .invoice-dropdown.visible {
  width: 100%;
  overflow: hidden;
  height: auto;
  margin-top: 15px;
}

.my-data .container .row .panel form .invoice-dropdown.visible label {
  margin-bottom: 15px;
}

.my-data .container .row .panel form .invoice-dropdown.visible input {
  padding: 11px;
  flex-grow: 1;
  margin-right: 15px;
}

.my-data .container .row .panel form .invoice.invisible {
  height: 0;
  overflow: hidden;
  margin: 0;
}

.my-data .container .row .panel form .gender, .my-data .container .row .panel form .customer {
  display: flex;
  flex-wrap: wrap;
  width: 70%;
  margin-left: 2px;
  margin-bottom: 30px;
  justify-content: flex-start;
}

.my-data .container .row .panel form .gender .checkbox-container, .my-data .container .row .panel form .customer .checkbox-container {
  width: 60px;
  display: block;
  position: relative;
  margin: 0;
  padding-left: 35px;
  cursor: pointer;
  margin-right: 50px;
}

.my-data .container .row .panel form .gender .checkbox-container input, .my-data .container .row .panel form .customer .checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.my-data .container .row .panel form .gender .checkmark, .my-data .container .row .panel form .customer .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border: 1px solid black;
}

.my-data .container .row .panel form .gender .checkbox-container input:checked ~ .checkmark, .my-data .container .row .panel form .customer .checkbox-container input:checked ~ .checkmark {
  background-color: #121212;
}

.my-data .container .row .panel form .gender .checkmark:after, .my-data .container .row .panel form .customer .checkmark:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

.my-data .container .row .panel form .gender .checkbox-container input:checked ~ .checkmark:after, .my-data .container .row .panel form .customer .checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.my-data .container .row .panel form .gender .checkbox-container .checkmark:after, .my-data .container .row .panel form .customer .checkbox-container .checkmark:after {
  top: 1px;
  left: 1px;
  width: 21px;
  height: 21px;
  background: black;
  border: 2px solid white;
}

.my-data .container .row .panel form .customer {
  margin-left: 2px;
}

.my-data .container .row .panel form .customer .checkbox-container {
  width: auto;
}

.my-data .container .row .panel form .newsletter {
  width: 100%;
  padding: 50px 100px 40px 0;
  margin-bottom: 50px;
}

.my-data .container .row .panel form .newsletter p {
  margin-bottom: 15px;
  margin-top: 0;
}

.my-data .container .row .panel form .newsletter label {
  width: 100%;
  font-size: 14px;
  line-height: 1.43;
}

.my-data .container .row .panel form .newsletter label input {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  border: 3px solid black;
}

.my-data .container .row .panel form .newsletter label input:checked {
  accent-color: #121212;
}

.my-data .container .row .panel form .newsletter div {
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 50px;
  justify-content: flex-end;
}

.my-data .container .row .panel form .newsletter div button {
  background-color: #121212;
  padding: 15px 150px;
  color: white;
  position: absolute;
}

.my-data .container .row .panel form .newsletter div button:hover {
  transform: scale(1.07) translateX(-10%);
}

.my-data .container .row .panel form .mt {
  margin-top: 50px;
}

.my-data .container .row .panel form .mb {
  margin-bottom: 49px;
}

.my-data .container .row .panel form p {
  font-size: 20px;
  font-weight: 500;
  line-height: 2;
  margin: 30px 0 40px 0;
}

.my-data .container .row .panel form .remove {
  margin-bottom: 15px;
}

.my-data .container .row .panel form a {
  color: #121212;
}

.my-data .container .row .panel form a:hover {
  text-decoration: none;
}

.my-data .container .row .panel .btn-container {
  width: 100%;
  height: 50px;
  position: relative;
  margin: 50px 0;
}

.my-data .container .row .panel .btn-container button {
  background-color: #121212;
  padding: 15px 150px;
  color: white;
  position: absolute;
  left: 50%;
}

.my-data .container .row .panel .btn-container button:hover {
  transform: scale(1.07) translateX(-10%);
}

.my-data .container .row .panel .returns-content {
  text-align: center;
  line-height: 1;
  padding-right: 100px;
  margin-bottom: 100px;
  font-size: 20px;
}

.my-data .container .row .panel .returns-content img {
  width: 55px;
  height: 55px;
}

.my-data .container .row .panel .returns-content .price {
  font-weight: 600;
  line-height: 0.8;
  margin-top: 50px;
  padding-bottom: 0;
}

.my-data .container .row .panel .returns-content p {
  padding-bottom: 10px;
}

.my-data .container .row .panel .returns-content a {
  font-size: 16px;
  font-weight: 500;
  color: #121212;
}

.my-data .container .row .panel .returns-content a:hover {
  text-decoration: none;
}

.my-data .container .row .panel .order-row {
  padding: 40px 0 40px 0;
  margin-right: 100px;
  border-bottom: 1px solid lightgray;
  height: 300px;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.my-data .container .row .panel .order-row .order {
  height: 100%;
}

.my-data .container .row .panel .order-row .order .order-number {
  width: 100%;
  height: 22%;
  display: flex;
  flex-wrap: wrap;
}

.my-data .container .row .panel .order-row .order .order-number p {
  width: 100%;
  font-size: 16px;
  margin: auto;
}

.my-data .container .row .panel .order-row .order .order-number .price {
  font-weight: 400;
}

.my-data .container .row .panel .order-row .order .order-items {
  height: 78%;
  width: 100%;
  position: relative;
  padding-top: 10px;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  flex-wrap: nowrap;
}

.my-data .container .row .panel .order-row .order .order-items .box-img {
  margin-right: 15px;
  width: auto;
  overflow: hidden;
  height: 100% !important;
}

.my-data .container .row .panel .order-row .order .order-items .box-img img {
  height: 100% !important;
  width: 100%;
  object-fit: cover;
}

.my-data .container .row .panel .order-row .order .order-items .box-img a:hover img {
  transform: scale(1.07);
}

.my-data .container .row .panel .order-row .order .order-items .box-img:last-of-type {
  margin-right: 0;
}

.my-data .container .row .panel .order-row .order-descriptions {
  height: 100%;
  padding-left: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-content: space-between;
}

.my-data .container .row .panel .order-row .order-descriptions .delivery {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.my-data .container .row .panel .order-row .order-descriptions .delivery span {
  width: 100%;
  margin-bottom: 10px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.my-data .container .row .panel .order-row .order-descriptions .delivery span img {
  height: 100%;
  margin-right: 10px;
}

.my-data .container .row .panel .order-row .order-descriptions .delivery p {
  text-align: right;
  width: 100%;
}

.my-data .container .row .panel .order-row .order-descriptions .delivery p span {
  padding-left: 5px;
  display: inline;
  font-weight: normal;
}

.my-data .container .row .panel .order-row .order-descriptions a {
  color: #121212;
  text-align: right;
  font-weight: 500;
}

.my-data .container .row .panel .order-row .order-descriptions a:hover {
  text-decoration: none;
  cursor: pointer;
}

.my-data .container .row .panel .order-row .order-content {
  display: flex;
  flex-wrap: nowrap;
}

.my-data .container .row .panel .order-row .order-content a {
  height: 100%;
  overflow: hidden;
}

.my-data .container .row .panel .order-row .order-content a img {
  height: 100%;
}

.my-data .container .row .panel .order-row .order-content a:hover {
  cursor: pointer;
}

.my-data .container .row .panel .order-row .order-content a:hover img {
  transform: scale(1.07);
}

.my-data .container .row .panel .order-row .order-content .order-description {
  padding-left: 20px;
  padding-top: 10px;
}

.my-data .container .row .panel .order-row .order-content .order-description a {
  color: #121212;
  text-decoration: none;
}

.my-data .container .row .panel .order-row .order-content .order-description a:hover {
  cursor: pointer;
  text-decoration: underline;
}

.my-data .container .row .panel .order-row .order-content p {
  display: flex;
  flex-wrap: wrap;
  line-height: 1.25;
}

.my-data .container .row .panel .order-row .order-content p span {
  width: 100%;
}

.my-data .container .row .panel .order-row .order-content .order-name {
  line-height: 1.5;
  text-transform: uppercase;
}

.my-data .container .row .panel .order-row .order-value {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding-top: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
}

.my-data .container .row .panel .order-row .uppercase {
  text-transform: uppercase;
}

.my-data .container .row .panel .summary {
  justify-content: space-between;
  height: auto;
}

.my-data .container .row .panel .summary h4 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: normal;
}

.my-data .container .row .panel .summary h4 span {
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
}

.my-data .container .row .panel .summary .summary-data h4 {
  margin-top: 8px;
  margin-bottom: 40px;
  font-size: 16px;
  font-weight: normal;
}

.my-data .container .row .panel .summary .summary-data span {
  font-weight: 500;
}

.my-data .container .row .panel .summary .summary-data p {
  font-size: 14px;
}

.my-data .container .row .panel .summary .summary-data p:last-child {
  margin-bottom: 0;
}

.my-data .container .row .panel .summary .summary-price h4, .my-data .container .row .panel .summary .summary-price h5 {
  display: flex;
  justify-content: space-between;
  font-weight: normal;
}

.my-data .container .row .panel .summary .summary-price h4 span, .my-data .container .row .panel .summary .summary-price h5 span {
  font-weight: 400;
}

.my-data .container .row .panel .summary .summary-price h5 {
  margin: 8px 0;
  font-size: 16px;
  line-height: 1.25;
}

.my-data .container .row .panel .summary .summary-price h4 {
  font-size: 20px;
  margin: 12px 0 10px;
}

.my-data .container .row .panel .return-btn-container {
  justify-content: flex-end;
  height: auto;
  margin-bottom: 20px;
}

.my-data .container .row .panel .return-btn-container button {
  border: 1px solid black;
  font-weight: 500;
  background-color: white;
  font-size: 16px;
}

.my-data .container .row .panel .return-btn-container button a {
  padding: 14px 100px;
  display: block;
  width: 100%;
  height: 100%;
  color: #121212;
  text-decoration: none;
}

.my-data .container .row .panel .return-btn-container button a:hover {
  cursor: pointer;
}

.my-data .container .row .panel .return-btn-container button:hover {
  transform: scale(1.07);
}

.my-data .container .row .panel .order-row:last-of-type {
  border: none;
}

.my-data .container .row .panel .order-row.return {
  height: auto;
}

.my-data .container .row .panel .returns-instruction-btn {
  position: absolute;
  top: 105px;
  right: 100px;
  font-size: 16px;
  font-weight: 500;
  color: #121212;
}

.my-data .container .row .panel .returns-instruction-btn:hover {
  text-decoration: none;
}

.my-data .container .row .panel .see-more {
  border: none;
  margin-left: 20%;
  margin-top: 50px;
  background-color: #121212;
  color: white;
  padding: 15px 150px;
}

.my-data .container .row .panel .see-more:hover {
  transform: scale(1.07);
  cursor: pointer;
}

.my-data .container .row .current {
  text-transform: uppercase;
}

.my-data .container .instruction-content {
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 35px 50px;
  transform: translate(-50%, -50%);
  z-index: 16;
  width: 570px;
  max-height: 750px;
  height: auto;
  background-color: white;
  display: none;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.my-data .container .instruction-content button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
}

.my-data .container .instruction-content button img {
  width: 15px;
  height: 15px;
}

.my-data .container .instruction-content button:hover {
  cursor: pointer;
}

.my-data .container .instruction-content button:hover img {
  transform: rotate(90deg);
}

.my-data .container .instruction-content h4 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.7;
  margin: 0;
  text-align: center;
  padding-bottom: 15px;
}

.my-data .container .instruction-content h5 {
  font-size: 16px;
  font-weight: 500;
  padding: 35px 0 15px;
  margin: 0;
  text-align: center;
}

.my-data .container .instruction-content p {
  font-size: 14px;
  line-height: 1.79;
  margin: 0;
  padding: 5px 0 10px;
  text-align: center;
}

.my-data .container .instruction-content p span {
  font-weight: 500;
  text-align: center;
}

.my-data .container .instruction-content .return-email {
  padding-bottom: 5px;
}

.my-data .container .instruction-content a {
  color: #121212;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 0 20px;
  margin: 0;
  text-align: center;
  width: 100%;
  display: block;
}

.my-data .container .instruction-content a:hover {
  cursor: pointer;
  text-decoration: none;
}

.my-data .container .instruction-content.active {
  display: block;
}

.shopping-cart .shopping {
  padding: 70px 140px 70px 210px;
  min-height: 60vh;
}

.shopping-cart .shopping .item-row {
  padding: 15px 0;
  border-bottom: 1px solid lightgray;
  display: flex;
  justify-content: space-between;
}

.shopping-cart .shopping .item-row:last-of-type {
  border-bottom: none;
}

.shopping-cart .shopping .item-content {
  display: flex;
}

.shopping-cart .shopping .item-content a {
  height: 100%;
  margin-right: 30px;
  max-width: 140px;
}

.shopping-cart .shopping .item-content a:hover img {
  transform: scale(1.07);
}

.shopping-cart .shopping .item-content img {
  max-width: 140px;
  margin-right: 15px;
  width: 140px;
  height: 140px;
  object-fit: cover;
}

.shopping-cart .shopping .item-content .item-description {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

.shopping-cart .shopping .item-content .item-description p {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.shopping-cart .shopping .item-content .item-description p span {
  width: 100%;
}

.shopping-cart .shopping .item-content .item-description .chose-amount {
  position: relative;
  width: 70px;
  height: 36px;
}

.shopping-cart .shopping .item-content .item-description .chose-amount select {
  max-height: 100px;
  position: absolute;
  width: 70px;
  padding: 5px 10px;
  -webkit-appearance: none;
  z-index: 4;
  background-color: transparent;
}

.shopping-cart .shopping .item-content .item-description .chose-amount select:hover {
  cursor: pointer;
}

.shopping-cart .shopping .item-content .item-description .chose-amount select:focus-visible {
  outline: none;
}

.shopping-cart .shopping .item-content .item-description .chose-amount::after {
  content: "";
  z-index: 3;
  background-image: url(../img/language.png);
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
}

.shopping-cart .shopping .item-content .item-name {
  width: 100%;
  padding-top: 8px;
  font-weight: normal;
}

.shopping-cart .shopping .item-content .item-name a {
  color: #121212;
  text-decoration: none;
}

.shopping-cart .shopping .item-content .item-name a:hover {
  text-decoration: underline;
  cursor: pointer;
}

.shopping-cart .shopping .item-value {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: flex-end;
}

.shopping-cart .shopping .item-value a {
  padding-top: 8px;
  background-color: transparent;
  display: flex;
  border: none;
  align-items: center;
  text-align: right;
}

.shopping-cart .shopping .item-value a:hover {
  transform: rotate(90deg);
  cursor: pointer;
}

.shopping-cart .shopping .item-value p {
  width: 100%;
  margin-bottom: 0;
  line-height: 1;
  text-align: right;
  font-weight: 400;
  font-size: 20px;
}

.shopping-cart .shopping .information {
  margin-top: 50px;
  padding: 50px;
  border: 1px solid black;
}

.shopping-cart .shopping .information h5 {
  font-size: 20px;
  font-weight: normal;
  line-height: 1.2;
  margin-bottom: 30px;
}

.shopping-cart .shopping .information p {
  font-size: 14px;
}

.shopping-cart .shopping .information p span {
  font-weight: 500;
}

.shopping-cart .shopping-card {
  border: 1px solid black;
  border-bottom: none;
  border-right: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  align-content: flex-start;
  padding-top: 30px;
}

.shopping-cart .shopping-card .payment {
  position: sticky;
  top: 30%;
  padding: 0px 100px 100px 40px;
  background-color: transparent;
  overflow: hidden;
  width: auto;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-transform: uppercase;
}

.shopping-cart .shopping-card .payment .total-price {
  width: 100%;
  font-weight: normal;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.shopping-cart .shopping-card .payment .total-price span {
  font-weight: 400;
}

.shopping-cart .shopping-card .payment form {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 20px 0;
  flex-grow: 0;
}

.shopping-cart .shopping-card .payment form input {
  width: 70%;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid lightgray;
  padding: 10px;
}

.shopping-cart .shopping-card .payment form input:focus-visible {
  outline: 0;
}

.shopping-cart .shopping-card .payment form button {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
  padding: 8px 20px;
}

.shopping-cart .shopping-card .payment form button:hover {
  background-color: #212529;
  color: white;
}

.shopping-cart .shopping-card .payment .payment-forms {
  width: 100%;
}

.shopping-cart .shopping-card .payment .payment-forms p {
  width: 100%;
  font-size: 16px;
}

.shopping-cart .shopping-card .payment .payment-forms a {
  padding-right: 10px;
  text-decoration: none;
}

.shopping-cart .shopping-card .payment .payment-forms a img {
  max-width: 70px;
  max-height: 30px;
}

.shopping-cart .shopping-card .payment .payment-forms a:hover img {
  transform: scale(1.07);
}

.shopping-cart .shopping-card .payment button {
  transition: 0.6s;
}

.shopping-cart .shopping-card .payment .btn-light {
  background-color: transparent;
  border: 1px solid black;
  border-radius: 0;
}

.shopping-cart .shopping-card .payment .btn-light:focus {
  box-shadow: none;
}

.shopping-cart .shopping-card .payment .btn-dark {
  margin: 20px 0;
  width: 100%;
  border-radius: 0;
  padding: 15px;
}

.shopping-cart .shopping-card .payment .btn-dark a {
  color: white;
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: block;
}

.shopping-cart .shopping-card .payment .btn-dark:focus {
  box-shadow: none;
}

.shopping-cart .shopping-card .payment .btn-dark:hover {
  transform: scale(1.07);
}

.shopping-cart .shopping-card.choices {
  height: 280px;
  position: fixed;
  border-bottom: 1px solid black;
  display: flex;
  align-items: flex-end;
  flex-wrap: nowrap;
  right: 0;
}

.shopping-cart .shopping-card.choices .payment {
  position: static;
  padding-bottom: 50px;
}

.shopping-cart .shopping-card.choices .payment button {
  border: none;
  background-color: #cccccc;
  color: white;
  border-radius: 0;
  margin: 20px 0;
  padding: 0;
  width: 100%;
}

.shopping-cart .shopping-card.choices .payment button a {
  padding: 13px 0;
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: #121212;
}

.shopping-cart .shopping-card.choices .payment .finish-return-btn {
  color: white;
  background-color: #121212;
  padding: 16px 0;
}

.shopping-cart .shopping-card.choices .payment .btn:focus {
  box-shadow: none;
}

.shopping-cart .shopping-card.choices .payment .btn:hover {
  transform: scale(1.07);
}

.shopping-cart .shopping-nav-btn {
  display: none;
  z-index: 13;
}

.shopping-cart h4 {
  font-size: 16px;
}

.shopping-cart h2 {
  font-size: 28px;
}

.shopping-cart .shoping-header {
  display: flex;
  flex-wrap: nowrap;
  font-weight: 400;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
}

.shopping-cart .shoping-header img:hover {
  cursor: pointer;
  transform: scale(1.07);
}

.choices .item-content {
  flex-grow: 1;
}

.choices .item-content img {
  max-width: 150px;
}

.choices .item-content .item-description {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.choices .item-content .item-description h4 {
  font-size: 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.choices .item-content .item-description h4 .price {
  font-weight: 400;
  width: auto;
}

.choices .item-content .item-description .chose-reason {
  position: relative;
  width: 70%;
  height: 58px;
}

.choices .item-content .item-description .chose-reason select {
  width: 100%;
  height: 100%;
  color: #ccc;
  border: 1px solid #ccc;
  position: absolute;
  -webkit-appearance: none;
  z-index: 4;
  background-color: transparent;
  padding: 10px;
}

.choices .item-content .item-description .chose-reason select option {
  color: #121212;
  padding: 10px;
}

.choices .item-content .item-description .chose-reason select:hover {
  cursor: pointer;
}

.choices .item-content .item-description .chose-reason select:focus-visible {
  outline: none;
}

.choices .item-content .item-description .chose-reason::after {
  content: "";
  z-index: 3;
  background-image: url(../img/arrow-gray.svg);
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
}

.choices .item-content .checkbox-btn {
  background: white;
  width: 29px;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 30px;
  border: none;
}

.choices .item-content .checkbox-btn input {
  display: none;
}

.choices .item-content .checkbox-btn img {
  height: 29px;
  margin: 0;
}

.choices .item-content .checkbox-btn img:hover {
  cursor: pointer;
}

.choices .item-content .checkbox-btn .hide {
  display: none;
}

.choices .item-content .checkbox-btn:hover {
  cursor: default;
}

.choices .select-all {
  border: none;
  display: flex;
  flex-wrap: nowrap;
  margin-top: 20px;
  align-items: center;
  background-color: transparent;
}

.choices .select-all img {
  margin-right: 10px;
  height: 29px;
}

.choices .select-all img:hover {
  cursor: pointer;
}

.choices .select-all .hide {
  display: none;
}

.choices .confirm-returned-info {
  position: absolute;
  z-index: 16;
  top: 50%;
  display: none;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: auto;
  padding: 50px;
}

.choices .confirm-returned-info .return-info-content {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.choices .confirm-returned-info h5 {
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  padding: 35px 10px 50px;
}

.choices .confirm-returned-info a {
  display: block;
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  color: #121212;
}

.choices .confirm-returned-info a:hover {
  text-decoration: none;
}

.choices .confirm-returned-info.active {
  display: block;
}

.empty-cart .row {
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid black;
}

.empty-cart .empty-content {
  padding: 140px 0;
  width: 420px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.empty-cart .empty-content img {
  margin-bottom: 40px;
}

.empty-cart .empty-content h2 {
  width: 100%;
  text-align: center;
  margin: 20px 0;
  font-size: 40px;
  line-height: 1;
}

.empty-cart .empty-content button {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  border: none;
  margin: 15px 0;
  background-color: transparent;
}

.empty-cart .empty-content button a {
  background-color: #121212;
  padding: 24px;
  color: white;
  text-decoration: none;
}

.empty-cart .empty-content button:hover {
  transform: scale(1.07);
}

.empty-cart .empty-content a {
  padding: 15px 0;
  display: block;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #121212;
}

.empty-cart .empty-content a:hover {
  text-decoration: none;
}

.blur {
  display: none;
}

.blur.active {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 14;
  width: 100%;
  height: 100%;
  background-color: rgba(18, 18, 18, 0.4);
  opacity: 1;
  display: block;
}

.newsletter2-popap,
.newsletter-popap,
.table-popup,
.product-popap {
  position: fixed;
  background-color: white;
  top: 50%;
  left: -200%;
  max-width: 100%;
}

.close-pp4,
.close-pp3,
.close-ps,
.close-pp {
  top: 10px;
  right: 10px;
  position: absolute;
  display: block;
  padding: 0;
  width: 10px;
  height: 10px;
  z-index: 100;
}

.close-pp4 img,
.close-pp3 img,
.close-ps img,
.close-pp img {
  width: 100%;
  height: 100%;
  vertical-align: text-top;
}

.close-ps:hover,
.close-pp:hover {
  cursor: pointer;
}

.close-ps:hover img,
.close-pp:hover img {
  transform: rotate(90deg);
}

.newsletter2-popap.active,
.newsletter-popap.active,
.table-popup.active,
.product-popap.active {
  max-width: 580px;
  z-index: 15;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.newsletter2-popap.active .product-popap-content,
.newsletter-popap.active .product-popap-content,
.table-popup.active .product-popap-content,
.product-popap.active .product-popap-content {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  padding: 50px;
}

.newsletter2-popap.active .product-popap-content .product-describe,
.newsletter-popap.active .product-popap-content .product-describe,
.table-popup.active .product-popap-content .product-describe,
.product-popap.active .product-popap-content .product-describe {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  padding: 20px 0;
  justify-content: start;
}

.newsletter2-popap.active .product-popap-content .product-describe .box-img,
.newsletter-popap.active .product-popap-content .product-describe .box-img,
.table-popup.active .product-popap-content .product-describe .box-img,
.product-popap.active .product-popap-content .product-describe .box-img {
  max-width: 190px;
  max-height: 190px;
}

.newsletter2-popap.active .product-popap-content .product-describe .box-content,
.newsletter-popap.active .product-popap-content .product-describe .box-content,
.table-popup.active .product-popap-content .product-describe .box-content,
.product-popap.active .product-popap-content .product-describe .box-content {
  padding-left: 20px;
}

.newsletter2-popap.active .product-popap-content .product-describe .box-content p,
.newsletter-popap.active .product-popap-content .product-describe .box-content p,
.table-popup.active .product-popap-content .product-describe .box-content p,
.product-popap.active .product-popap-content .product-describe .box-content p {
  font-size: 14px;
  line-height: 1.43;
  margin-bottom: 5px;
}

.newsletter2-popap.active .product-popap-content .product-describe .box-content span,
.newsletter-popap.active .product-popap-content .product-describe .box-content span,
.table-popup.active .product-popap-content .product-describe .box-content span,
.product-popap.active .product-popap-content .product-describe .box-content span {
  line-height: 1.5;
  font-weight: 400;
  font-size: 16px;
}

.newsletter2-popap.active .product-popap-content .product-describe h4,
.newsletter-popap.active .product-popap-content .product-describe h4,
.table-popup.active .product-popap-content .product-describe h4,
.product-popap.active .product-popap-content .product-describe h4 {
  font-size: 16px;
  line-height: 1.5;
}

.newsletter2-popap.active .product-popap-content a,
.newsletter-popap.active .product-popap-content a,
.table-popup.active .product-popap-content a,
.product-popap.active .product-popap-content a {
  width: 100%;
  text-align: center;
  color: #121212;
  padding: 20px 0;
}

.newsletter2-popap.active .product-popap-content a button,
.newsletter-popap.active .product-popap-content a button,
.table-popup.active .product-popap-content a button,
.product-popap.active .product-popap-content a button {
  padding: 24px;
  width: 100%;
  border: none;
  background-color: #121212;
  color: white;
}

.newsletter2-popap.active .product-popap-content a button:hover,
.newsletter-popap.active .product-popap-content a button:hover,
.table-popup.active .product-popap-content a button:hover,
.product-popap.active .product-popap-content a button:hover {
  transform: scale(1.07);
}

.newsletter2-popap.active .product-popap-content a:hover,
.newsletter-popap.active .product-popap-content a:hover,
.table-popup.active .product-popap-content a:hover,
.product-popap.active .product-popap-content a:hover {
  text-decoration: none;
  cursor: pointer;
}

.newsletter2-popap.active .done,
.newsletter-popap.active .done,
.table-popup.active .done,
.product-popap.active .done {
  width: 100%;
  text-align: center;
}

.newsletter2-popap.active .done img,
.newsletter-popap.active .done img,
.table-popup.active .done img,
.product-popap.active .done img {
  width: 25px;
  height: 25px;
}

.newsletter2-popap.active .box-img img,
.newsletter-popap.active .box-img img,
.table-popup.active .box-img img,
.product-popap.active .box-img img {
  height: 100%;
  object-fit: cover;
}

.newsletter2-popap.active h3,
.newsletter-popap.active h3,
.table-popup.active h3,
.product-popap.active h3 {
  display: block;
  text-align: center;
  line-height: 1.7;
  padding: 20px 0;
  margin: 0;
  font-size: 20px;
  font-weight: 500;
}

.table-popup.active {
  max-width: 100%;
}

.delivery-popup {
  top: 50%;
  left: -600px;
  transform: translate(-50%, -50%);
  width: 500px;
  z-index: 15;
  max-width: 100%;
  background-color: white;
  text-align: center;
}

.delivery-popup a {
  text-decoration: underline;
  color: black;
}

.delivery-popup a:hover {
  text-decoration: underline;
}

.delivery-popup p {
  font-weight: 500;
}

.delivery-popup button {
  border: none;
  background-color: transparent;
  right: 25px;
  top: 25px;
}

.delivery-popup button:hover {
  cursor: pointer;
}

.delivery-popup button:hover img {
  transform: rotate(90deg);
}

.delivery-popup.active {
  left: 50%;
}

.payment-popup {
  top: 50%;
  left: -600px;
  transform: translate(-50%, -50%);
  width: 500px;
  z-index: 15;
  max-width: 100%;
  background-color: white;
  text-align: center;
}

.payment-popup a {
  text-decoration: underline;
  color: black;
}

.payment-popup a:hover {
  text-decoration: underline;
}

.payment-popup p {
  font-weight: 500;
}

.payment-popup button {
  border: none;
  background-color: transparent;
  right: 25px;
  top: 25px;
}

.payment-popup button:hover {
  cursor: pointer;
}

.payment-popup button:hover img {
  transform: rotate(90deg);
}

.payment-popup.active {
  left: 50%;
}

.showroom .row {
  margin: 50px 18%;
}

.showroom .row .showroom-content {
  padding: 0 70px;
  margin: 50px 0;
}

.showroom .row .showroom-content .border-div {
  display: flex;
  border: 1px solid black;
}

.showroom .row .showroom-content .border-div .text-content {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-left: 5%;
}

.showroom .row .showroom-content .border-div .text-content h4 {
  width: 100%;
  font-size: 40px;
  margin-bottom: 20px;
}

.showroom .row .showroom-content .border-div .text-content h4 p {
  font-size: 40px;
}

.showroom .row .showroom-content .border-div .text-content p {
  width: 100%;
  line-height: 2;
  margin-bottom: 10px;
}

.showroom .row .showroom-content .border-div .text-content .tinner {
  font-weight: 200;
}

.showroom .row .showroom-content .border-div .img-content {
  overflow: hidden;
}

.showroom .row .showroom-content .border-div .img-content img {
  width: auto;
}

.showroom h2 {
  line-height: 1.63;
  text-align: center;
  font-size: 40px;
  width: 100%;
  padding: 0;
  margin: 50px 0;
}

.showroom p {
  font-size: 20px;
  font-weight: 500;
  width: 100%;
  margin-bottom: 60px;
}

.showroom .centered {
  text-align: center;
}

.payment-end .payment-method {
  padding: 70px 50px 70px 210px;
}

.payment-end .payment-method h2 {
  width: 100%;
  font-size: 28px;
  font-weight: 400;
  align-content: center;
  padding: 40px 0;
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
}

.payment-end .payment-method h2 p {
  font-size: 14px;
  display: flex;
  background-color: #121212;
  color: white;
  width: 27px;
  height: 27px;
  align-items: center;
  justify-content: center;
  margin: auto 25px auto 0;
}

.payment-end .payment-method .payment-header {
  padding: 60px 0 30px;
}

.payment-end .payment-method form {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.payment-end .payment-method form .invoice {
  border: 1px solid black;
}

.payment-end .payment-method form .invoice .cont {
  border: none;
}

.payment-end .payment-method form .add-adres input {
  padding: 11px;
}

.payment-end .payment-method form .invoice-dropdown.invisible {
  height: 0;
  overflow: hidden;
  margin: 0 !important;
}

.payment-end .payment-method form .invoice-dropdown.visible {
  overflow: hidden;
  height: auto;
}

.payment-end .payment-method form .invoice-dropdown.visible input {
  padding: 11px;
}

.payment-end .payment-method form .invoice.invisible {
  height: 0;
  overflow: hidden;
  margin: 0;
}

.payment-end .payment-method form .cont {
  width: 100%;
  border: 1px solid black;
  margin: 7.5px 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 30px;
}

.payment-end .payment-method form .cont p {
  margin-bottom: 0;
  line-height: 1.2;
  font-size: 20px;
}

.payment-end .payment-method form .cont span {
  width: 100%;
  font-size: 14px;
  line-height: 1.71;
}

.payment-end .payment-method form .cont .delivery-type {
  margin: 0 20px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.payment-end .payment-method form .cont .price {
  font-size: 16px;
  font-weight: 400;
  margin: auto 0 auto auto;
  width: 100px;
  text-align: right;
}

.payment-end .payment-method form .cont img {
  margin: auto 20px auto 20px;
}

.payment-end .payment-method form .cont p {
  align-self: center;
}

.payment-end .payment-method form .cont .delivery-type-x2 {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  margin: auto 20px;
  flex-wrap: nowrap;
}

.payment-end .payment-method form .cont .delivery-type-x2 span {
  width: auto;
}

.payment-end .payment-method form .wrap-label {
  flex-wrap: wrap;
  flex-grow: 1;
}

.payment-end .payment-method form .wrap-label .delivery-type-x2 {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  margin: auto 20px;
  flex-wrap: nowrap;
}

.payment-end .payment-method form .wrap-label .delivery-type-x2 span {
  width: auto;
}

.payment-end .payment-method form .wrap-label .label-5 {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
}

.payment-end .payment-method form .wrap-label .delivery-addres {
  padding: 25px 0 10px 70px;
  width: 100%;
}

.payment-end .payment-method form .wrap-label .delivery-addres .addres-data {
  font-size: 14px;
  line-height: 1.7;
  text-transform: uppercase;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-bottom: 5px;
}

.payment-end .payment-method form .wrap-label .delivery-addres .addres-data a {
  font-size: 16px;
  text-transform: none;
  color: #121212;
  font-weight: 500;
}

.payment-end .payment-method form .wrap-label .delivery-addres .addres-data a:hover {
  text-decoration: none;
}

.payment-end .payment-method form .wrap-label .delivery-addres p {
  font-size: 14px;
  line-height: 1.7;
}

.payment-end .payment-method form .wrap-label .delivery-addres p span {
  font-weight: 500;
}

.payment-end .payment-method form .checkbox-container {
  width: 20px;
  height: 20px;
  position: relative;
  display: flex;
  align-content: center;
  cursor: pointer;
}

.payment-end .payment-method form .checkbox-container .checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
  background-color: white;
  border: 1px solid black;
}

.payment-end .payment-method form .checkbox-container .checkmark:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: none;
}

.payment-end .payment-method form .checkbox-container input {
  position: absolute;
  margin: 0;
  opacity: 0;
  cursor: pointer;
}

.payment-end .payment-method form .checkbox-container input:checked ~ .checkmark {
  background-color: #121212;
}

.payment-end .payment-method form .checkmark:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: none;
}

.payment-end .payment-method form .checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.payment-end .payment-method form .checkbox-container .checkmark:after {
  top: 50%;
  transform: translateY(-50%);
  left: 1px;
  width: 16px;
  height: 16px;
  background: black;
  border: 2px solid white;
}

.payment-end .payment-method form .master-card {
  margin: 7.5px 0;
  padding-bottom: 10px;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.payment-end .payment-method form .master-card label {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  padding: 0;
  width: 55%;
  justify-content: space-between;
  border: 1px solid black;
}

.payment-end .payment-method form .master-card label input {
  margin: 0;
  border: none;
  padding: 15px;
}

.payment-end .payment-method form .master-card label input:focus-visible {
  outline: none;
}

.payment-end .payment-method form .master-card label #card-number {
  width: 50%;
}

.payment-end .payment-method form .master-card label #card-date {
  width: 25%;
}

.payment-end .payment-method form .master-card label #card-cvv {
  width: 25%;
}

.payment-end .payment-method form .master-card span {
  width: 55%;
  text-align: center;
}

.payment-end .payment-method form .master-card span a {
  color: lightgray;
}

.payment-end .payment-method form .master-card span a:hover {
  text-decoration: underline;
}

.payment-end .payment-method form .master-card .cont {
  border: none;
}

.payment-end .payment-method form .master-card .cont .checkbox-container {
  border: none;
  width: 20px;
  height: 20px;
  position: relative;
  display: flex;
  align-content: center;
  cursor: pointer;
}

.payment-end .payment-method form .master-card .cont .checkbox-container .checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
  background-color: white;
  border: 1px solid black;
}

.payment-end .payment-method form .master-card .cont .checkbox-container .checkmark:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: none;
}

.payment-end .payment-method form .master-card .cont .checkbox-container input {
  position: absolute;
  margin: 0;
  opacity: 0;
  cursor: pointer;
}

.payment-end .payment-method form .master-card .cont .checkbox-container input:checked ~ .checkmark {
  background-color: #121212;
}

.payment-end .payment-method form .master-card .cont .checkmark:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: none;
}

.payment-end .payment-method form .master-card .cont .checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.payment-end .payment-method form .master-card .cont .checkbox-container .checkmark:after {
  top: 50%;
  transform: translateY(-50%);
  left: 1px;
  width: 16px;
  height: 16px;
  background: black;
  border: 2px solid white;
}

.payment-end .payment-method form .master-card .cont img {
  margin: auto 50px auto 20px;
}

.payment-end .payment-method form .master-card .cont p {
  align-self: center;
  line-height: 1.2;
  font-size: 20px;
  margin: 0;
}

.payment-end .payment-method form button {
  background-color: #121212;
  margin: 50px auto 15px auto;
  justify-self: center;
  width: 53%;
  color: #FFF;
  padding: 10px 0;
}

.payment-end .payment-method form button p {
  color: white;
  font-size: 16px;
  text-decoration: none;
  font-weight: 500;
  margin-bottom: 0;
}

.payment-end .payment-method form button p span {
  line-height: 1.14;
  font-size: 14px;
}

.payment-end .payment-method form button:hover {
  transform: scale(1.07);
}

.payment-end .payment-method form .regulamin {
  width: 54%;
  font-size: 12px;
  line-height: 1.67;
  text-align: center;
}

.payment-end .payment-method form .regulamin a {
  font-weight: 500;
  color: #121212;
}

.payment-end .payment-method form .regulamin a:hover {
  text-decoration: none;
}

.payment-end .payment-method form .adres {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid black;
  justify-content: flex-start;
}

.payment-end .payment-method form .adres .cont {
  border: none;
  flex-wrap: nowrap;
  align-items: flex-start;
}

.payment-end .payment-method form .adres .cont label {
  margin-right: 10px;
}

.payment-end .payment-method form .adres .cont label .checkmark {
  top: 55%;
}

.payment-end .payment-method form .adres .adres-name {
  font-size: 16px;
  margin: 0;
  font-weight: 500;
  text-decoration: none;
  color: #121212;
  display: inline;
  padding-left: 0;
}

.payment-end .payment-nav {
  margin-right: 0;
  border-left: 1px solid black;
  border-top: 1px solid black;
  position: relative;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
}

.payment-end .payment-nav .payment {
  padding: 100px 100px 0 40px;
  background-color: transparent;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.payment-end .payment-nav .payment .subtotal-price {
  text-transform: uppercase;
  width: 100%;
  font-size: 16px;
  font-weight: normal;
  margin: 0;
  padding-bottom: 15px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.payment-end .payment-nav .payment .subtotal-price span {
  font-weight: 400;
}

.payment-end .payment-nav .payment .total-price {
  font-size: 16px;
  border-top: 1px solid lightgray;
  padding: 25px 0;
  margin-top: 10px;
}

.payment-end .payment-nav .payment p {
  font-size: 14px;
  line-height: 1.43;
  margin-bottom: 13px;
}

.payment-end .payment-nav .payment button {
  transition: 0.6s;
}

.payment-end .payment-nav .payment .btn-dark {
  margin: 15px 0;
  width: 100%;
  border-radius: 0;
  padding: 15px;
}

.payment-end .payment-nav .payment .btn-dark:focus {
  box-shadow: none;
}

.payment-end .payment-nav .payment .btn-dark:hover {
  transform: scale(1.07);
}

.payment-end .payment-nav .payment .orders-hide {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  background-color: transparent;
  width: auto;
  text-decoration: none;
  color: #121212;
  text-transform: uppercase;
  margin: 16.5px 0;
  width: 100%;
  text-align: left;
}

.payment-end .payment-nav .payment .orders-hide img {
  margin-left: 10px;
  transform: rotate(180deg);
}

.payment-end .payment-nav .payment .orders-hide .rotate {
  transform: rotate(0deg);
}

.payment-end .payment-nav .payment .orders-hide:hover {
  cursor: pointer;
}

.payment-end .payment-nav .payment .orders {
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.payment-end .payment-nav .payment .orders.active {
  opacity: 1;
  height: auto;
  width: 100%;
}

.payment-end .payment-nav .payment .orders.active .order-row {
  height: 125px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  padding: 12.5px 0;
}

.payment-end .payment-nav .payment .orders.active .order-row .order-content {
  display: flex;
  flex-wrap: nowrap;
}

.payment-end .payment-nav .payment .orders.active .order-row .order-content a {
  max-width: 100px;
  max-height: 100px;
  overflow: hidden;
}

.payment-end .payment-nav .payment .orders.active .order-row .order-content a img {
  max-width: 100px;
  max-height: 100px;
  overflow: hidden;
}

.payment-end .payment-nav .payment .orders.active .order-row .order-content a:hover img {
  transform: scale(1.07);
  cursor: pointer;
}

.payment-end .payment-nav .payment .orders.active .order-row .order-content .order-description {
  padding-left: 20px;
}

.payment-end .payment-nav .payment .orders.active .order-row .order-content p {
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
}

.payment-end .payment-nav .payment .orders.active .order-row .order-content p span {
  width: 100%;
}

.payment-end .payment-nav .payment .orders.active .order-row .order-content .order-name {
  line-height: 1.43;
  color: #121212;
  text-decoration: none;
}

.payment-end .payment-nav .payment .orders.active .order-row .order-content .order-name:hover {
  text-decoration: underline;
  cursor: pointer;
}

.payment-end .payment-nav .payment .orders.active .order-row .order-value {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

.payment-end .payment-nav .payment-nav-btn {
  position: absolute;
  border: 1px solid black;
  top: 250px;
  right: 100%;
  background-color: transparent;
  transform: rotate(90deg);
}

.payment-end .payment-nav .payment-nav-btn img {
  height: 100%;
  padding: 2px;
}

.payment-end .payment-nav .payment-nav-btn:hover {
  transform: scaleY(1.1) rotate(90deg);
}

.payment-end .payment-nav .payment-nav-btn.rotate {
  transform: translate(270deg);
}

.payment-end .payment-nav-btn {
  display: none;
}

.payment-end .confirm-buy-info {
  position: fixed;
  z-index: 16;
  top: 50%;
  display: none;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: auto;
  padding: 50px;
}

.payment-end .confirm-buy-info .return-info-content {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.payment-end .confirm-buy-info h5 {
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  padding: 35px 10px 50px;
}

.payment-end .confirm-buy-info a {
  display: block;
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  color: #121212;
}

.payment-end .confirm-buy-info a:hover {
  text-decoration: none;
}

.payment-end .confirm-buy-info.active {
  display: block;
}

.product-details .row {
  padding: 50px 12% 0;
  height: 100%;
}

.product-details .row .side {
  padding-right: 15px;
  max-height: 100%;
  padding-left: 0;
}

.product-details .row .side .box-img {
  width: 100%;
  height: 33.333%;
  padding: 5px 0;
  display: flex;
  overflow: hidden;
}

.product-details .row .side .box-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.product-details .row .side .box-img video {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  width: 100%;
}

.product-details .row .side .box-img img:hover {
  cursor: pointer;
}

.product-details .row .side .box-img:first-child {
  padding-top: 0;
  padding-bottom: 10px;
}

.product-details .row .side .box-img:last-child {
  padding-top: 10px;
  padding-bottom: 0;
}

.product-details .row .main {
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
}

.product-details .row .main .box-img {
  padding: 0;
  overflow: hidden;
}

.product-details .row .main .box-img img {
  height: 100%;
  width: 100%;
  object-position: center;
  object-fit: cover;
}

.product-details .row .main .box-img .slider {
  display: none;
}

.product-details .row .main .box-img .slider img {
  display: block;
}

.product-details .row .main .box-img:hover img {
  transform: scale(1.07);
  cursor: pointer;
}

.product-details .row .main .main-content {
  padding: 30px 70px 30px 55px;
  border: 1px solid black;
  border-left: none;
}

.product-details .row .main .main-content p {
  font-size: 14px;
  line-height: 1.43;
  margin: 20px 0 0;
}

.product-details .row .main .main-content p a {
  color: #cccccc;
  text-decoration: none;
}

.product-details .row .main .main-content p a:hover {
  text-decoration: underline;
}

.product-details .row .main .main-content h1,
.product-details .row .main .main-content h3 {
  font-size: 20px;
  line-height: 2;
  font-weight: 400;
}

.product-details .row .main .main-content .price {
  line-height: 1;
  margin: 5px 0 15px;
}

.product-details .row .main .main-content .bold {
  font-weight: 500;
}

.product-details .row .main .main-content .uppercase {
  text-transform: uppercase;
}

.product-details .row .main .main-content .bolder {
  font-weight: 600;
}

.product-details .row .main .main-content .space-beetwen {
  width: 100%;
  justify-content: space-between;
}

.product-details .row .main .main-content form {
  margin-bottom: 20px;
}

.product-details .row .main .main-content form .chose-color {
  width: 100%;
  height: 25px;
  display: flex;
  align-content: flex-start;
}

.product-details .row .main .main-content form .chose-color .checkbox-container {
  width: auto;
  height: auto;
  position: relative;
  margin-right: 10px;
  cursor: pointer;
  width: 25px;
}

.product-details .row .main .main-content form .chose-color .checkbox-container label {
  top: 0;
}

.product-details .row .main .main-content form .chose-color .checkbox-container input {
  position: absolute;
  border: none;
  opacity: 0;
  cursor: pointer;
}

.product-details .row .main .main-content form .chose-color .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
}

.product-details .row .main .main-content form .chose-color .checkbox-container input:checked ~ .checkmark {
  border: 1px solid black;
}

.product-details .row .main .main-content form .chose-color .checkmark:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

.product-details .row .main .main-content form .chose-color .checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.product-details .row .main .main-content form .chose-color .checkbox-container .checkmark:after {
  top: 0px;
  left: 0px;
  width: 23px;
  height: 23px;
  border: 3px solid white;
}

.product-details .row .main .main-content form label {
  position: relative;
  height: 50px;
  width: 100%;
  margin-bottom: 10px;
}

.product-details .row .main .main-content form label select {
  position: absolute;
  z-index: 3;
  width: 100%;
  font-weight: 500;
  text-transform: uppercase;
  padding: 12px;
  -webkit-appearance: none;
  background-color: transparent;
}

.product-details .row .main .main-content form label select:hover {
  cursor: pointer;
}

.product-details .row .main .main-content form .chose-size::after {
  content: "";
  background-image: url("../img/language.png");
  position: absolute;
  overflow: visible;
  z-index: 2;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
}

.product-details .row .main .main-content form label:hover {
  cursor: pointer;
}

.product-details .row .main .main-content form .to-cart {
  width: 100%;
  background-color: #121212;
  border: none;
  display: flex;
  height: 50px;
  justify-content: center;
  align-content: center;
}

.product-details .row .main .main-content form .to-cart p {
  color: white;
  display: flex;
  width: auto;
  display: block;
  width: 100%;
  align-content: center;
  justify-content: center;
  text-align: center;
  padding: 10px 10px 10px 0;
  margin: auto 0;
}

.product-details .row .main .main-content form .to-cart img {
  padding: 0 10px 2px 0;
}

.product-details .row .main .main-content form .to-cart:hover {
  transform: scale(1.07);
}

.product-details .row .main .main-content form .space-beetwen {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.product-details .row .main .main-content form .space-beetwen p {
  margin-bottom: 5px;
}

.product-details .row .main .main-content .about-product {
  margin: 10px 0 20px;
}

.product-details .row .main .main-content .about-product p {
  margin: 10px 0;
}

.product-details .row .main .main-content .share p {
  margin: 0 0 15px;
  font-size: 16px;
}

.product-details .row .main .main-content .share img {
  max-width: 15px;
  max-height: 15px;
  margin: 5px;
}

.product-details .row .main .main-content .share img:hover {
  transform: scale(1.07);
}

.product-details .lack-of-product {
  width: 570px;
  height: 350px;
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 16;
  padding: 50px;
  background-color: white;
}

.product-details .lack-of-product form {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-content: space-between;
  flex-wrap: wrap;
}

.product-details .lack-of-product form input {
  width: 100%;
  padding: 30px;
}

.product-details .lack-of-product form button {
  background-color: #121212;
  font-size: 16px;
  font-weight: 500;
  color: white;
  width: 100%;
  padding: 20px;
  text-align: center;
  border: none;
}

.product-details .lack-of-product form button:hover {
  transform: scale(1.07);
}

.product-details .lack-of-product h4 {
  line-height: 1.7;
  font-size: 20px;
  font-weight: 500;
  width: 100%;
  text-align: center;
}

.product-details .lack-of-product .close-lack-popap {
  position: absolute;
  right: -20px;
  top: -30px;
}

.product-details .lack-of-product .close-lack-popap img {
  width: 15px;
  height: 15px;
}

.product-details .lack-of-product .close-lack-popap:hover img {
  transform: rotate(90deg);
}

.product-details .lack-of-product.active {
  display: block;
}

.product-details .close-up {
  display: none;
  width: auto;
  z-index: 16;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 80%;
}

.product-details .close-up .box-img {
  display: none;
}

.product-details .close-up .box-img.show {
  display: flex;
  justify-content: center;
  align-content: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
}

.product-details .close-up .box-img.show img {
  height: 100%;
  width: auto;
}

.product-details .close-up button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border: none;
}

.product-details .close-up button img {
  width: 38px;
  height: 38px;
}

.product-details .close-up button:hover img {
  transform: scale(1.07);
}

.product-details .close-up .prev-img {
  left: -80px;
}

.product-details .close-up .next-img {
  right: -80px;
}

.product-details .close-up.active {
  display: flex;
  justify-content: center;
  align-items: center;
}

var {
  color: #121212;
}

@keyframes arrowShake {
  40% {
    top: 140%;
  }
  50% {
    top: 135%;
  }
  60% {
    top: 140%;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  height: 100%;
  display: block;
  margin-left: 0;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

@media (min-width: 1199px) {
  footer .container .row-padding .col-flex-2 {
    padding: 0;
    max-width: 20%;
  }
  footer .container .row-padding .col-flex {
    max-width: 13%;
    padding: 0;
  }
}

@media (max-width: 1469px) {
  .black-banner .container .row-padding {
    padding: 30px 115px;
  }
  .black-banner .container .row-padding .col-md-4 {
    padding: 80px 25px;
  }
  .black-banner .container .row-padding .col-md-4 .subtitle {
    font-size: 35px;
  }
  .personal-stylist .black-banner .container {
    padding: 30px 0;
  }
  .personal-stylist .black-banner .container .row-padding {
    padding: 30px 140px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .text-row h2 {
    font-size: 36px;
    margin-bottom: 5px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .text-row a {
    margin-bottom: 5px;
    font-size: 18px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-5 .box-img .text-content {
    top: 55%;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-5 .box-img .text-content a {
    font-size: 24px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-3 {
    top: 50%;
  }
  .photo-banner .container .photo-content {
    top: 25%;
  }
  .photo-banner .container .photo-content .photo-title {
    font-size: 90px;
    margin-bottom: 40px;
  }
  .photo-banner .container .photo-content .underline-text {
    font-size: 18px;
  }
  .photo-banner .container .photo-content-second {
    top: 55%;
  }
  .photo-banner .container .photo-content-second p {
    font-size: 18px;
  }
  .photo-banner .container .photo-content-second .photo-title-smaller {
    font-size: 36px;
  }
  .woocommerce-tabs,
  .new-collection .row-padding {
    padding: 30px 140px;
  }
  .woocommerce-tabs .box-v2 .text-content .new-collection-header,
  .new-collection .row-padding .box-v2 .text-content .new-collection-header {
    font-size: 18px;
  }
  .woocommerce-tabs .box-v2 .text-content button a img,
  .new-collection .row-padding .box-v2 .text-content button a img {
    left: 12%;
  }
  .woocommerce-tabs .box-v2 .text-content button a:hover img,
  .new-collection .row-padding .box-v2 .text-content button a:hover img {
    left: 13%;
  }
  .woocommerce-tabs .box-v2 .box-text a,
  .new-collection .row-padding .box-v2 .box-text a {
    font-size: 18px;
  }
  .shop-new-collection .container .row .col-12 .box {
    max-width: 30%;
  }
  .shop-new-collection .container .row .col-sm-6 .box {
    max-width: 45%;
  }
  .login-container .login form, .login-container .register form {
    width: 70%;
  }
  .register-container .login #register-form, .register-container .register #register-form {
    width: 70%;
  }
  .shop-all .container .panel-path,
  .shop-all .container .row {
    padding: 0 150px;
  }
  .shop-all .container .panel-path .box,
  .shop-all .container .row .box {
    margin-bottom: 40px;
  }
  .about .container .ceo {
    margin: 0 5%;
  }
  .about .container .stylish-everyday {
    margin: 60px 5%;
  }
  .about .container .stylish-everyday .text-content h4 {
    font-size: 20px;
  }
  .about .container .about-header {
    font-size: 20px;
    padding: 60px 20px;
  }
  .about .container .about-header.bottom {
    padding-bottom: 30px;
  }
  footer .container .row-padding .sign-up {
    font-size: 18px;
  }
  footer .container .row-padding form .sign-input {
    padding: 15px 10px;
  }
  footer .container .row-padding form .submit-form {
    right: 5%;
    width: 35px;
  }
  footer .container .row-padding form .submit-form img {
    width: 100%;
  }
  footer .container .row-padding form .submit-form:hover {
    right: 3%;
  }
  .my-data .container .row .panel {
    padding-left: 40px;
  }
  .my-data .container .row .panel .order-row {
    margin-right: 80px;
  }
  .my-data .container .row .panel .returns-instruction-btn {
    right: 80px;
  }
  .my-data .container .instruction-content {
    max-height: 90%;
    overflow-y: scroll;
  }
  .my-data .container .instruction-content::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }
  .my-data .container .instruction-content::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 5px;
  }
  .my-data .container .instruction-content::-webkit-scrollbar-thumb:hover {
    display: block;
  }
  .my-data .container .instruction-content::-webkit-scrollbar-track {
    background: white;
    border-radius: 5px;
    border-left: none;
    margin: 0 0;
  }
  .shopping-cart .shopping {
    padding: 60px 120px;
  }
  .shopping-cart .shopping-card .payment {
    top: 30%;
    padding: 0 60px 50px 30px;
  }
  .message form h4 {
    margin: 20px 0;
  }
  .message form input {
    padding: 26px;
  }
  .message form textarea {
    padding: 30px;
  }
  .message form input[type="submit"],
  .message form button {
    padding: 20px;
    margin-bottom: 20px;
  }
  .showroom .row {
    margin: 40px 140px;
  }
  .showroom .row .showroom-content {
    margin: 40px 0;
  }
  .showroom .row .showroom-content .border-div .text-content {
    padding-left: 20px;
  }
  .showroom .row .showroom-content .border-div .text-content h4 {
    font-size: 36px;
    margin-bottom: 15px;
  }
  .showroom .row .showroom-content .border-div .text-content p {
    line-height: 1.8;
    font-size: 18px;
  }
  .showroom h2 {
    font-size: 36px;
    line-height: 1.5;
    margin: 40px 0;
  }
  .showroom p {
    font-size: 18px;
    margin-bottom: 40px;
  }
  .payment-end .payment-method {
    padding: 60px 120px;
  }
  .payment-end .payment-method h2 {
    font-size: 28px;
    padding: 30px 0;
  }
  .payment-end .payment-method form .master-card label {
    width: 70%;
  }
  .payment-end .payment-method form .master-card span {
    width: 70%;
  }
  .payment-end .payment-method form button {
    width: 66%;
  }
  .payment-end .payment-method form .regulamin {
    width: 70%;
  }
  .payment-end .payment-nav .payment {
    padding: 80px 80px 0 30px;
  }
  .product-details .row {
    padding: 30px 140px 0;
  }
  .product-details .row .main-content {
    padding: 30px 90px 30px 40px;
  }
  .product-details .close-up .box-img {
    max-width: 600px;
    max-height: 900px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (max-width: 1199px) {
  footer .container {
    padding: 30px;
  }
  footer .container .row-padding {
    padding: 0 20px;
  }
  footer .container .row-padding .col-flex-2 {
    padding: 30px 0;
  }
  footer .container .row-padding .col-flex-2 .sign-up {
    font-size: 16px;
  }
  footer .container .row-padding .col-flex-2 p {
    padding-bottom: 0;
  }
  footer .container .row-padding .col-flex-2 .footer-img {
    height: 100%;
    object-fit: contain;
    padding: 0;
  }
  footer .container .row-padding .col-flex {
    padding: 0;
    text-align: left;
  }
  footer .container .row-padding .showroom .read-more img {
    width: 30px;
  }
  footer .container .row-padding form .submit-form:hover {
    right: 4%;
  }
  footer .container .copy-write {
    padding-top: 30px;
  }
  footer .container .copy-write .gray-font {
    padding: 0;
  }
  .black-banner .container .row-padding {
    padding: 25px 100px;
  }
  .black-banner .container .row-padding .col-md-4 {
    padding: 70px 20px;
  }
  .black-banner .container .row-padding .col-md-4 .subtitle {
    font-size: 32px;
  }
  .personal-stylist {
    height: 50vw;
  }
  .personal-stylist .black-banner .container {
    padding: 0;
  }
  .personal-stylist .black-banner .container .row-padding {
    padding: 25px 120px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .text-row h2 {
    font-size: 32px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .text-row p {
    font-size: 16px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-5 .box-img .text-content {
    width: 100%;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-5 .box-img .text-content a {
    font-size: 32px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-5 .box-img .text-content a .product-price {
    font-size: 14px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-3 .box-img .text-content a {
    font-size: 12px;
  }
  .personal-stylist .black-banner .container .row-padding .stylist-arrow {
    top: 55.5%;
  }
  .personal-stylist .black-banner .container .row-padding .stylist-arrow a {
    font-size: 12px;
  }
  .photo-banner .container .photo-arrow {
    width: 35px;
    height: 80px;
  }
  .photo-banner .container .photo-content {
    top: 25%;
  }
  .photo-banner .container .photo-content .photo-title {
    font-size: 75px;
    margin-bottom: 30px;
  }
  .photo-banner .container .photo-content .underline-text {
    font-size: 16px;
  }
  .photo-banner .container .photo-content-second {
    top: 50%;
  }
  .photo-banner .container .photo-content-second p {
    font-size: 16px;
  }
  .photo-banner .container .photo-content-second .photo-title-smaller {
    font-size: 32px;
  }
  .photo-banner .container .text-content.about a {
    font-size: 18px;
  }
  .photo-banner .container .text-content.about a .bigger {
    font-size: 32px;
    padding-top: 20px;
  }
  .photo-banner h2 {
    font-size: 64px;
  }
  .new-collection {
    padding: 25px 0 0 0;
  }
  .new-collection .row-padding {
    padding: 25px 120px;
  }
  .new-collection .row-padding .box-v2 {
    padding-bottom: 100px;
  }
  .new-collection .row-padding .box-v2 .text-content .new-collection-header {
    font-size: 16px;
  }
  .new-collection .row-padding .box-v2 .text-content button {
    padding-bottom: 10px;
  }
  .new-collection .row-padding .box-v2 .text-content button a img {
    left: 15%;
  }
  .new-collection .row-padding .box-v2 .text-content button a:hover img {
    left: 16%;
  }
  .new-collection .row-padding .box-v2 .box-text a {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .new-collection .row-padding .box-v2 .box-text a .product-price {
    margin-bottom: 10px;
    padding-top: 10px;
  }
  .new-collection .container .row-border h3 {
    font-size: 18px;
    margin: 0px 0 20px;
  }
  header .container .navbar {
    padding: 20px 40px;
  }
  .shop-new-collection {
    padding: 80px 0;
  }
  .shop-new-collection .container .row .col-12 {
    margin-top: 80px;
  }
  .shop-new-collection .container .row .col-12 .box {
    max-width: 35%;
  }
  .shop-new-collection .container .row .col-sm-6 .box {
    max-width: 50%;
  }
  .shop-product .container .row {
    padding: 80px 0 60px 0;
  }
  .login-container .login form, .login-container .register form {
    width: 80%;
  }
  .register-container .login #register-form, .register-container .register #register-form {
    width: 80%;
  }
  .about .container .ceo {
    margin: 0 4%;
  }
  .about .container .stylish-everyday {
    margin: 40px 4%;
  }
  .about .container .stylish-everyday .text-content h4 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .about .container .about-header {
    font-size: 20px;
    padding: 50px 10px;
  }
  .about .container .about-header.bottom {
    padding-bottom: 25px;
  }
  .my-data .container .row .account-nav .data p {
    margin-left: 30%;
    width: 60%;
  }
  .my-data .container .row .panel {
    padding-left: 30px;
  }
  .my-data .container .row .panel form label {
    width: 80%;
  }
  .my-data .container .row .panel form .flex-label {
    width: 80%;
  }
  .my-data .container .row .panel form .flex-label input {
    padding: 16px;
  }
  .my-data .container .row .panel form .adres {
    width: 80%;
  }
  .my-data .container .row .panel form .newsletter {
    padding-right: 80px;
  }
  .my-data .container .row .panel form .btn-container {
    width: 100%;
    height: 50px;
    position: relative;
    margin: 50px 0;
  }
  .my-data .container .row .panel form .btn-container button {
    background-color: black;
    padding: 10px 120px;
    color: white;
    position: absolute;
    left: 40%;
  }
  .my-data .container .row .panel form .btn-container button:hover {
    transform: scale(1.07) translateX(-10%);
  }
  .my-data .container .row .panel .order-row {
    margin-right: 60px;
  }
  .my-data .container .row .panel .see-more {
    padding: 15px 130px;
    margin: 40px 0 0 100px;
  }
  .my-data .container .row .panel .returns-instruction-btn {
    right: 60px;
  }
  .my-data .container .row .panel .return-addres h5 {
    margin: 60px 0 25px;
  }
  .shopping-cart .shopping {
    padding: 50px 80px;
  }
  .shopping-cart .shopping-card .payment {
    padding: 0 20px;
  }
  .shopping-cart h4 {
    font-size: 18px;
  }
  .newsletter2-popap.active .product-popap-content a button,
  .newsletter-popap.active .product-popap-content a button,
  .table-popup.active .product-popap-content a button,
  .product-popap.active .product-popap-content a button {
    padding: 18px 80px;
  }
  .empty-cart .empty-content {
    padding: 100px 0;
  }
  .showroom .row {
    margin: 30px 120px;
  }
  .showroom .row .showroom-content {
    margin: 30px 0;
  }
  .showroom .row .showroom-content .border-div .text-content {
    padding-left: 20px;
  }
  .showroom .row .showroom-content .border-div .text-content h4 {
    font-size: 32px;
    margin-bottom: 10px;
  }
  .showroom .row .showroom-content .border-div .text-content p {
    line-height: 1.6;
    margin-bottom: 5px;
    font-size: 16px;
  }
  .showroom h2 {
    font-size: 32px;
    line-height: 1.4;
    margin: 30px 0;
  }
  .showroom p {
    font-size: 16px;
    margin-bottom: 30px;
  }
  .payment-end .payment-method {
    padding: 50px 80px;
  }
  .payment-end .payment-method h2 {
    padding: 30px 0;
  }
  .payment-end .payment-method form .master-card {
    margin: 5px 0;
  }
  .payment-end .payment-method form .master-card label {
    width: 80%;
  }
  .payment-end .payment-method form .master-card span {
    width: 80%;
  }
  .payment-end .payment-method form .master-card .cont input {
    margin: 15px;
  }
  .payment-end .payment-method form .master-card .cont p {
    font-size: 18px;
  }
  .payment-end .payment-method form button {
    width: 74%;
  }
  .payment-end .payment-method form button p {
    font-size: 14px;
  }
  .payment-end .payment-method form .regulamin {
    width: 80%;
  }
  .payment-end .payment-method form .wrap-label .delivery-addres {
    padding: 20px 0 10px 63px;
  }
  .payment-end .payment-method .payment-header {
    padding: 50px 0 25px;
  }
  .payment-end .payment-nav .payment {
    padding: 70px 20px;
  }
  .payment-end .payment-nav .payment .btn-dark {
    font-size: 14px;
  }
  .payment-end .payment-nav .payment .total-price {
    font-size: 18px;
  }
  .payment-end .payment-nav .payment .orders.active .order-row {
    height: 120px;
    padding: 10px 0;
  }
  .payment-end .payment-nav .payment .orders.active .order-row .order-value {
    font-size: 14px;
  }
  .payment-end .payment-nav .payment .orders.active .order-row .order-content .order-description {
    padding-left: 10px;
  }
  .product-details .row {
    padding: 25px 120px 0;
  }
  .product-details .row .main .main-content {
    padding: 20px 70px 20px 20px;
  }
  .product-details .row .main .main-content h3 {
    line-height: 1.5;
    font-size: 18px;
    margin-bottom: 0;
  }
  .product-details .row .main .main-content p {
    margin: 10px 0;
  }
  .product-details .row .main .main-content form label {
    height: 45px;
  }
  .product-details .row .main .main-content form label select {
    padding: 10px;
  }
  .product-details .close-up .box-img {
    max-width: 540px;
    max-height: 800px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (max-width: 991px) {
  .black-banner .container .row-padding {
    padding: 20px 55px;
  }
  .black-banner .container .row-padding .col-md-4 {
    padding: 60px 15px;
  }
  .black-banner .container .row-padding .col-md-4 .subtitle {
    font-size: 28px;
  }
  footer .container .row-padding {
    padding: 0 20px;
  }
  footer .container .copy-write {
    padding-top: 0;
  }
  .personal-stylist {
    height: 65vw;
  }
  .personal-stylist .black-banner .container .row-padding {
    padding: 20px 80px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container {
    padding: 20px 0;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .text-row {
    height: 10%;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .text-row h2 {
    font-size: 28px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .text-row p {
    font-size: 14px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-5 .box-img .text-content {
    width: 100%;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-5 .box-img .text-content a {
    font-size: 28px;
    padding: 5px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-5 .box-img .text-content a .product-price {
    padding: 5px;
    font-size: 18px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-3 {
    padding-left: 15px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-3 .text-content {
    top: 60%;
    width: 100%;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-3 .text-content a {
    font-size: 14px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-3 .text-content a .product-price {
    font-size: 16px;
    padding-top: 2px;
  }
  .personal-stylist .black-banner .container .row-padding .stylist-arrow {
    top: 53%;
  }
  .personal-stylist .black-banner .container .row-padding .stylist-right-arrow {
    right: 8%;
  }
  .personal-stylist .black-banner .container .row-padding .stylist-right-arrow:hover {
    right: 7%;
  }
  .personal-stylist .black-banner .container .row-padding .stylist-left-arrow {
    left: 8%;
  }
  .personal-stylist .black-banner .container .row-padding .stylist-left-arrow:hover {
    left: 7%;
  }
  .photo-banner .container .photo-arrow {
    width: 30px;
    height: 65px;
  }
  .photo-banner .container .photo-content {
    top: 20%;
  }
  .photo-banner .container .photo-content .photo-title {
    font-size: 60px;
    margin-bottom: 20px;
  }
  .photo-banner .container .photo-content .underline-text {
    font-size: 14px;
  }
  .photo-banner .container .photo-content-second {
    top: 50%;
  }
  .photo-banner .container .photo-content-second p {
    font-size: 14px;
  }
  .photo-banner .container .photo-content-second .photo-title-smaller {
    font-size: 28px;
  }
  .new-collection .row-padding {
    padding: 20px 40px;
  }
  .new-collection .row-padding .box-v2 {
    padding-bottom: 80px;
  }
  .new-collection .row-padding .box-v2 .text-content .new-collection-header {
    font-size: 14px;
  }
  .new-collection .row-padding .box-v2 .text-content button a img {
    left: 68px;
  }
  .new-collection .row-padding .box-v2 .text-content button a:hover img {
    left: 72px;
  }
  .new-collection .row-padding .box-v2 .box-text a {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .new-collection .row-padding .box-v2 .box-text a .product-price {
    margin-bottom: 5px;
    padding-top: 5px;
  }
  .new-collection .row-padding .box-v2 .box-img2 {
    margin-top: 5px;
    height: calc(70% - 5px);
  }
  .new-collection .container .row-border h3 {
    font-size: 16px;
    margin: 0px 0 15px;
  }
  header .container .navbar {
    padding: 15px 30px;
    position: relative;
  }
  header .container .navbar .navbar-toggler {
    padding: 0;
  }
  header .container .navbar .collapse {
    z-index: 21;
    transition: 0.3s;
    position: absolute;
    top: -204%;
    background-color: white;
    left: 0;
    width: 100%;
    padding: 10px 30px;
  }
  header .container .navbar .collapse .navbar-nav {
    position: absolute;
    left: 0;
    padding-left: 30px;
    width: 100%;
    padding-top: 30px;
  }
  header .container .navbar .collapse .navbar-icons form {
    left: 0;
    width: 100%;
    justify-content: flex-start;
    margin: 0;
    padding-top: 20px;
  }
  header .container .navbar .collapse .navbar-icons form .search {
    padding: 10px 0;
    justify-content: center;
    max-height: 40px;
  }
  header .container .navbar .collapse .navbar-icons form #search {
    margin: 10px 30px 10px 0;
  }
  header .container .navbar .collapse .navbar-icons .account {
    top: 166px;
    transform: translate(-50%, -50%);
    opacity: 1;
    position: absolute;
    height: 40px;
    padding: 10px;
    right: 40px;
  }
  header .container .navbar .collapse .navbar-icons .account:hover {
    opacity: 0.5;
  }
  header .container .navbar .collapse .navbar-icons .cart {
    right: -25px;
    opacity: 1;
    position: absolute;
    height: 40px;
    padding: 10px;
    top: 161px;
    transform: translate(-50%, -50%);
  }
  header .container .navbar .collapse .navbar-icons .cart p {
    padding-top: 3px;
    padding-bottom: 0;
  }
  header .container .navbar .collapse .navbar-icons .cart:hover {
    opacity: 0.5;
  }
  header .container .navbar .collapse.mobile-active {
    transition: 0.3s;
    opacity: 1;
    z-index: 21;
    display: flex;
    flex: 100%;
    flex-wrap: wrap;
    position: absolute;
    height: 100vh;
    top: 100%;
    align-content: flex-start;
    left: 0;
  }
  header .container .navbar .collapse.mobile-active .navbar-nav {
    position: static;
    width: 100%;
    padding-left: 0;
    opacity: 1;
  }
  header .container .navbar .collapse.mobile-active .navbar-nav .nav-link:hover {
    text-decoration: underline;
  }
  header .container .navbar .collapse.mobile-active .navbar-icons {
    opacity: 1;
  }
  header .container .navbar .collapse.mobile-active .navbar-icons form input {
    width: 100%;
    background-color: white;
  }
  header .container .navbar .navbar-first-col .navbar-nav .nav-link {
    font-size: 14px;
  }
  .shop-new-collection {
    padding: 60px 0;
  }
  .shop-new-collection .container .row .col-12 {
    margin-top: 60px;
  }
  .shop-new-collection .container .row .col-12 .box {
    max-width: 40%;
  }
  .shop-new-collection .container .row .col-sm-6 .box {
    max-width: 60%;
  }
  .shop-product .container .row {
    padding: 60px 0 40px 0;
  }
  .shop-product .container .row .col-xxl-2 .text-content {
    padding-bottom: 20px;
  }
  .shop-product .container .row .col-xxl-2 .text-content a {
    align-content: flex-start;
  }
  .account-popap {
    top: -350px;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
  }
  .account-popap.active {
    top: -350px;
  }
  .account-popap.mobile-active {
    height: auto;
    display: block;
    top: 64px;
  }
  .account-popap.mobile-active.loged {
    display: none;
  }
  .loged-account-popap {
    left: 50%;
    transform: translateX(-50%);
    top: -350px;
  }
  .loged-account-popap.active {
    top: -350px;
  }
  .loged-account-popap.mobile-active {
    height: auto;
    display: block;
    top: 64px;
  }
  .loged-account-popap.logout {
    display: none;
  }
  .login-container .login form, .login-container .register form {
    width: 90%;
  }
  .login-container .login form input, .login-container .register form input {
    padding: 10px;
  }
  .register-container .login #register-form, .register-container .register #register-form {
    width: 90%;
  }
  .register-container .login #register-form input, .register-container .register #register-form input {
    padding: 10px;
  }
  .shop-all .container .shop-all-header {
    padding: 30px 0;
  }
  .shop-all .container .panel-path,
  .shop-all .container .row {
    padding: 0 100px;
  }
  .shop-all .container .panel-path .box,
  .shop-all .container .row .box {
    margin-bottom: 40px;
    padding: 0 10px;
  }
  .shop-all .container .blocks-nav button {
    width: 35px;
    height: 35px;
    margin: 15px;
  }
  .about .container .ceo {
    margin: 0 50px;
  }
  .about .container .stylish-everyday {
    margin: 20px 50px;
  }
  .about .container .stylish-everyday .text-content h4 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .about .container .stylish-everyday .text-content p {
    font-size: 12px;
    margin-bottom: 5px;
  }
  .about .container .about-header {
    font-size: 20px;
    padding: 30px 5px;
  }
  .about .container .about-header.bottom {
    padding-bottom: 20px;
  }
  .my-data .container .row .account-nav .data p {
    margin-left: 10%;
    width: 85%;
  }
  .my-data .container .row .panel {
    padding: 30px 0 30px 30px;
  }
  .my-data .container .row .panel form label {
    margin-left: 2px;
    margin-bottom: 14px;
    width: 80%;
  }
  .my-data .container .row .panel form .flex-label {
    margin-bottom: 14px;
    width: 90%;
  }
  .my-data .container .row .panel form .flex-label input {
    padding: 10px;
  }
  .my-data .container .row .panel form .adres {
    width: 90%;
  }
  .my-data .container .row .panel form .newsletter {
    padding-right: 80px;
  }
  .my-data .container .row .panel form .newsletter div button {
    padding: 15px 100px;
  }
  .my-data .container .row .panel form .btn-container {
    width: 100%;
    height: 50px;
    position: relative;
    margin: 50px 0;
  }
  .my-data .container .row .panel form .btn-container button {
    background-color: black;
    padding: 10px 70px;
    color: white;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .my-data .container .row .panel form .btn-container button:hover {
    transform: translateX(-50%) scale(1.07);
  }
  .my-data .container .row .panel .order-row {
    margin-right: 30px;
  }
  .my-data .container .row .panel .returns-instruction-btn {
    top: 87px;
    right: 30px;
  }
  .my-data .container .row .panel .see-more {
    padding: 15px 100px;
    margin: 40px 0 0 60px;
  }
  .my-data .container .row .panel .summary .summary-data h4 {
    margin-bottom: 20px;
  }
  .my-data .container .row .panel .summary .summary-data p {
    margin-bottom: 15px;
  }
  .my-data .container .row .panel .return-btn-container {
    margin-bottom: 0;
  }
  .shopping-cart .shopping {
    padding: 30px 50px 10px;
    font-size: 12px;
  }
  .shopping-cart .shopping .item-row {
    padding: 20px 0;
    height: 250px;
  }
  .shopping-cart .shopping .item-row .item-content img {
    margin-right: 15px;
  }
  .shopping-cart .shopping .item-row .item-content .item-description .dropdown button {
    font-size: 14px;
  }
  .shopping-cart .shopping .item-row .item-value p {
    padding-bottom: 8px;
  }
  .shopping-cart .shopping .information {
    margin-top: 40px;
    padding: 40px;
  }
  .shopping-cart .shopping .information h5 {
    font-size: 14px;
  }
  .shopping-cart .shopping .information p {
    font-size: 12px;
  }
  .shopping-cart .shopping-card .payment {
    padding: 10px;
    font-size: 12px;
  }
  .shopping-cart .shopping-card .payment .btn-dark {
    width: 90%;
    font-size: 14px;
    padding: 10px;
  }
  .shopping-cart .shopping-card .payment form input {
    padding: 5px;
  }
  .shopping-cart .shopping-card .payment form button {
    padding: 5px 10px;
  }
  .shopping-cart .shopping-card .payment .payment-forms p {
    font-size: 12px;
  }
  .shopping-cart .shopping-card .payment .payment-forms a img {
    transform: scale(0.8);
  }
  .shopping-cart .shopping-card .payment .payment-forms a:hover img {
    transform: scale(0.9);
  }
  .shopping-cart h4 {
    font-size: 14px;
  }
  .choices .item-content .item-description .chose-reason {
    width: 100%;
    height: 40px;
  }
  .choices .item-content .item-description .chose-reason select {
    padding: 5px 10px;
  }
  .newsletter2-popap.active .product-popap-content,
  .newsletter-popap.active .product-popap-content,
  .table-popup.active .product-popap-content,
  .product-popap.active .product-popap-content {
    padding: 15px 30px;
  }
  .newsletter2-popap.active .product-popap-content .product-describe,
  .newsletter-popap.active .product-popap-content .product-describe,
  .table-popup.active .product-popap-content .product-describe,
  .product-popap.active .product-popap-content .product-describe {
    padding: 10px 0;
    justify-content: space-around;
  }
  .newsletter2-popap.active .product-popap-content .product-describe .box-img,
  .newsletter-popap.active .product-popap-content .product-describe .box-img,
  .table-popup.active .product-popap-content .product-describe .box-img,
  .product-popap.active .product-popap-content .product-describe .box-img {
    padding-left: 10px;
  }
  .newsletter2-popap.active .product-popap-content .product-describe .box-content,
  .newsletter-popap.active .product-popap-content .product-describe .box-content,
  .table-popup.active .product-popap-content .product-describe .box-content,
  .product-popap.active .product-popap-content .product-describe .box-content {
    padding: 0 10px;
  }
  .newsletter2-popap.active .product-popap-content a,
  .newsletter-popap.active .product-popap-content a,
  .table-popup.active .product-popap-content a,
  .product-popap.active .product-popap-content a {
    font-size: 14px;
    padding: 10px 0;
  }
  .newsletter2-popap.active .product-popap-content a button,
  .newsletter-popap.active .product-popap-content a button,
  .table-popup.active .product-popap-content a button,
  .product-popap.active .product-popap-content a button {
    padding: 18px;
    width: 100%;
  }
  .newsletter2-popap.active h3,
  .newsletter-popap.active h3,
  .table-popup.active h3,
  .product-popap.active h3 {
    font-size: 18px;
    padding: 15px 0;
  }
  .empty-cart .empty-content {
    padding: 80px 0;
  }
  .empty-cart .empty-content img {
    margin-bottom: 30px;
  }
  .empty-cart .empty-content h2 {
    margin: 10px 0 15px 0;
  }
  .empty-cart .empty-content button a {
    padding: 18px 0;
  }
  .message form input {
    padding: 20px;
  }
  .message form textarea {
    padding: 20px;
  }
  .message form .permission {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .message form input[type="submit"],
  .message form button {
    margin: 10px 5px 20px 5px;
    padding: 18px;
  }
  .showroom .row {
    margin: 20px 80px;
  }
  .showroom .row .showroom-content {
    padding: 0;
    margin: 20px 0;
  }
  .showroom .row .showroom-content .border-div .text-content {
    padding-left: 20px;
  }
  .showroom .row .showroom-content .border-div .text-content h4 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  .showroom .row .showroom-content .border-div .text-content p {
    line-height: 1.5;
    margin-bottom: 5px;
    font-size: 14px;
  }
  .showroom h2 {
    font-size: 28px;
    line-height: 1.3;
    margin: 20px 0;
  }
  .showroom p {
    font-size: 14px;
    margin-bottom: 20px;
  }
  .payment-end .payment-method {
    padding: 30px 50px;
  }
  .payment-end .payment-method h2 {
    font-size: 28px;
    padding: 25px 0;
  }
  .payment-end .payment-method form .cont {
    padding: 15px;
  }
  .payment-end .payment-method form .cont .delivery-type {
    margin: 0 10px;
  }
  .payment-end .payment-method form .cont .delivery-type-x2 {
    margin: auto 10px;
  }
  .payment-end .payment-method form .cont input {
    margin: auto 12px;
  }
  .payment-end .payment-method form .cont p {
    font-size: 16px;
  }
  .payment-end .payment-method form .cont .price {
    font-size: 14px;
    padding-right: 0;
    width: 80px;
  }
  .payment-end .payment-method form .wrap-label .delivery-addres {
    padding: 15px 0 10px 47px;
  }
  .payment-end .payment-method form .wrap-label .delivery-addres .addres-data {
    margin-bottom: 0;
  }
  .payment-end .payment-method form .wrap-label .delivery-addres .addres-data a {
    padding-right: 0;
    font-size: 14px;
  }
  .payment-end .payment-method form .master-card .cont input {
    margin: auto 12px;
  }
  .payment-end .payment-method form .master-card .cont p {
    font-size: 16px;
  }
  .payment-end .payment-method form .master-card label {
    width: 90%;
  }
  .payment-end .payment-method form .master-card label input {
    font-size: 14px;
  }
  .payment-end .payment-method form .master-card span {
    width: 90%;
    font-size: 14px;
  }
  .payment-end .payment-method form button {
    width: 86%;
    margin-top: 20px;
  }
  .payment-end .payment-method form .regulamin {
    width: 90%;
    margin-bottom: 0;
  }
  .payment-end .payment-method .payment-header {
    padding: 30px 0 20px;
  }
  .payment-end .payment-nav .payment {
    padding: 60px 10px;
    font-size: 12px;
  }
  .payment-end .payment-nav .payment .subtotal-price {
    font-size: 14px;
  }
  .payment-end .payment-nav .payment .btn-dark {
    padding: 10px 0;
  }
  .payment-end .payment-nav .payment p {
    font-size: 12px;
  }
  .payment-end .payment-nav .payment .orders.active .order-row {
    height: 110px;
    padding: 5px 0;
  }
  .payment-end .payment-nav .payment .orders.active .order-row .order-value {
    font-size: 14px;
  }
  .payment-end .payment-nav .payment .orders.active .order-row .order-content img {
    max-width: 90px;
  }
  .payment-end .payment-nav .payment .orders.active .order-row .order-content .order-description {
    padding-left: 5px;
  }
  .payment-end .payment-nav .payment .orders.active .order-row .order-content p {
    font-size: 12px;
  }
  .payment-end .payment-nav .payment .orders.active .order-row .order-content .order-name {
    margin-bottom: 10px;
  }
  .product-details .row {
    padding: 20px 40px;
  }
  .product-details .row .main .main-content {
    padding: 20px 40px 20px 20px;
  }
  .product-details .close-up .box-img {
    max-width: 480px;
    max-height: 700px;
  }
  header .container .navbar.scroll .collapse .navbar-icons .account {
    top: 115px;
  }
  header .container .navbar.scroll .collapse .navbar-icons .cart {
    top: 110px;
  }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (max-width: 767px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
  .showroom .row .showroom-content .border-div .img-content img {
    height: 250px;
  }
  .photo-banner .container img {
    height: 45vh;
  }
  .black-banner .container .row-padding {
    padding: 20px 50px;
  }
  .black-banner .container .row-padding .col-md-4 {
    max-height: 500px;
    padding: 10px;
  }
  .black-banner .container .row-padding .col-md-4 .box-img {
    margin: auto;
    width: 80%;
    height: 100%;
  }
  .black-banner .container .row-padding .col-md-4 .box-img img {
    width: 100%;
  }
  footer .container {
    padding: 0;
  }
  footer .container .row-padding {
    width: 100%;
  }
  footer .container .row-padding .col-flex-2 {
    min-height: 50px;
    padding: 30px 0;
  }
  footer .container .row-padding .col-flex-2 .sign-up {
    font-size: 14px;
  }
  footer .container .row-padding .col-flex-2 p {
    padding-bottom: 0;
    font-size: 12px;
  }
  footer .container .row-padding .col-flex-2 a {
    font-size: 12px;
  }
  footer .container .row-padding .col-flex-2 .footer-img {
    height: 100%;
    object-fit: contain;
    padding: 0;
  }
  footer .container .row-padding .col-flex {
    text-align: left;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px 0;
    font-size: 12px;
  }
  footer .container .row-padding .col-flex p {
    text-align: left;
    margin-right: 5px;
  }
  footer .container .row-padding .col-flex a {
    margin-top: 5px;
    font-size: 12px;
    text-align: center;
  }
  footer .container .row-padding .col-flex .black-font {
    margin-bottom: 5px;
    font-size: 14px;
  }
  footer .container .row-padding .col-flex .gray-font {
    text-align: left;
  }
  footer .container .row-padding .showroom .showroom-text p {
    font-size: 14px;
  }
  footer .container .row-padding .showroom .showroom-text a {
    font-size: 12px;
    padding-right: 5px;
    margin-top: 5px;
  }
  footer .container .row-padding .showroom .read-more {
    margin: 6px 0 0 0;
  }
  footer .container .row-padding .showroom .read-more img {
    width: 25px;
    top: 65%;
  }
  footer .container .row-padding .social .contact p {
    margin-bottom: 5px;
  }
  footer .container .row-padding .social .visit-us p {
    margin-bottom: 10px;
  }
  footer .container .row-padding .social .visit-us .social-media {
    margin-bottom: 0;
  }
  footer .container .copy-write .gray-font {
    padding-left: 0;
    font-size: 12px;
  }
  .personal-stylist {
    height: 75vw;
  }
  .personal-stylist .black-banner .container .row-padding {
    padding: 20px 60px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .text-row {
    height: 12%;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .text-row h2 {
    font-size: 28px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .text-row p {
    font-size: 14px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-5 .box-img .text-content {
    width: 100%;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-5 .box-img .text-content a {
    font-size: 28px;
    padding: 5px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-5 .box-img .text-content a .product-price {
    padding: 5px;
    font-size: 16px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-3 {
    padding-left: 10px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-3 .text-content {
    top: 65%;
    width: 100%;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-3 .text-content a {
    font-size: 12px;
    padding-bottom: 3px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-3 .text-content a .product-price {
    font-size: 14px;
  }
  .personal-stylist .black-banner .container .row-padding .stylist-arrow {
    top: 54%;
    width: 60px;
  }
  .personal-stylist .black-banner .container .row-padding .stylist-left-arrow {
    left: 2%;
  }
  .personal-stylist .black-banner .container .row-padding .stylist-left-arrow:hover {
    left: 1%;
  }
  .personal-stylist .black-banner .container .row-padding .stylist-right-arrow {
    right: 2%;
  }
  .personal-stylist .black-banner .container .row-padding .stylist-right-arrow:hover {
    right: 1%;
  }
  .photo-banner .container .photo-arrow {
    width: 22px;
    height: 48px;
  }
  .photo-banner .container .photo-content {
    top: 18%;
  }
  .photo-banner .container .photo-content .photo-title {
    font-size: 45px;
    margin-bottom: 15px;
  }
  .photo-banner .container .photo-content .underline-text {
    font-size: 12px;
  }
  .photo-banner .container .photo-content-second {
    top: 45%;
  }
  .photo-banner .container .photo-content-second p {
    font-size: 14px;
  }
  .photo-banner .container .photo-content-second .photo-title-smaller {
    font-size: 40px;
  }
  .photo-banner .container .text-content.about a {
    font-size: 16px;
  }
  .photo-banner .container .text-content.about a .bigger {
    padding-top: 15px;
    font-size: 24px;
  }
  .photo-banner h2 {
    font-size: 48px;
  }
  .new-collection {
    padding-top: 30px;
    padding-bottom: 0;
  }
  .new-collection .row-padding {
    padding: 20px 30px;
    justify-content: space-between;
  }
  .new-collection .row-padding .col-md-4 {
    max-width: 48%;
  }
  .new-collection .row-padding .col-md-8 {
    padding: 0;
    margin-bottom: 20px;
  }
  .new-collection .row-padding .col-md-8 .box-v2 {
    padding-bottom: 0;
  }
  .new-collection .row-padding .col-md-8 .box-v2 .text-content {
    height: 25%;
  }
  .new-collection .row-padding .col-md-8 .box-v2 .text-content .new-collection-header {
    font-size: 14px;
  }
  .new-collection .row-padding .col-md-8 .box-v2 .box-img2 {
    width: 100%;
    height: 75%;
    margin-top: 0;
    overflow: hidden;
  }
  .new-collection .row-padding .col-md-8 .box-v2 .box-text {
    bottom: -1%;
  }
  .new-collection .row-padding .col-md-8 .box-v2 .box-text a {
    font-size: 14px;
  }
  .new-collection .row-padding .prl-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .new-collection .row-padding .pl-0 {
    padding-right: 0;
  }
  .new-collection .row-padding .pr-0 {
    padding: 0;
  }
  .new-collection .row-padding .first {
    padding-right: 10px;
  }
  .new-collection .row-padding .second {
    padding: 0 5px;
  }
  .new-collection .row-padding .third {
    padding-left: 10px;
  }
  .new-collection .container .row-border {
    padding: 0px 20px 30px;
  }
  .shop-new-collection {
    padding: 40px 0;
  }
  .shop-new-collection .container .row .col-12 {
    margin-top: 40px;
  }
  .shop-new-collection .container .row .col-12 .box {
    max-width: 50%;
  }
  .shop-new-collection .container .row .col-12 .box a {
    height: 88%;
  }
  .shop-new-collection .container .row .col-12 .box .text-content {
    height: 12%;
  }
  .shop-new-collection .container .row .col-sm-6 .box {
    max-width: 80%;
  }
  .shop-new-collection .container .row .head-text {
    font-size: 16px;
  }
  .shop-product .container .row {
    padding: 40px 0 20px 0;
  }
  .shop-product .container .row .col-xxl-2 a {
    height: 80%;
  }
  .shop-product .container .row .col-xxl-2 .text-content {
    height: 20%;
    padding-bottom: 20px;
  }
  .shop-product .container .row .col-xxl-2 .text-content a {
    align-content: flex-start;
  }
  .login-container {
    flex-wrap: wrap;
  }
  .login-container .login {
    width: 100%;
    padding: 0 15%;
    height: 50%;
  }
  .login-container .login form {
    height: 60%;
  }
  .login-container .login form button {
    margin: 15px 0;
  }
  .login-container .login form a {
    margin: 0 0 10px 0;
  }
  .login-container .register {
    padding: 0 15%;
    border-left: none;
    width: 100%;
    height: 50%;
  }
  .register-container div.login #login-form, .register-container div.login #register-form, .register-container div.register #login-form, .register-container div.register #register-form {
    position: relative;
    width: 80%;
  }
  main {
    margin-top: 120px;
  }
  .register-container {
    flex-wrap: wrap;
    height: auto;
  }
  .register-container .login {
    width: 100%;
    padding: 0;
    height: auto;
  }
  .register-container .login #register-form {
    height: 60%;
  }
  .register-container div.register {
    padding: 0;
    border-left: none;
    width: 100%;
  }
  .register-container div.register form {
    height: 80%;
  }
  .shop-all .container .row {
    padding: 0 60px;
  }
  .shop-all .container .row .box {
    margin-bottom: 40px;
    padding: 0 5px;
  }
  .shop-all .container .shop-all-header {
    font-size: 28px;
  }
  .shop-all .container .blocks-nav button {
    width: 30px;
    height: 30px;
    margin: 10px;
  }
  .about .container .ceo {
    margin: 0 20px;
  }
  .about .container .ceo .col-12 .box .text-content {
    font-size: 14px;
  }
  .about .container .stylish-everyday {
    margin: 20px;
  }
  .about .container .stylish-everyday .text-content {
    padding: 20px 0;
  }
  .about .container .stylish-everyday .text-content h4 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .about .container .stylish-everyday .text-content p {
    font-size: 14px;
  }
  .about .container .stylish-everyday a {
    font-size: 16px;
  }
  .about .container .about-header {
    font-size: 20px;
    padding: 20px 0px;
  }
  .about .container .about-header.bottom {
    padding-bottom: 20px;
  }
  .my-data .container .row .account-nav {
    position: fixed;
    left: 0;
    top: 64px;
    border-bottom: 1px solid black;
    transform: translateY(-100%);
    background-color: white;
    justify-content: flex-start;
    z-index: 10;
    display: flex;
    width: 100%;
    margin: 0;
  }
  .my-data .container .row .account-nav .data {
    max-width: 100%;
    position: relative;
    width: 100%;
    margin: 0;
    display: flex;
    border: none;
    border-top: none;
    padding: 0;
    background-color: white;
    flex-wrap: nowrap;
    justify-content: left;
  }
  .my-data .container .row .account-nav .data p {
    width: auto;
    margin: 10px 0 10px 10px;
    display: flex;
    padding-top: 0;
  }
  .my-data .container .row .account-nav .data p a {
    width: 100%;
    line-height: 1;
  }
  .my-data .container .row .account-nav .data .account-nav-btn {
    position: absolute;
    top: 100%;
    left: 0;
    justify-content: center;
    align-content: center;
    background-color: white;
    display: flex;
    height: 22px;
    border: 1px solid black;
  }
  .my-data .container .row .account-nav .data .account-nav-btn img {
    height: 100%;
    padding: 2px;
  }
  .my-data .container .row .account-nav .data .account-nav-btn:hover {
    transform: scale(1.1);
  }
  .my-data .container .row .account-nav .data .account-nav-btn.rotate {
    top: 100%;
    transform: rotate(180deg);
  }
  .my-data .container .row .account-nav .data .account-nav-btn.rotate:hover {
    transform: rotate(180deg) scale(1.1);
  }
  .my-data .container .row .account-nav.show {
    left: 0px;
    width: 100%;
    height: auto;
    transform: translateY(0);
  }
  .my-data .container .row .panel {
    padding: 30px 20px;
  }
  .my-data .container .row .panel input {
    margin: 2px;
  }
  .my-data .container .row .panel .panel-header {
    margin: 20px 0 70px 0;
  }
  .my-data .container .row .panel .panel-header.orders {
    margin: 20px 0 40px 0;
  }
  .my-data .container .row .panel form .newsletter {
    padding: 0;
  }
  .my-data .container .row .panel form .newsletter div button {
    padding: 10px 70px;
  }
  .my-data .container .row .panel .order-row {
    padding: 30px 0 30px 0;
    margin-right: 0;
    font-size: 14px;
  }
  .my-data .container .row .panel .order-row .order-descriptions .delivery span img {
    max-width: 20px;
    max-height: 20px;
  }
  .my-data .container .row .panel .order-row .order .order-number p {
    font-size: 14px;
  }
  .my-data .container .row .panel .summary {
    height: 200px;
  }
  .my-data .container .row .panel .summary .summary-data h4 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .my-data .container .row .panel .summary .summary-data p {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .my-data .container .row .panel .summary .summary-price h5 {
    font-size: 14px;
  }
  .my-data .container .row .panel .summary .summary-price h4 {
    font-size: 18px;
  }
  .my-data .container .row .panel .summary .summary-price p {
    font-size: 12px;
  }
  .my-data .container .row .panel .returns-content {
    margin-bottom: 20px;
    padding-right: 0;
  }
  .my-data .container .row .panel .returns-instruction-btn {
    top: 75px;
    right: 20px;
  }
  .my-data .container .row .panel .see-more {
    padding: 15px 80px;
    margin: 40px 0 0 100px;
    font-size: 14px;
  }
  .my-data .container .row .panel .return-addres {
    font-size: 14px;
  }
  .my-data .container .row .panel .return-addres h5 {
    margin: 40px 0 15px;
    font-size: 18px;
  }
  .my-data .container .row .panel .return-addres .uppercase {
    margin-bottom: 10px;
  }
  .my-data .container .instruction-content {
    width: 560px;
    padding: 35px 40px;
    overflow-y: scroll;
  }
  .my-data .container .instruction-content::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }
  .my-data .container .instruction-content::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 5px;
  }
  .my-data .container .instruction-content::-webkit-scrollbar-thumb:hover {
    display: block;
  }
  .my-data .container .instruction-content::-webkit-scrollbar-track {
    background: white;
    border-radius: 5px;
    border-left: none;
    margin: 0 0;
  }
  footer .container .row-padding .col-flex-2 p,
  footer .container .row-padding .showroom .showroom-text a,
  footer .container .row-padding .col-flex a {
    color: #000;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-lg-4 {
    padding: 0;
  }
  .my-data .container .row .account-nav.scroll {
    top: 44px;
  }
  .shopping-cart .shopping {
    padding: 30px 20px 10px;
    font-size: 12px;
  }
  .shopping-cart .shopping .item-row {
    padding: 20px 0;
    height: 250px;
  }
  .shopping-cart .shopping .item-row .item-content img {
    margin-right: 15px;
  }
  .shopping-cart .shopping .item-row .item-content .item-description .dropdown button {
    font-size: 14px;
  }
  .shopping-cart .shopping .item-row .item-value p {
    padding-bottom: 8px;
  }
  .shopping-cart .shopping .information {
    margin-top: 30px;
    margin-bottom: 10px;
    padding: 30px;
  }
  .shopping-cart .shopping-card {
    border-bottom: 1px solid black;
    position: static;
    z-index: 4;
    width: 100%;
    height: auto;
    overflow-y: auto;
    overflow-x: visible;
    align-content: center;
    background-color: white;
  }
  .shopping-cart .shopping-card::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }
  .shopping-cart .shopping-card::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 5px;
  }
  .shopping-cart .shopping-card::-webkit-scrollbar-thumb:hover {
    display: block;
  }
  .shopping-cart .shopping-card::-webkit-scrollbar-track {
    background: white;
    border-radius: 5px;
    border-left: none;
    margin: 0 0;
  }
  .shopping-cart .shopping-card .payment {
    position: static;
    font-size: 12px;
  }
  .shopping-cart .shopping-card .payment .payment-forms p {
    font-size: 12px;
  }
  .shopping-cart .shopping-card .payment .payment-forms a {
    padding-right: 5px;
  }
  .shopping-cart .shopping-card .payment .payment-forms a img {
    transform: scale(0.7);
  }
  .shopping-cart .shopping-card .payment .payment-forms a:hover img {
    transform: scale(0.75);
  }
  .shopping-cart .shopping-card.active {
    right: 0;
  }
  .shopping-cart h4 {
    font-size: 14px;
  }
  .shopping-cart .shopping-nav-btn {
    position: fixed;
    top: 30%;
    right: -1px;
    justify-content: center;
    align-content: center;
    background-color: white;
    display: flex;
    height: 30px;
    width: 30px;
    border: 1px solid black;
  }
  .shopping-cart .shopping-nav-btn img {
    height: 100%;
    padding: 2px;
    transform: rotate(90deg);
  }
  .shopping-cart .shopping-nav-btn:hover {
    transform: scaleY(1.1);
  }
  .shopping-cart .shopping-nav-btn.rotate {
    transform: rotate(180deg);
    right: 229px;
  }
  .shopping-cart .shopping-nav-btn.rotate:hover {
    transform: rotate(180deg) scaleY(1.1);
  }
  .shopping-cart .shopping-card.choices {
    right: -250px;
  }
  .shopping-cart .shopping-card.choices.active {
    right: 0;
  }
  .choices .confirm-returned-info {
    padding: 30px;
  }
  .choices .confirm-returned-info h5 {
    font-size: 18px;
    padding: 25px 5px 30px;
  }
  .choices .confirm-returned-info a {
    font-size: 14px;
  }
  .newsletter2-popap.active .product-popap-content,
  .newsletter-popap.active .product-popap-content,
  .table-popup.active .product-popap-content,
  .product-popap.active .product-popap-content {
    padding: 15px 20px;
  }
  .newsletter2-popap.active .product-popap-content .product-describe,
  .newsletter-popap.active .product-popap-content .product-describe,
  .table-popup.active .product-popap-content .product-describe,
  .product-popap.active .product-popap-content .product-describe {
    padding: 10px 0;
  }
  .newsletter2-popap.active .product-popap-content a,
  .newsletter-popap.active .product-popap-content a,
  .table-popup.active .product-popap-content a,
  .product-popap.active .product-popap-content a {
    font-size: 12px;
    padding: 10px 0;
  }
  .newsletter2-popap.active .product-popap-content a button,
  .newsletter-popap.active .product-popap-content a button,
  .table-popup.active .product-popap-content a button,
  .product-popap.active .product-popap-content a button {
    padding: 12px;
  }
  .newsletter2-popap.active h3,
  .newsletter-popap.active h3,
  .table-popup.active h3,
  .product-popap.active h3 {
    padding: 10px 0;
    font-size: 16px;
  }
  .empty-cart .empty-content {
    padding: 60px 0;
  }
  .empty-cart .empty-content img {
    margin-bottom: 20px;
    transform: scale(0.8);
  }
  .empty-cart .empty-content h2 {
    font-size: 32px;
    margin: 0 0 15px 0;
  }
  .empty-cart .empty-content button a {
    padding: 18px 0;
  }
  .message form h4 {
    margin: 10px;
  }
  .message form label {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .message form label input {
    max-width: 100%;
  }
  .message form input {
    padding: 16px;
    margin: 5px;
  }
  .message form textarea {
    margin: 5px;
    padding: 16px;
  }
  .message form .permission {
    flex-wrap: nowrap;
    margin: 15px 5px;
  }
  .message form input[type="submit"],
  .message form button {
    margin: 5px 5px 10px 5px;
    padding: 16px;
  }
  .showroom .row {
    margin: 20px 30px;
  }
  .showroom .row .showroom-content {
    padding: 0;
    margin: 15px 0;
  }
  .showroom .row .showroom-content .border-div .text-content {
    padding-left: 15px;
  }
  .showroom .row .showroom-content .border-div .text-content h4 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .showroom .row .showroom-content .border-div .text-content p {
    line-height: 1.5;
    margin-bottom: 5px;
    font-size: 12px;
  }
  .showroom h2 {
    font-size: 24px;
    line-height: 1.2;
    margin: 15px 0;
  }
  .showroom p {
    font-size: 12px;
    margin-bottom: 15px;
  }
  .payment-end .payment-method {
    padding: 10px 20px;
  }
  .payment-end .payment-method h2 {
    padding: 20px 0;
  }
  .payment-end .payment-method form .adres .resp {
    flex-wrap: wrap;
  }
  .payment-end .payment-nav {
    position: fixed;
    right: -250px;
    background-color: white;
    height: calc(100% - 84px);
    width: 250px;
    z-index: 11;
  }
  .payment-end .payment-nav .payment {
    width: auto;
    padding: 50px 10px 5px 10px;
  }
  .payment-end .payment-nav.active {
    right: 0;
    overflow-y: auto;
  }
  .payment-end .payment-nav-btn {
    position: fixed;
    right: -1px;
    border: 1px solid black;
    top: 300px;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 30px;
    width: 30px;
    padding: 0;
    background-color: white;
    transform: rotate(90deg);
    z-index: 11;
  }
  .payment-end .payment-nav-btn img {
    height: 100%;
    padding: 2px;
  }
  .payment-end .payment-nav-btn:hover {
    transform: scaleY(1.1) rotate(90deg);
  }
  .payment-end .payment-nav-btn.rotate {
    right: 249px;
    transform: rotate(270deg);
  }
  .product-details .row {
    padding: 20px 20px 0;
  }
  .product-details .row .side {
    padding-right: 5px;
  }
  .product-details .row .side .first {
    padding-bottom: 5px;
  }
  .product-details .row .side .second {
    padding: 1.25px 0;
  }
  .product-details .row .side .third {
    padding-top: 5px;
  }
  .product-details .row .main .main-content {
    padding: 15px;
  }
  .product-details .row .main .main-content form {
    margin-bottom: 10px;
  }
  .product-details .row .main .main-content form .chose-color .checkmark {
    width: 20px;
    height: 20px;
  }
  .product-details .row .main .main-content form .chose-color .checkbox-container .checkmark::after {
    width: 18px;
    height: 18px;
  }
  .product-details .row .main .main-content form .space-beetwen p {
    margin-bottom: 0;
  }
  .product-details .row .main .main-content h3 {
    font-size: 16px;
  }
  .product-details .row .main .main-content p {
    font-size: 16px;
    margin: 5px 0 5px 0;
  }
  .product-details .row .main .main-content .share p {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .product-details .row .main .main-content .about-product {
    margin: 5px 0 10px;
  }
  .product-details .row .main .main-content .about-product p {
    margin: 5px 0;
  }
  .product-details .row .main .main-content .price {
    margin: 5px 0 10px;
  }
  .product-details .lack-of-product {
    width: 350px;
    height: 200px;
    padding: 30px;
  }
  .product-details .lack-of-product form input {
    padding: 15px;
  }
  .product-details .lack-of-product form button {
    padding: 15px;
  }
  .product-details .lack-of-product h4 {
    font-size: 18px;
  }
  .product-details .lack-of-product .close-lack-popap {
    right: -15px;
    top: -20px;
  }
  .product-details .close-up .box-img {
    max-width: 400px;
    max-height: 600px;
  }
  .product-details .close-up .box-img img {
    object-fit: contain;
  }
  .product-details .close-up button img {
    width: 30px;
    height: 30px;
  }
  .product-details .close-up .prev-img {
    left: -65px;
  }
  .product-details .close-up .next-img {
    right: -65px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 575px) {
  .promo-strap p {
    font-size: 12px;
    letter-spacing: 0.3px;
  }
  .black-banner .container .row-padding {
    padding: 20px 60px;
  }
  .black-banner .container .row-padding .col-md-4 {
    max-height: 300px;
  }
  footer {
    z-index: 2;
  }
  footer .container .row-padding {
    padding: 0 20px;
  }
  footer .container .row-padding .col-flex-2 {
    padding: 15px 0;
  }
  footer .container .row-padding .showroom .showroom-text {
    width: 100%;
  }
  footer .container .row-padding .showroom .read-more a {
    margin-top: 0;
  }
  footer .container .row-padding .showroom .read-more img {
    top: 52%;
  }
  footer .container .row-padding .social .contact p {
    margin-bottom: 5px;
  }
  footer .container .row-padding .social .visit-us {
    margin-top: 5px;
  }
  footer .container .row-padding .social .visit-us p {
    margin-bottom: 0;
  }
  footer .container .row-padding .social .visit-us .social-media {
    margin-bottom: 0;
  }
  footer .container .row-padding .social .visit-us .social-media a {
    height: 20px;
  }
  .photo-banner .container .photo-arrow {
    height: 30px;
    width: 20px;
  }
  .photo-banner .container .photo-content {
    top: 22%;
  }
  .photo-banner .container .photo-content .photo-title {
    font-size: 35px;
    margin-bottom: 10px;
  }
  .photo-banner .container .photo-content .underline-text {
    font-size: 12px;
  }
  .photo-banner .container .photo-content-second {
    top: 45%;
  }
  .photo-banner .container .photo-content-second p {
    font-size: 12px;
    margin-bottom: 5px;
  }
  .photo-banner .container .photo-content-second .photo-title-smaller {
    font-size: 30px;
  }
  .photo-banner .container .text-content.about {
    bottom: 20%;
  }
  .photo-banner .container .text-content.about a {
    font-size: 12px;
  }
  .photo-banner .container .text-content.about a .bigger {
    padding-top: 5px;
    font-size: 20px;
  }
  .personal-stylist {
    height: 900px;
  }
  .personal-stylist .black-banner .container .row-padding {
    padding: 20px 60px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide {
    align-content: center;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .text-row {
    height: 8%;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .text-row h2 {
    padding-top: 5px;
    font-size: 22px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .text-row p {
    font-size: 12px;
    padding: 10px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-4 {
    padding-right: 0;
    margin-bottom: 10px;
    height: 25%;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-4 .box-img {
    height: 100%;
    margin-top: 20px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-4 .box-img .text-content {
    width: 100%;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-4 .box-img .text-content a {
    font-size: 18px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-4 .box-img .text-content a .product-price {
    font-size: 16px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-3 {
    height: 40%;
    padding-left: 0;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-3 .text-content {
    top: 60%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-3 .text-content a {
    font-size: 22px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-3 .text-content a .product-price {
    padding: 5px;
    font-size: 16px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-3 .box-img {
    height: 50%;
    max-height: 50%;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-3 .mb {
    margin-bottom: 5px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-3 .mt {
    margin-top: 5px;
  }
  .personal-stylist .black-banner .container .row-padding .stylist-arrow {
    top: 55%;
  }
  .new-collection {
    padding-top: 10px;
  }
  .new-collection .row-padding {
    padding: 20px;
    padding-bottom: 0;
  }
  .new-collection .row-padding .col-md-8 {
    margin-bottom: 15px;
  }
  .new-collection .row-padding .col-md-8 .box-v2 .text-content {
    height: 35%;
  }
  .new-collection .row-padding .col-md-8 .box-v2 .text-content .new-collection-header {
    font-size: 14px;
  }
  .new-collection .row-padding .col-md-8 .box-v2 .box-img2 {
    height: 65%;
  }
  .new-collection .row-padding .box-v2 .box-text {
    bottom: 5%;
  }
  .new-collection .row-padding .box-v2 .box-text a {
    font-size: 14px;
  }
  .new-collection .row-padding .intrested-col {
    max-width: 60%;
    padding: 0;
  }
  .new-collection .container .row-border {
    justify-content: center;
  }
  .new-collection .container .row-border h3 {
    text-align: center;
  }
  header .container .navbar {
    padding: 15px 30px;
  }
  header .container .navbar .navbar-toggler {
    padding: 0;
  }
  header .container .navbar .navbar-title-img {
    left: 49%;
  }
  header .container .navbar .collapse .navbar-icons .account {
    right: 24px;
  }
  header .container .navbar .collapse .navbar-icons .cart {
    right: -33px;
  }
  .shop-new-collection .container .row .col-12 {
    margin-top: 30px;
    padding: 0;
  }
  .shop-new-collection .container .row .col-12 .box {
    max-width: 70%;
  }
  .shop-new-collection .container .row .col-12 .box a {
    height: auto;
  }
  .shop-new-collection .container .row .col-12 .box .text-content {
    height: auto;
  }
  .shop-new-collection .container .row .col-sm-6 .box {
    max-width: 70%;
  }
  .shop-new-collection .container .row .head-text {
    font-size: 14px;
  }
  .shop-new-collection .container .row .right, .shop-new-collection .container .row .left {
    justify-content: center;
  }
  .panel-path {
    display: none;
  }
  .product-details .close-up {
    width: 80%;
  }
  .shop-product .container .row {
    padding: 40px 0 20px 0;
  }
  .shop-product .container .row .col-xxl-2 a {
    height: 85%;
  }
  .shop-product .container .row .col-xxl-2 .text-content {
    height: 15%;
    padding-bottom: 0px;
  }
  .shop-product .container .row .col-xxl-2 .text-content a {
    align-content: flex-start;
  }
  .login-container .login {
    padding: 0 2%;
  }
  .login-container .login form {
    height: 90%;
  }
  .login-container .login form h4 {
    margin-bottom: 20px;
  }
  .login-container .login form button {
    margin: 10px 0;
  }
  .login-container .register {
    padding: 0 2%;
  }
  .login-container .register form {
    height: 65%;
  }
  .login-container .register form h4 {
    margin-bottom: 20px;
  }
  .register-container {
    flex-wrap: wrap;
  }
  .register-container .login {
    width: 100%;
    padding: 0 2%;
  }
  .register-container .login #register-form h4 {
    margin-bottom: 10px;
  }
  .register-container .login #register-form button {
    height: 40px;
    margin: 10px 0;
  }
  .register-container .register {
    padding: 0 2%;
    border-left: none;
    width: 100%;
    height: 70%;
  }
  .register-container .register form {
    height: 80%;
  }
  .register-container .register form input {
    padding: 5px;
  }
  .register-container .register form h4 {
    margin-bottom: 10px;
  }
  .register-container .register form .permission {
    margin: 10px 0;
  }
  .register-container .register form button {
    height: 40px;
    margin: 10px 0;
  }
  .shop-all .container .panel-path,
  .shop-all .container .row {
    padding: 0 40px;
  }
  .shop-all .container .panel-path .box,
  .shop-all .container .row .box {
    margin-bottom: 30px;
  }
  .shop-all .container .shop-all-header {
    padding: 20px 0;
  }
  .shop-all .container .blocks-nav button {
    width: 25px;
    height: 25px;
    margin: 5px;
  }
  .about .container .ceo .col-12 .box {
    width: 65%;
  }
  .about .container .ceo .col-12 .box .text-content {
    padding: 10px 0;
  }
  .about .container .stylish-everyday {
    margin: 10px 20px;
  }
  .about .container .stylish-everyday .text-content {
    padding: 15px 0;
  }
  .about .container .stylish-everyday .text-content h4 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .about .container .stylish-everyday .text-content p {
    font-size: 14px;
  }
  .about .container .stylish-everyday a {
    font-size: 14px;
  }
  .about .container .about-header {
    font-size: 16px;
    padding: 10px 0px;
  }
  .about .container .about-header.bottom {
    padding-bottom: 10px;
  }
  .my-data .container .row .panel .panel-header {
    margin: 20px 0 50px 0;
    font-size: 28px;
  }
  .my-data .container .row .panel .panel-header.orders {
    margin: 20px 0 30px 0;
  }
  .my-data .container .row .panel form .newsletter div {
    justify-content: center;
    margin-top: 20px;
  }
  .my-data .container .row .panel form .newsletter div button {
    padding: 10px 50px;
  }
  .my-data .container .row .panel form .newsletter div button:hover {
    transform: scale(1.07);
  }
  .my-data .container .row .panel form .flex-label {
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 1px;
  }
  .my-data .container .row .panel form .flex-label input {
    margin: 0 0 10px 0;
    width: 100%;
    max-width: 100%;
  }
  .my-data .container .row .panel form .adres {
    width: 100%;
    margin-bottom: 22px;
  }
  .my-data .container .row .panel form .adres .resp {
    flex-wrap: wrap;
  }
  .my-data .container .row .panel form .adres .resp .cont {
    margin-bottom: 10px;
  }
  .my-data .container .row .panel form .adres .checkbox-container {
    margin-right: 10px;
  }
  .my-data .container .row .panel form .add-adres label {
    flex-wrap: wrap;
    margin: 0;
  }
  .my-data .container .row .panel form .add-adres label input {
    margin: 0 0 10px 0;
    max-width: 100%;
    width: 100%;
  }
  .my-data .container .row .panel form .invoice-dropdown.visible label {
    margin: 0;
  }
  .my-data .container .row .panel form .invoice-dropdown.visible label input {
    margin-bottom: 10px;
    width: 100%;
  }
  .my-data .container .row .panel form p {
    margin: 20px 0;
  }
  .my-data .container .row .panel form .btn-container {
    height: 40px;
    margin: 30px 0;
  }
  .my-data .container .row .panel form .btn-container button {
    padding: 5px 25px;
  }
  .my-data .container .row .panel form .customer {
    width: 100%;
    margin-bottom: 20px;
  }
  .my-data .container .row .panel form .customer .checkbox-container {
    margin-bottom: 10px;
  }
  .my-data .container .row .panel .order-row {
    height: 250px;
    padding: 20px 0;
    margin-right: 0;
    font-size: 12px;
  }
  .my-data .container .row .panel .order-row .order .order-number p {
    font-size: 12px;
  }
  .my-data .container .row .panel .order-row .order .order-items .box-img {
    margin-right: 10px;
  }
  .my-data .container .row .panel .order-row .order-descriptions .delivery p span {
    display: block;
  }
  .my-data .container .row .panel .summary {
    height: 200px;
  }
  .my-data .container .row .panel .summary .summary-data h4 {
    font-size: 16px;
    margin: 5px 0 15px;
  }
  .my-data .container .row .panel .summary .summary-price {
    padding-left: 5px;
  }
  .my-data .container .row .panel .summary .summary-price h5 {
    font-size: 12px;
    margin: 6px 0;
  }
  .my-data .container .row .panel .summary .summary-price h4 {
    font-size: 16px;
    margin: 8px 0 0;
  }
  .my-data .container .row .panel .return-btn-container {
    height: auto;
  }
  .my-data .container .row .panel .returns-instruction-btn {
    top: 105px;
    right: 20px;
  }
  .my-data .container .row .panel .see-more {
    padding: 12px 60px;
    margin: 30px 0 0 60px;
    font-size: 14px;
  }
  .my-data .container .row .panel .return-addres {
    font-size: 12px;
  }
  .my-data .container .row .panel .return-addres h5 {
    margin: 30px 0 10px;
  }
  .my-data .container .row .panel .return-addres .uppercase {
    margin-bottom: 8px;
  }
  .my-data .container .instruction-content {
    width: 350px;
    padding: 15px 20px;
  }
  .my-data .container .instruction-content button {
    top: 5px;
    right: 5px;
  }
  .my-data .container .instruction-content h4 {
    font-size: 16px;
    line-height: 1.5;
    padding-bottom: 0;
  }
  .my-data .container .instruction-content h5 {
    font-size: 14px;
    padding: 25px 0 10px;
  }
  .my-data .container .instruction-content p {
    font-size: 12px;
    line-height: 1.4;
    padding: 5px 0;
  }
  .my-data .container .instruction-content a {
    font-size: 12px;
    padding: 5px 0 10px;
  }
  .shopping-cart .shopping {
    padding: 30px 20px 10px;
    overflow: visible;
  }
  .shopping-cart .shopping h2 {
    font-size: 28px;
  }
  .shopping-cart .shopping .item-row {
    height: 200px;
  }
  .shopping-cart .shopping .item-row .item-content .item-description .dropdown .dropdown-menu {
    z-index: 10;
    font-size: 12px;
  }
  .shopping-cart .shopping .item-row .item-content .item-description .dropdown .dropdown-menu .dropdown-item {
    z-index: 11;
  }
  .shopping-cart .shopping .item-row .item-content .item-description .dropdown button {
    padding: 2px 8px;
  }
  .shopping-cart .shopping .item-row .item-content img {
    max-width: 110px;
  }
  .shopping-cart .shopping .item-row .item-content a {
    margin-right: 5px;
  }
  .shopping-cart .shopping .item-value p {
    font-size: 14px;
  }
  .shopping-cart .shopping .information {
    margin-top: 20px;
    padding: 25px;
  }
  .choices .select-all img {
    width: 20px;
    height: 20px;
  }
  .choices .item-content .checkbox-btn {
    margin-right: 20px;
  }
  .choices .item-content .checkbox-btn img {
    width: 20px;
    height: 20px;
  }
  .choices .confirm-returned-info {
    padding: 20px;
    width: 300px;
  }
  .choices .confirm-returned-info h5 {
    font-size: 18px;
    padding: 25px 5px 30px;
  }
  .choices .confirm-returned-info a {
    font-size: 14px;
  }
  .newsletter2-popap.active,
  .newsletter-popap.active,
  .table-popup.active,
  .product-popap.active {
    width: 80%;
  }
  .newsletter2-popap.active .product-popap-content,
  .newsletter-popap.active .product-popap-content,
  .table-popup.active .product-popap-content,
  .product-popap.active .product-popap-content {
    padding: 30px 20px;
  }
  .newsletter2-popap.active .product-popap-content .product-describe .box-content,
  .newsletter-popap.active .product-popap-content .product-describe .box-content,
  .table-popup.active .product-popap-content .product-describe .box-content,
  .product-popap.active .product-popap-content .product-describe .box-content {
    padding-left: 10px;
  }
  .newsletter2-popap.active .product-popap-content .product-describe .box-content h4,
  .newsletter-popap.active .product-popap-content .product-describe .box-content h4,
  .table-popup.active .product-popap-content .product-describe .box-content h4,
  .product-popap.active .product-popap-content .product-describe .box-content h4 {
    font-size: 14px;
  }
  .newsletter2-popap.active .product-popap-content .product-describe .box-content span,
  .newsletter-popap.active .product-popap-content .product-describe .box-content span,
  .table-popup.active .product-popap-content .product-describe .box-content span,
  .product-popap.active .product-popap-content .product-describe .box-content span {
    font-size: 14px;
  }
  .newsletter2-popap.active .product-popap-content .product-describe .box-content p,
  .newsletter-popap.active .product-popap-content .product-describe .box-content p,
  .table-popup.active .product-popap-content .product-describe .box-content p,
  .product-popap.active .product-popap-content .product-describe .box-content p {
    font-size: 12px;
  }
  .newsletter2-popap.active .product-popap-content a button,
  .newsletter-popap.active .product-popap-content a button,
  .table-popup.active .product-popap-content a button,
  .product-popap.active .product-popap-content a button {
    padding: 12px 70px;
  }
  .newsletter2-popap.active h3,
  .newsletter-popap.active h3,
  .table-popup.active h3,
  .product-popap.active h3 {
    font-size: 16px;
  }
  .empty-cart .empty-content {
    padding: 40px 0;
  }
  .empty-cart .empty-content img {
    margin-bottom: 10px;
    transform: scale(0.8);
  }
  .empty-cart .empty-content h2 {
    width: 100%;
    text-align: center;
    margin: 10px 0;
    font-size: 26px;
  }
  .empty-cart .empty-content button {
    width: 70%;
    font-size: 16px;
    margin: 15px 0;
  }
  .empty-cart .empty-content button a {
    padding: 12px;
  }
  .empty-cart .empty-content a {
    padding: 10px 0;
    font-size: 14px;
  }
  .empty-cart .empty-content a:hover {
    text-decoration: none;
  }
  .message form {
    max-width: 350px;
  }
  .message form h4 {
    font-size: 20px;
    line-height: 1.5;
  }
  .message form input {
    padding: 12px;
  }
  .message form textarea {
    padding: 12px;
  }
  .message form input[type="submit"],
  .message form button {
    padding: 14px;
  }
  .showroom .row {
    margin: 20px;
  }
  .showroom .row .showroom-content {
    padding: 0;
    margin: 10px 0;
  }
  .showroom .row .showroom-content .border-div {
    flex-wrap: wrap;
  }
  .showroom .row .showroom-content .border-div .text-content {
    padding: 20px;
  }
  .showroom .row .showroom-content .border-div .text-content h4 {
    font-size: 20px;
    text-align: center;
    margin-bottom: 10px;
  }
  .showroom .row .showroom-content .border-div .text-content p {
    text-align: center;
    line-height: 1.5;
    margin-bottom: 5px;
    font-size: 12px;
  }
  .showroom h2 {
    font-size: 18px;
    line-height: 1.2;
    margin: 10px 0;
  }
  .showroom p {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .payment-end .payment-method h2 {
    padding: 15px 0;
    font-size: 24px;
  }
  .payment-end .payment-method h2 p {
    margin: auto 15px auto 0;
  }
  .payment-end .payment-method form .adres {
    flex-wrap: wrap;
  }
  .payment-end .payment-method form .cont {
    padding: 10px;
  }
  .payment-end .payment-method form .cont input {
    margin: auto 5px;
  }
  .payment-end .payment-method form .cont p {
    font-size: 14px;
  }
  .payment-end .payment-method form .cont span {
    font-size: 10px;
  }
  .payment-end .payment-method form .cont .price {
    font-size: 12px;
    width: 60px;
  }
  .payment-end .payment-method form .cont img {
    max-height: 30px;
  }
  .payment-end .payment-method form .wrap-label .delivery-addres {
    padding: 15px 0 10px 34px;
  }
  .payment-end .payment-method form .wrap-label .delivery-addres span {
    font-size: 14px;
  }
  .payment-end .payment-method form .wrap-label .delivery-addres .addres-data {
    font-size: 12px;
  }
  .payment-end .payment-method form .wrap-label .delivery-addres .addres-data a {
    font-size: 12px;
  }
  .payment-end .payment-method form .master-card {
    padding: 10px;
  }
  .payment-end .payment-method form .master-card .cont {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
  }
  .payment-end .payment-method form .master-card .cont input {
    margin: auto 5px;
  }
  .payment-end .payment-method form .master-card .cont img {
    max-height: 30px;
  }
  .payment-end .payment-method form .master-card .cont p {
    font-size: 14px;
  }
  .payment-end .payment-method form .master-card label {
    width: 100%;
  }
  .payment-end .payment-method form .master-card label input {
    font-size: 12px;
  }
  .payment-end .payment-method form .master-card span {
    width: 100%;
    font-size: 12px;
  }
  .payment-end .payment-method form button p {
    font-size: 12px;
  }
  .payment-end .payment-method form button p span {
    font-size: 12px;
  }
  .payment-end .payment-method form .regulamin {
    font-size: 10px;
    width: 100%;
  }
  .product-details .row .side {
    display: none;
  }
  .product-details .row .main {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
  .product-details .row .main .box-img {
    width: 60%;
  }
  .product-details .row .main .box-img img {
    display: none;
  }
  .product-details .row .main .box-img .slider {
    display: block;
  }
  .product-details .row .main .box-img .slider .slick-list .slick-track {
    display: flex;
    align-self: center;
    align-items: center;
    align-content: center;
  }
  .product-details .row .main .box-img .slider .slick-list .slick-track img {
    display: block;
    margin-right: 10px;
    width: 100%;
    height: 100%;
  }
  .product-details .row .main .box-img .slider .slick-list .slick-track img:hover {
    transform: scale(1.07);
  }
  .product-details .row .main .box-img .slider .slick-list:hover img {
    transform: scale(1);
  }
  .product-details .row .main .main-content {
    padding: 20px 0;
    border: none;
    width: 100%;
  }
  .product-details .close-up .box-img {
    max-height: 500px;
    max-width: 320px;
    width: auto;
  }
  .product-details .close-up .box-img img {
    object-fit: cover;
    width: 100%;
  }
  .product-details .close-up button img {
    width: 25px;
    height: 25px;
  }
  .product-details .close-up .prev-img {
    left: -30px;
  }
  .product-details .close-up .next-img {
    right: -30px;
  }
  .shop-all .container .row .box {
    max-height: none;
  }
}

@media (max-width: 375px) {
  .promo-strap p {
    font-size: 12px;
    letter-spacing: -0.5px;
  }
  .black-banner .container .row-padding .col-md-4 {
    max-height: 500px;
  }
  .black-banner .container .row-padding .col-md-4 .box-img {
    margin: auto;
    width: 100%;
    height: 100%;
  }
  .black-banner .container .row-padding .col-md-4 .box-img img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  .black-banner .container .row-padding .col-md-4 .subtitle {
    font-size: 22px;
  }
  footer .container .row-padding {
    padding: 10px 20px;
  }
  footer .container .row-padding .showroom .read-more img {
    display: none;
  }
  .photo-banner .container .photo-content {
    top: 15%;
  }
  .photo-banner .container .photo-content .photo-title {
    font-size: 26px;
    margin-bottom: 5px;
  }
  .photo-banner .container .photo-content .photo-arrow {
    width: 12px;
    height: 30px;
  }
  .photo-banner .container .photo-content .underline-text {
    font-size: 12px;
    margin-bottom: 5px;
  }
  .photo-banner .container .photo-content-second {
    top: 40%;
  }
  .photo-banner .container .photo-content-second p {
    font-size: 12px;
    margin-bottom: 5px;
  }
  .photo-banner .container .photo-content-second .photo-title-smaller {
    font-size: 24px;
  }
  .photo-banner .container .text-content.about {
    bottom: 20%;
  }
  .photo-banner .container .text-content.about a {
    font-size: 12px;
  }
  .photo-banner .container .text-content.about a .bigger {
    padding-top: 5px;
    font-size: 16px;
  }
  .photo-banner h2 {
    font-size: 42px;
  }
  .personal-stylist .black-banner .container .row-padding {
    padding: 20px 40px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .text-row h2 {
    font-size: 14px;
    margin-bottom: 0;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .text-row p {
    font-size: 10px;
    margin-bottom: 0;
    padding-top: 5px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-5 .box-img .text-content a {
    font-size: 14px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-5 .box-img .text-content a .product-price {
    font-size: 12px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-3 .box-img .text-content {
    top: 55%;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-3 .box-img .text-content a {
    font-size: 14px;
  }
  .personal-stylist .black-banner .container .row-padding .slideshow-container .slide .col-md-3 .box-img .text-content a .product-price {
    font-size: 12px;
  }
  .personal-stylist .black-banner .container .row-padding .stylist-arrow {
    top: 53%;
  }
  .new-collection {
    padding-top: 10px;
  }
  .new-collection .row-padding {
    padding: 20px;
  }
  .new-collection .row-padding .col-md-8 {
    margin-bottom: 10px;
  }
  .new-collection .row-padding .col-md-8 .box-v2 {
    display: flex;
    flex-wrap: wrap;
  }
  .new-collection .row-padding .col-md-8 .box-v2 .text-content .new-collection-header {
    font-size: 12px;
  }
  .new-collection .row-padding .col-md-8 .box-v2 .box-img2 {
    max-height: 50%;
  }
  .new-collection .row-padding .col-md-8 .box-v2 .box-img2 a {
    width: 100%;
  }
  .new-collection .row-padding .col-md-8 .box-v2 .box-img2 img {
    height: 100%;
  }
  .new-collection .row-padding .box-v2 {
    padding-bottom: 50px;
  }
  .new-collection .row-padding .box-v2 .box-text {
    bottom: 0%;
  }
  .new-collection .row-padding .box-v2 .box-text a {
    font-size: 10px;
  }
  .new-collection .row-padding .intrested-col {
    max-width: 100%;
  }
  header .container .navbar {
    padding: 10px 15px;
  }
  header .container .navbar .navbar-title-img {
    left: 49%;
    max-width: 120px;
  }
  header .container .navbar .navbar-title-img img {
    object-fit: contain;
    width: 100%;
  }
  header .container .navbar .collapse .navbar-icons .account {
    right: 12px;
  }
  header .container .navbar .collapse .navbar-icons .cart {
    right: -44px;
  }
  .shop-new-collection .container .row .col-12 {
    margin-top: 20px;
  }
  .shop-new-collection .container .row .head-text {
    font-size: 12px;
  }
  .login-container .register {
    height: 50%;
  }
  .login-container .register form {
    height: 60%;
  }
  .login-container .register form button {
    height: 40px;
    margin: 10px 0;
  }
  .login-container .register form h4 {
    margin-bottom: 10px;
  }
  .login-container .register form .content-adverb {
    width: 100%;
    font-size: 12px;
  }
  .login-container .login form button {
    height: 40px;
    margin: 10px 0;
  }
  .login-container .login form h4 {
    margin-bottom: 10px;
  }
  .register-container .login #register-form {
    height: 70%;
  }
  .register-container .login #register-form button {
    height: 25px;
    margin: 10px 0;
  }
  .register-container .login #register-form h4 {
    margin-bottom: 10px;
  }
  .register-container .register {
    padding: 0 2%;
    border-left: none;
    width: 100%;
  }
  .register-container .register #register-form input {
    padding: 5px;
  }
  .register-container .register #register-form .permission {
    margin: 10px 0;
  }
  .register-container .register #register-form button {
    height: 40px;
    margin: 0 0 10px 0;
  }
  .register-container .register #register-form h4 {
    margin-bottom: 0;
  }
  .register-container .register #register-form .date-id {
    display: block;
  }
  .register-container .register #register-form .date-id #name {
    margin-right: 0;
  }
  .register-container .register #register-form .date-id #surname {
    margin-left: 0;
  }
  .register-container .register #register-form p {
    font-size: 12px;
  }
  .shop-all .container .panel-path,
  .shop-all .container .row {
    padding: 0 25px;
  }
  .shop-all .container .panel-path .box,
  .shop-all .container .row .box {
    margin-bottom: 30px;
  }
  .shop-all .container .blocks-nav button {
    font-size: 14px;
    width: 20px;
    height: 20px;
    margin: 5px;
  }
  .about .container .ceo .col-12 .box {
    width: 85%;
  }
  .about .container .stylish-everyday {
    margin: 10px 20px;
  }
  .about .container .stylish-everyday .text-content {
    padding: 15px 0;
  }
  .about .container .stylish-everyday .text-content h4 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .about .container .stylish-everyday .text-content p {
    font-size: 12px;
  }
  .about .container .stylish-everyday a {
    font-size: 12px;
  }
  .about .container .about-header {
    font-size: 14px;
    padding: 10px 0px;
  }
  .about .container .about-header.bottom {
    padding-bottom: 10px;
  }
  .my-data .container .row .panel .panel-path {
    font-size: 10px;
  }
  .my-data .container .row .panel .panel-path img {
    padding: 0 10px;
    width: 30px;
  }
  .my-data .container .row .panel .panel-header {
    margin-bottom: 30px;
  }
  .my-data .container .row .panel .panel-header.orders {
    margin: 20px 0 15px 0;
  }
  .my-data .container .row .panel form {
    font-size: 12px;
  }
  .my-data .container .row .panel form .gender {
    margin-bottom: 15px;
    width: 100%;
  }
  .my-data .container .row .panel form .gender .checkbox-container {
    font-size: 12px;
    padding-left: 20px;
  }
  .my-data .container .row .panel form .gender .checkbox-container .adres-name {
    font-size: 14px;
  }
  .my-data .container .row .panel form .gender .checkmark {
    height: 15px;
    width: 15px;
  }
  .my-data .container .row .panel form .gender .checkbox-container .checkmark:after {
    width: 13px;
    height: 13px;
    top: 0;
    left: 0;
    border: 1px solid white;
  }
  .my-data .container .row .panel form .newsletter label {
    font-size: 12px;
  }
  .my-data .container .row .panel form .newsletter div button {
    padding: 5px 30px;
  }
  .my-data .container .row .panel form p {
    margin: 10px 0;
  }
  .my-data .container .row .panel form .adres .checkbox-container {
    font-size: 12px;
    padding-left: 20px;
  }
  .my-data .container .row .panel form .adres .checkbox-container .adres-name {
    font-size: 14px;
  }
  .my-data .container .row .panel form .adres .checkmark {
    height: 15px;
    width: 15px;
  }
  .my-data .container .row .panel form .adres .checkbox-container .checkmark:after {
    width: 13px;
    height: 13px;
    top: 0;
    left: 0;
    border: 1px solid white;
  }
  .my-data .container .row .panel form .btn-container {
    height: 30px;
    margin: 20px 0 0 0;
  }
  .my-data .container .row .panel form .btn-container button {
    padding: 5px 15px;
  }
  .my-data .container .row .panel form .customer {
    margin-bottom: 20px;
  }
  .my-data .container .row .panel form .customer .checkbox-container {
    margin-bottom: 20px;
  }
  .my-data .container .row .panel .order-row {
    height: 250px;
    padding: 15px 0;
    margin-right: 0;
    font-size: 10px;
  }
  .my-data .container .row .panel .order-row .order-descriptions {
    padding-left: 5px;
  }
  .my-data .container .row .panel .order-row .order-descriptions .delivery span img {
    max-width: 13px;
    max-height: 13x;
    margin-right: 5px;
  }
  .my-data .container .row .panel .order-row .order .order-items .box-img {
    margin-right: 10px;
  }
  .my-data .container .row .panel .order-row .order .order-number p {
    font-size: 10px;
  }
  .my-data .container .row .panel .summary {
    height: 180px;
  }
  .my-data .container .row .panel .summary .summary-data h4 {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .my-data .container .row .panel .summary .summary-data p {
    font-size: 10px;
    margin-bottom: 5px;
  }
  .my-data .container .row .panel .summary .summary-price span {
    text-align: right;
  }
  .my-data .container .row .panel .summary .summary-price h4 {
    font-size: 14px;
  }
  .my-data .container .row .panel .summary .summary-price p {
    font-size: 10px;
  }
  .my-data .container .row .panel .summary .order-value {
    align-items: flex-end;
    font-size: 12px;
  }
  .my-data .container .row .panel .summary .order-content .order-description {
    padding-left: 10px;
  }
  .my-data .container .row .panel .summary h4 {
    font-size: 16px;
  }
  .my-data .container .row .panel .summary h4 span {
    font-size: 12px;
  }
  .my-data .container .row .panel .return-btn-container {
    height: auto;
  }
  .my-data .container .row .panel .returns-content {
    font-size: 16px;
    margin-bottom: 0;
  }
  .my-data .container .row .panel .returns-content img {
    width: 40px;
    height: 40px;
  }
  .my-data .container .row .panel .returns-content a {
    font-size: 12px;
  }
  .my-data .container .row .panel .returns-instruction-btn {
    top: 100px;
    right: 20px;
    font-size: 12px;
  }
  .my-data .container .row .panel .see-more {
    padding: 10px 40px;
    margin: 20px 0 0 40px;
    font-size: 12px;
  }
  .my-data .container .row .panel .order-stats p {
    font-size: 12px;
  }
  .my-data .container .row .panel .return-addres h5 {
    margin: 20px 0 10px;
    font-size: 16px;
  }
  .my-data .container .row .panel .return-addres .uppercase {
    margin-bottom: 8px;
  }
  .my-data .container .row .panel .return-addres p {
    font-size: 10px;
  }
  .my-data .container .instruction-content {
    width: 260px;
    height: 500px;
  }
  .my-data .container .instruction-content h5 {
    font-size: 14px;
    padding: 15px 0 10px;
  }
  .loged-account-popap {
    width: 100%;
  }
  .loged-account-popap .loged-box {
    padding: 30px;
  }
  .account-popap {
    width: 100%;
  }
  .shopping-cart .shopping {
    padding: 30px 20px 10px;
    overflow: visible;
  }
  .shopping-cart .shopping .item-row {
    height: 150px;
  }
  .shopping-cart .shopping .item-row .item-content .item-description {
    font-size: 10px;
  }
  .shopping-cart .shopping .item-row .item-content .item-description .dropdown .dropdown-menu {
    width: 50px;
    max-width: 50px;
    font-size: 10px;
  }
  .shopping-cart .shopping .item-row .item-content .item-description .dropdown button {
    padding: 5px 1px 3px 5px;
    font-size: 10px;
  }
  .shopping-cart .shopping .item-row .item-content .item-description .dropdown button::after {
    margin: 0 5px;
    vertical-align: 0em;
    transform: scale(0.7);
  }
  .shopping-cart .shopping .item-row .item-content .item-name {
    padding-top: 0;
  }
  .shopping-cart .shopping .item-row .item-content img {
    max-width: 80px;
  }
  .shopping-cart .shopping .item-value p {
    font-size: 12px;
  }
  .shopping-cart .shopping h4 {
    font-size: 12px;
  }
  .shopping-cart .shopping .information {
    margin-top: 15px;
    margin-bottom: 0;
    padding: 10px;
  }
  .shopping-cart .shopping .information h5 {
    font-size: 14px;
    margin-bottom: 20px;
  }
  .shopping-cart .shopping .information p {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .shopping-cart .shopping-card .payment {
    font-size: 12px;
  }
  .shopping-cart .shopping-card .payment .total-price {
    font-size: 13px;
  }
  .choices .choices.item-row .item-content .item-description .chose-reason {
    width: 100%;
    height: 30px;
  }
  .choices .choices.item-row .item-content .item-description .chose-reason select {
    padding: 5px 10px;
  }
  .choices .choices.item-row .item-content .item-description .chose-reason::after {
    width: 15px;
    height: 15px;
  }
  .choices .choices.item-row .item-content .item-description p {
    margin-bottom: 0;
  }
  .choices .choices.item-row .item-content .item-description .item-name {
    font-size: 12px;
  }
  .choices .choices.item-row .item-content .checkbox-btn {
    width: 15px;
    margin-right: 10px;
  }
  .choices .choices.item-row .item-content .checkbox-btn img {
    width: 15px;
    height: 15px;
  }
  .choices .choices.item-row .item-content img {
    margin-right: 10px;
  }
  .choices .select-all img {
    width: 15px;
    height: 15px;
  }
  .choices .confirm-returned-info {
    padding: 15px;
    width: 250px;
  }
  .choices .confirm-returned-info h5 {
    font-size: 16px;
    padding: 15px 5px 20px;
    margin-bottom: 0;
  }
  .choices .confirm-returned-info a {
    font-size: 12px;
  }
  .newsletter2-popap.active,
  .newsletter-popap.active,
  .table-popup.active,
  .product-popap.active {
    width: 100%;
  }
  .newsletter2-popap.active .done img,
  .newsletter-popap.active .done img,
  .table-popup.active .done img,
  .product-popap.active .done img {
    width: 15px;
    height: 15px;
  }
  .newsletter2-popap.active .product-popap-content,
  .newsletter-popap.active .product-popap-content,
  .table-popup.active .product-popap-content,
  .product-popap.active .product-popap-content {
    padding: 0px 10px;
  }
  .newsletter2-popap.active .product-popap-content .product-describe .box-content,
  .newsletter-popap.active .product-popap-content .product-describe .box-content,
  .table-popup.active .product-popap-content .product-describe .box-content,
  .product-popap.active .product-popap-content .product-describe .box-content {
    padding-left: 10px;
  }
  .newsletter2-popap.active .product-popap-content .product-describe .box-content h4,
  .newsletter-popap.active .product-popap-content .product-describe .box-content h4,
  .table-popup.active .product-popap-content .product-describe .box-content h4,
  .product-popap.active .product-popap-content .product-describe .box-content h4 {
    font-size: 12px;
  }
  .newsletter2-popap.active .product-popap-content .product-describe .box-content span,
  .newsletter-popap.active .product-popap-content .product-describe .box-content span,
  .table-popup.active .product-popap-content .product-describe .box-content span,
  .product-popap.active .product-popap-content .product-describe .box-content span {
    font-size: 12px;
  }
  .newsletter2-popap.active .product-popap-content .product-describe .box-content p,
  .newsletter-popap.active .product-popap-content .product-describe .box-content p,
  .table-popup.active .product-popap-content .product-describe .box-content p,
  .product-popap.active .product-popap-content .product-describe .box-content p {
    font-size: 12px;
  }
  .newsletter2-popap.active .product-popap-content a button,
  .newsletter-popap.active .product-popap-content a button,
  .table-popup.active .product-popap-content a button,
  .product-popap.active .product-popap-content a button {
    width: 100%;
    padding: 12px;
  }
  .newsletter2-popap.active h3,
  .newsletter-popap.active h3,
  .table-popup.active h3,
  .product-popap.active h3 {
    padding: 5px 0;
    font-size: 12px;
    line-height: 1.5;
  }
  .empty-cart .empty-content {
    padding: 40px 0;
    width: 100%;
  }
  .empty-cart .empty-content img {
    margin-bottom: 0;
    transform: scale(0.6);
  }
  .empty-cart .empty-content h2 {
    margin: 0 0 10px 0;
    font-size: 18px;
  }
  .empty-cart .empty-content button {
    width: 70%;
    font-size: 14px;
    margin: 10px 0;
  }
  .empty-cart .empty-content button a {
    padding: 10px;
  }
  .empty-cart .empty-content a {
    padding: 10px 0;
    font-size: 12px;
  }
  .empty-cart .empty-content a:hover {
    text-decoration: none;
  }
  .message form {
    max-width: 265px;
  }
  .message form h4 {
    font-size: 18px;
    margin: 10px 0;
    line-height: 1.5;
  }
  .message form input {
    padding: 8px;
  }
  .message form textarea {
    padding: 8px;
  }
  .message form .permission {
    margin: 10px 5px;
  }
  .message form .permission p {
    font-size: 14px;
  }
  .message form input[type="submit"],
  .message form button {
    padding: 8px;
  }
  .showroom .row .showroom-content .border-div .text-content h4 {
    font-size: 20px;
  }
  .showroom .row .showroom-content .border-div .text-content p {
    text-align: center;
    line-height: 1.5;
    margin-bottom: 5px;
    font-size: 12px;
  }
  .showroom h2 {
    font-size: 14px;
    line-height: 1.2;
    margin: 10px 0;
  }
  .showroom p {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .payment-end .payment-method form .wrap-label .delivery-addres {
    padding: 15px 0 10px 0;
  }
  .payment-end .payment-method form .wrap-label .delivery-addres span {
    font-size: 12px;
  }
  .payment-end .payment-method form .wrap-label .delivery-addres p {
    font-size: 12px;
  }
  .payment-end .payment-method form label {
    padding: 5px;
  }
  .payment-end .payment-method form label input {
    margin: auto 0;
  }
  .payment-end .payment-method form label .delivery-type {
    margin: 0 10px 0 5px;
  }
  .payment-end .payment-method form label .delivery-type-x2 {
    margin: 0 5px;
  }
  .payment-end .payment-method form label p {
    font-size: 12px;
  }
  .payment-end .payment-method form label img {
    margin: auto 30px auto 10px;
  }
  .payment-end .payment-method form .master-card .cont {
    padding: 5px;
  }
  .payment-end .payment-method form .master-card .cont input {
    margin: auto 0;
  }
  .payment-end .payment-method form .master-card .cont img {
    margin: auto 30px auto 10px;
  }
  .payment-end .payment-method form .master-card .cont p {
    font-size: 12px;
  }
  .payment-end .payment-method form .master-card label input {
    padding: 10px;
  }
  .payment-end .payment-method form .master-card label #card-number {
    width: 40%;
  }
  .payment-end .payment-method form .master-card label #card-date {
    width: 30%;
  }
  .payment-end .payment-method form .master-card label #card-cvv {
    width: 30%;
  }
  .payment-end .payment-method form .master-card span {
    font-size: 10px;
  }
  .payment-end .payment-method form button p {
    font-size: 10px;
  }
  .payment-end .payment-method form button p span {
    font-size: 10px;
  }
  .payment-end .payment-method .payment-header {
    padding: 10px 0;
  }
  .product-details .row .main .box-img {
    width: 100%;
  }
  .product-details .row .main .box-img img {
    display: none;
  }
  .product-details .row .main .box-img .slider {
    display: block;
  }
  .product-details .row .main .box-img .slider img {
    display: block;
    width: 100%;
    height: 100%;
  }
  .product-details .lack-of-product {
    width: 100%;
    height: 250px;
    padding: 30px;
  }
  .product-details .lack-of-product form {
    align-content: space-around;
  }
  .product-details .lack-of-product form input {
    padding: 10px;
    font-size: 14px;
  }
  .product-details .lack-of-product form button {
    padding: 10px;
    font-size: 14px;
  }
  .product-details .lack-of-product h4 {
    font-size: 14px;
  }
  .product-details .lack-of-product .close-lack-popap {
    right: -15px;
    top: -20px;
  }
  .product-details .close-up .box-img {
    max-height: 400px;
    max-width: 220px;
  }
}

@media only screen and (orientation: landscape) and (max-width: 1450px) and (max-height: 920px) {
  .register-container .login form h4 {
    margin-bottom: 20px;
  }
  .register-container .register #register-form h4 {
    margin: 10px 0;
  }
  .register-container .register #register-form input {
    padding: 10px;
  }
  .register-container .register #register-form .permission {
    margin: 10px 0;
  }
  .login-container .login form {
    height: 65%;
  }
  .login-container .login form h4 {
    margin-bottom: 20px;
  }
  .login-container .register form {
    height: 45%;
  }
  .login-container .register form h4 {
    margin: 10px 0;
  }
  .login-container .register form input {
    padding: 10px;
  }
  .login-container .register form .permission {
    margin: 10px 0;
  }
  .shop-all .container .panel-path .box,
  .shop-all .container .row .box {
    padding: 0 20px;
    max-height: 80vh;
  }
}

@media only screen and (orientation: landscape) and (max-width: 920px) and (max-height: 500px) {
  .register-container .login {
    margin-top: 40px;
  }
  .register-container .login form h4 {
    margin-bottom: 20px;
  }
  .register-container .register #register-form h4 {
    margin: 10px 0;
  }
  .register-container .register #register-form input {
    padding: 10px;
  }
  .register-container .register #register-form .permission {
    margin: 10px 0;
  }
  .login-container {
    height: 150%;
  }
  .login-container .login {
    margin: 10px 0;
  }
  .login-container .login form h4 {
    margin-bottom: 20px;
  }
  .login-container .register {
    height: auto;
  }
  .login-container .register form h4 {
    margin: 10px 0;
  }
  .login-container .register form input {
    padding: 10px;
  }
  .login-container .register form .permission {
    margin: 10px 0;
  }
  .register-container .login form h4 {
    margin-bottom: 20px;
  }
  .register-container .register #register-form h4 {
    margin: 10px 0;
  }
  .register-container .register #register-form input {
    padding: 10px;
  }
  .register-container .register #register-form .permission {
    margin: 10px 0;
  }
  .login-container {
    height: 200%;
  }
  .login-container .login form h4 {
    margin-bottom: 20px;
  }
  .login-container .register form {
    height: 70%;
  }
  .login-container .register form h4 {
    margin: 10px 0;
  }
  .login-container .register form input {
    padding: 10px;
  }
  .login-container .register form .permission {
    margin: 10px 0;
  }
  .shop-all .container .panel-path .box,
  .shop-all .container .row .box {
    padding: 0 20px;
    max-height: 115vh;
  }
}

@media only screen and (orientation: landscape) and (max-width: 730px) and (max-height: 5500px) {
  .register-container .login form h4 {
    margin-bottom: 20px;
  }
  .register-container .register #register-form h4 {
    margin: 10px 0;
  }
  .register-container .register #register-form input {
    padding: 10px;
  }
  .register-container .register #register-form .permission {
    margin: 10px 0;
  }
  .login-container {
    height: 200%;
  }
  .login-container .login form h4 {
    margin-bottom: 20px;
  }
  .login-container .register form {
    height: 70%;
  }
  .login-container .register form h4 {
    margin: 10px 0;
  }
  .login-container .register form input {
    padding: 10px;
  }
  .login-container .register form .permission {
    margin: 10px 0;
  }
}

@media only screen and (orientation: landscape) and (max-width: 700px) and (max-height: 400px) {
  .register-container .login form h4 {
    margin-bottom: 20px;
  }
  .register-container .register #register-form h4 {
    margin: 10px 0;
  }
  .register-container .register #register-form input {
    padding: 10px;
  }
  .register-container .register #register-form .permission {
    margin: 10px 0;
  }
  .login-container {
    height: 200%;
  }
  .login-container .login form h4 {
    margin-bottom: 20px;
  }
  .login-container .register form {
    height: 70%;
  }
  .login-container .register form h4 {
    margin: 10px 0;
  }
  .login-container .register form input {
    padding: 10px;
  }
  .login-container .register form .permission {
    margin: 10px 0;
  }
  .shop-all .container .panel-path,
  .shop-all .container .row {
    padding: 0 70px;
  }
  .shop-all .container .panel-path .box,
  .shop-all .container .row .box {
    padding: 0 25px;
    max-height: 110vh;
  }
  .my-data .container .row .account-nav {
    height: calc(100% - 84px);
  }
  .my-data .container .row .account-nav .data {
    height: auto;
    margin: 0;
  }
  .newsletter2-popap.active,
  .newsletter-popap.active,
  .table-popup.active,
  .product-popap.active {
    width: 50%;
  }
  .newsletter2-popap.active .done img,
  .newsletter-popap.active .done img,
  .table-popup.active .done img,
  .product-popap.active .done img {
    width: 15px;
    height: 15px;
  }
  .newsletter2-popap.active .product-popap-content,
  .newsletter-popap.active .product-popap-content,
  .table-popup.active .product-popap-content,
  .product-popap.active .product-popap-content {
    padding: 0px 10px;
  }
  .newsletter2-popap.active .product-popap-content .product-describe .box-content,
  .newsletter-popap.active .product-popap-content .product-describe .box-content,
  .table-popup.active .product-popap-content .product-describe .box-content,
  .product-popap.active .product-popap-content .product-describe .box-content {
    padding-left: 10px;
  }
  .newsletter2-popap.active .product-popap-content .product-describe .box-content h4,
  .newsletter-popap.active .product-popap-content .product-describe .box-content h4,
  .table-popup.active .product-popap-content .product-describe .box-content h4,
  .product-popap.active .product-popap-content .product-describe .box-content h4 {
    font-size: 12px;
  }
  .newsletter2-popap.active .product-popap-content .product-describe .box-content span,
  .newsletter-popap.active .product-popap-content .product-describe .box-content span,
  .table-popup.active .product-popap-content .product-describe .box-content span,
  .product-popap.active .product-popap-content .product-describe .box-content span {
    font-size: 12px;
  }
  .newsletter2-popap.active .product-popap-content .product-describe .box-content p,
  .newsletter-popap.active .product-popap-content .product-describe .box-content p,
  .table-popup.active .product-popap-content .product-describe .box-content p,
  .product-popap.active .product-popap-content .product-describe .box-content p {
    font-size: 10px;
  }
  .newsletter2-popap.active .product-popap-content a button,
  .newsletter-popap.active .product-popap-content a button,
  .table-popup.active .product-popap-content a button,
  .product-popap.active .product-popap-content a button {
    width: 100%;
    padding: 12px;
  }
  .newsletter2-popap.active h3,
  .newsletter-popap.active h3,
  .table-popup.active h3,
  .product-popap.active h3 {
    padding: 5px 0;
    font-size: 12px;
    line-height: 1.5;
  }
}

@media (min-width: 512px) and (max-width: 540px) and (min-height: 717px) and (max-height: 720px) {
  .shop-all .container .panel-path,
  .shop-all .container .row {
    padding: 0 80px;
  }
  .shop-all .container .panel-path .box,
  .shop-all .container .row .box {
    max-height: 80vh;
  }
}
